import { gql } from "@urql/core"
import { action } from "@graphql/fragments"

export default gql`
  query myActionVersions(
    $after: String
    $q: String
    $appId: String
    $exceptIds: [String!]
    $hidden: Boolean
    $rootId: String!
    $verified: Boolean
    $orderBy: [ActionOrderByEnum!]
  ) {
    my {
      id
      actionVersions(
        first: 20
        after: $after
        q: $q
        appId: $appId
        exceptIds: $exceptIds
        hidden: $hidden
        rootId: $rootId
        verified: $verified
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...ActionParts
        }
      }
    }
  }
  ${action}
`
