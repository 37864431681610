import loadScript from "@/src/utils/loadScript"

const APPCUES_ID = "62844"

export async function show(showId) {
  try {
    if (!window.Appcues) {
      await loadScript(`https://fast.appcues.com/${APPCUES_ID}.js`)
    }
    window.Appcues.show(showId)
  } catch (err) {
    // do nothing
  }
}

export function page() {
  if (!window.Appcues) {
    return
  }
  try {
    window.Appcues.page()
  } catch (err) {
    // do nothing
  }
}
