import { gql } from "@urql/core"
import _get from "lodash-es/get"
import { orgUser } from "@graphql/fragments"
import { ORG_QUERY } from "@graphql/queries"

export default {
  async mutate(orgId, addKey, admin) {
    const resp = await this.mutation(
      gql`
        mutation orgAddUser(
          $orgId: String!
          $addKey: String!
          $admin: Boolean!
        ) {
          orgAddUser(orgId: $orgId, addKey: $addKey, admin: $admin) {
            orgUser {
              ...OrgUserParts
            }
            errors
          }
        }
        ${orgUser}
      `,
      {
        orgId,
        addKey,
        admin,
      }
      // XXX add update?
    ).toPromise()
    const { orgUser: ou, errors } = resp.data.orgAddUser
    if (errors && errors.length) throw errors
    return ou
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "orgAddUser.errors.length")) {
      const id = _get(result, "orgAddUser.orgUser.id")
      const newOrgUser = _get(result, "orgAddUser.orgUser")
      const orgId = args.orgId
      if (id && orgId) {
        cache.updateQuery(
          { query: ORG_QUERY, variables: { id: orgId } },
          data => {
            data.org.orgUserConnections.nodes.push(newOrgUser)
            return data
          }
        )
      }
    }
  },
}
