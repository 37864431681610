import { gql } from "@urql/core"

export default gql`
  query viewerSources {
    viewer {
      id
      deployedComponents {
        id
        name
        nameSlug
        # XXX annoying we need this just for the logos
        savedComponent {
          id
          apps {
            appId
          }
        }
      }
    }
  }
`
