export default async function savePipelineCheckpoint(
  pipelineId,
  stepNamespace,
  value
) {
  // TODO(apollo) check cache update
  const resp = await this.mutation(
    `
      mutation savePipelineCheckpoint(
        $pipelineId: String!
        $stepNamespace: String
        $value: String
      ) {
        savePipelineCheckpoint(
          pipelineId: $pipelineId
          stepNamespace: $stepNamespace
          value: $value
        ) {
          errors
        }
      }
    `,
    {
      pipelineId,
      stepNamespace,
      value,
    }
  ).toPromise()
  const { errors } = resp.data.savePipelineCheckpoint
  if (errors && errors.length) throw errors
}
