import { gql } from "@urql/core"
import { pipeline, pipelineWiths } from "@graphql/fragments"

export default gql`
  query adminPipelines(
    $after: String
    $q: String
    $public: Boolean
    $status: PipelineStatusEnum
    $orderBy: [PipelineOrderByEnum!]
    ${pipelineWiths.fragmentPart}
  ) {
    admin {
      pipelines(
        after: $after
        first: 20
        public: $public
        q: $q
        status: $status
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          _can
          ...PipelineParts
        }
      }
    }
  }
  ${pipeline}
`
