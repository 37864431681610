import * as fragments from "@graphql/fragments"
import StepInput from "./stepInput"
import StepAppInput from "./stepAppInput"

const mutation = `
  mutation pipelineUpdateStep(
    $deploymentId: String!
    $index: Int!
    $step: StepInput!
    $apps: [StepAppInput!]
    $lambdaMakerSuffix: String
    $orgId: String
  ) {
    my(orgId: $orgId) {
      pipelineUpdateStep(
        deploymentId: $deploymentId
        index: $index
        step: $step
        apps: $apps
        lambdaMakerSuffix: $lambdaMakerSuffix
      ) {
        deployment {
          ...WorkflowDeploymentParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.deployment}
  ${fragments.workflow.savedComponent}
`

export function replaceLatestDeployment(replaceId, withDeployment, cache) {
  if (replaceId && withDeployment) {
    cache
      .inspectFields("Query")
      .filter(f => f.fieldName == "pipeline")
      .map(f => cache.resolve("Query", f.fieldKey))
      .filter(
        p =>
          cache.resolve(cache.resolve(p, "latestDeployment"), "id") == replaceId
      )
      .forEach(p => {
        cache.link(p, "latestDeployment", withDeployment)
      })
  }
}

export default {
  name: "my.pipelineUpdateStep",
  async mutate(
    pipeline,
    index,
    stepInput,
    stepAppInputs = [],
    { lambdaMakerSuffix = null, orgId }
  ) {
    const reservedNamespaces = pipeline.latestDeployment.steps.map(
      s => s.namespace
    )
    reservedNamespaces.splice(index, 1)
    reservedNamespaces.unshift(pipeline.triggerNamespace || "trigger")
    const _stepInput = StepInput.prepare(stepInput, reservedNamespaces)
    const _stepAppInputs = StepAppInput.prepareAll(stepAppInputs)
    return await this.mutation(mutation, {
      deploymentId: pipeline.latestDeployment.id,
      index,
      step: _stepInput,
      apps: _stepAppInputs,
      lambdaMakerSuffix,
      orgId,
    }).toPromise()
  },
  update(result, _args, cache, info) {
    const args = info.variables
    if (!result.my.errors) {
      replaceLatestDeployment(
        args.deploymentId,
        result.my.pipelineUpdateStep.deployment,
        cache
      )
    }
  },
}
