import _camelCase from "lodash-es/camelCase"
import _keys from "lodash-es/keys"
import _isArray from "lodash-es/isArray"
import _isBoolean from "lodash-es/isBoolean"
import _isFunction from "lodash-es/isFunction"
import _isNumber from "lodash-es/isNumber"
import _isObjectLike from "lodash-es/isObjectLike"
import _isString from "lodash-es/isString"
import _startsWith from "lodash-es/startsWith"
import _transform from "lodash-es/transform"
import _mergeWith from "lodash-es/mergeWith"

export function camelizeKeys(object) {
  return _transform(
    object,
    (result, value, key) => {
      result[_camelCase(key)] = _isObjectLike(value)
        ? camelizeKeys(value)
        : value
    },
    {}
  )
}

export function camelizeKeysDeep(object) {
  if (!_isObjectLike(object)) return object
  for (const key of Object.keys(object)) {
    const value = object[key]
    delete object[key]
    object[_camelCase(key)] = camelizeKeysDeep(value)
  }
  return object
}

export function pascalCase(str) {
  if (!_isString(str)) return str
  return `${str.slice(0, 1).toUpperCase()}${_camelCase(str).slice(1)}`
}

export function emptyPromise() {
  let _resolve
  let _reject
  let _noop = function() {}
  const emptyPromise = new Promise((resolve, reject) => {
    _resolve = resolve
    _reject = reject
  })
  emptyPromise.resolve = (...args) => {
    emptyPromise.resolved = true
    emptyPromise.resolve = _noop
    emptyPromise.reject = _noop
    return _resolve(...args)
  }
  emptyPromise.reject = (...args) => {
    emptyPromise.rejected = true
    emptyPromise.resolve = _noop
    emptyPromise.reject = _noop
    return _reject(...args)
  }
  return emptyPromise
}

export function mergeState(object, ...sources) {
  return _mergeWith(object, ...sources, (to, from, key, obj) => {
    if (!to || _isArray(to)) {
      return (obj[key] = from)
    }
  })
}

export function findDeep(obj, re, maxDepth = 2) {
  const q = Array.of({ v: obj, depth: 0 })
  while (q.length > 0) {
    const { v, depth } = q.shift()
    if (_isString(v) || _isNumber(v)) {
      if (re.test(v)) {
        return true
      }
    } else if (depth < maxDepth && _isObjectLike(v)) {
      for (const key of _keys(v)) {
        if (!_startsWith(key, "_") && !_startsWith(key, "$")) {
          q.push({ v: v[key], depth: depth + 1 })
        }
      }
    }
  }
  return false
}

export function freezeDeep(obj) {
  if (_isObjectLike(obj)) {
    Object.freeze(obj)
    Object.seal(obj)
    for (const v of Object.values(obj)) {
      freezeDeep(v)
    }
  }
}

// {
//   process: {
//     env: {
//       A: 0,
//       B: 0,
//     },
//   },
// }
// --->
// {
//   "process.env.A": 0,
//   "process.env.B": 0,
// }
function _flatten(ret, prefix, obj) {
  const isArray = Array.isArray(obj)
  for (const k in obj) {
    let key
    if (prefix) {
      let suffix
      if (isArray) {
        suffix = `[${k}]`
      } else {
        suffix = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/.test(k) ? `.${k}` : `["${k}"]`
      }
      key = `${prefix}${suffix}`
    } else {
      key = k
    }
    const v = obj[k]
    if (typeof v === "object") {
      ret[key] = !v ? null : Array.isArray(v) ? "[]" : "{}"
      _flatten(ret, key, v)
    } else {
      ret[key] = v
    }
  }
}

export function flatten(obj) {
  const ret = {}
  _flatten(ret, "", obj)
  return ret
}

export function fromJson(json) {
  if (!json) return null
  try {
    return JSON.parse(json)
  } catch (e) {
    // noop
  }
  return null
}

export function normalize(x, min, max, scale = 1) {
  return scale * ((x - min) / (max - min))
}

export function propToFlags(v) {
  let p = {}
  if (_isArray(v)) {
    for (const key of v) {
      p[key] = true
    }
  } else if (_isString(v)) {
    for (const key of v.split(" ")) {
      p[key] = true
    }
  } else if (_isObjectLike(v)) {
    for (const key in v) {
      p[key] = !!v[key]
    }
  }
  return p
}

export function typeToFlags(o) {
  if (_isArray(o)) return { array: true, empty: !o.length }
  if (_isFunction(o)) return { function: true }
  if (_isObjectLike(o)) return { object: true, empty: !_keys(o).length }
  if (_isBoolean(o)) return { boolean: true }
  if (_isNumber(o)) return { number: true }
  if (_isString(o)) return { string: true, empty: !o.length }
  return { null: true }
}

export function* reverse(arr) {
  let index = arr.length
  while (index > 0) {
    index--
    yield arr[index]
  }
}

export function* mergeBy(compareFn, ...arrs) {
  const iters = arrs.map(a => a[Symbol.iterator]())
  let heads = iters.map(l => l.next())
  while (heads.filter(h => !h.done).length > 0) {
    let idxNext = -1
    for (const index in heads) {
      if (idxNext < 0) {
        if (!heads[index].done) {
          idxNext = index
        }
      } else {
        if (!heads[index].done) {
          if (compareFn(heads[index].value, heads[idxNext].value)) {
            idxNext = index
          }
        }
      }
    }
    yield heads[idxNext].value
    heads[idxNext] = iters[idxNext].next()
  }
}

export function* lookbehind(iter) {
  let previous
  for (let current of iter) {
    yield { previous, current }
    previous = current
  }
}

export function* tap(iter, fn) {
  for (let v of iter) {
    fn(v)
    yield v
  }
}

export function* range(from = 0, to) {
  if (to >= from) {
    for (let i = from; i <= to; i++) yield i
  } else {
    for (let i = from; i >= to; i--) yield i
  }
}

const alphabet =
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
export function randomString(n) {
  let s = ""
  for (let i = 0; i < n; i++) {
    s += alphabet[Math.floor(Math.random() * alphabet.length)]
  }
  return s
}
