/* global Buffer */

export function atob(str) {
  if (typeof window !== "undefined") {
    return window.atob(str)
  } else {
    return Buffer.from(str, "base64").toString("binary")
  }
}

export function btoa(str) {
  if (typeof window !== "undefined") {
    return window.btoa(str)
  } else {
    return Buffer.from(str.toString(), "binary").toString("base64")
  }
}

export function base64Decode(data) {
  // Replace non-url compatible chars with base64 standard chars
  const unsafe = data.replace(/-/g, "+").replace(/_/g, "/")
  // Pad with standard base64 required padding characters
  const pad = unsafe.length % 4
  const padString = pad > 1 ? new Array(5 - pad).join("=") : ""
  return atob(unsafe + padString)
}
