import { gql } from "@urql/core"

export default gql`
  query actionVersions($rootId: String!) {
    actionVersions(rootId: $rootId) {
      id
      publishedAt
      publishedVersionMajor
      publishedVersionMinor
    }
  }
`
