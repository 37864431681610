import { gql } from "@urql/core"
import { action } from "@graphql/fragments"

// XXX should this be ADMIN_* ?
export default gql`
  query actionConnections(
    $after: String
    $q: String
    $appId: String
    $adminOnly: Boolean
    $hidden: Boolean
    $latest: Boolean
    $published: Boolean
    $verified: Boolean
    $orderBy: OrderByActionInput
    $first: Int
  ) {
    actionConnections(
      first: $first
      after: $after
      q: $q
      appId: $appId
      adminOnly: $adminOnly
      hidden: $hidden
      latest: $latest
      published: $published
      verified: $verified
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...ActionParts
      }
    }
  }
  ${action}
`
