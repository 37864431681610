import * as fragments from "@graphql/fragments"
import { gql } from "@urql/core"

const mutation = gql`
  mutation savedComponentDelete($id: String!, $orgId: String) {
    my(orgId: $orgId) {
      deleteSavedComponent(id: $id) {
        errors
      }
    }
  }
  ${fragments.workflow.savedComponent}
`
export default async function savedComponentDelete(id, { orgId } = {}) {
  const results = await this.mutation(mutation, { id, orgId }).toPromise()
  const { errors } = results.data.my.deleteSavedComponent
  if (errors && errors.length) throw errors
}
