import _get from "lodash-es/get"
export function invalidatePipelinesQueries(cache) {
  cache
    .inspectFields("Query")
    .filter(f => ["admin", "my", "viewer"].includes(f.fieldName))
    .forEach(c => {
      const context = cache.resolve("Query", c.fieldKey)
      cache
        .inspectFields(context)
        .filter(f => f.fieldName == "pipelines")
        .forEach(f => {
          cache.invalidate(context, f.fieldKey)
        })
    })
  cache
    .inspectFields("Query")
    .filter(f => f.fieldName == "pipelines")
    .forEach(f => cache.invalidate("Query", f.fieldKey))
}
export default {
  async mutate(context, ids) {
    const orgId = context.isOrg ? context.id : null
    const resp = await this.mutation(
      `
      mutation deletePipelines($ids: [String!], $orgId: String) {
        deletePipelines(ids: $ids, orgId: $orgId) {
          errors
        }
      }
    `,
      {
        ids,
        orgId,
      }
    ).toPromise()
    const { errors } = resp.data.deletePipelines
    if (errors && errors.length) throw errors
    return resp
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "deletePipelines.errors.length")) {
      args.ids.forEach(id => {
        cache.invalidate({ __typename: "Pipeline", id })
      })
      invalidatePipelinesQueries(cache)
    }
  },
}
