import { gql } from "@urql/core"

export default gql`
  query myPublishedComponents(
    $first: Int = 20
    $after: String
    $type: String
    $orderBy: [SavedComponentOrderByEnum!]
    $orgId: String
    $withConfigurableProps: Boolean = false
  ) {
    my(orgId: $orgId) {
      id
      publishedComponents(
        first: $first
        after: $after
        type: $type
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          name
          description
          key
          codeHash
          configurablePropsJson @include(if: $withConfigurableProps)
        }
      }
    }
  }
`
