import { gql } from "@urql/core"
import _get from "lodash-es/get"
export function invalidateOrgQueries(cache) {
  cache
    .inspectFields("Query")
    .filter(f => ["admin", "my", "viewer"].includes(f.fieldName))
    .forEach(c => {
      const context = cache.resolve("Query", c.fieldKey)
      cache
        .inspectFields(context)
        .filter(f => ["orgs", "orgConnections"].includes(f.fieldName))
        .forEach(f => {
          cache.invalidate(context, f.fieldKey)
        })
    })
  cache
    .inspectFields("Query")
    .filter(f => ["orgs", "orgConnections"].includes(f.fieldName))
    .forEach(f => cache.invalidate("Query", f.fieldKey))
}
export default {
  async mutate(data) {
    const resp = await this.mutation(
      gql`
        mutation orgCreate($name: String, $desiredEmail: String) {
          orgCreate(name: $name, desiredEmail: $desiredEmail) {
            org {
              id
              name
              orgname
              nickname
              email
              desiredEmail
            }
            errors
          }
        }
      `,
      {
        name: data.name,
        desiredEmail: data.desiredEmail,
      }
    ).toPromise()
    // XXX add update?
    const { org, errors } = resp.data.orgCreate
    if (errors && errors.length) throw errors
    return org
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "orgCreate.errors.length")) {
      const id = _get(result, "data.orgCreate.org.id")
      if (id) {
        invalidateOrgQueries(cache)
      }
    }
  },
}
