import { gql } from "@urql/core"
import { source } from "@graphql/fragments"

export default gql`
  query emitterSource {
    emitterSource {
      ...SourceParts
    }
  }
  ${source}
`
