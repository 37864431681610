import { gql } from "@urql/core"
import { savedComponent } from "@graphql/fragments"

export default gql`
  query savedComponent($id: String!) {
    savedComponent(id: $id) {
      ...SavedComponentParts
    }
  }
  ${savedComponent}
`
