import _toString from "lodash-es/toString"
import _throttle from "lodash-es/throttle"
import _isArray from "lodash-es/isArray"

export class Observable {
  constructor() {
    this.listeners = {}
  }
  on(event, fn) {
    const e = _toString(event)
    if (!this.listeners[e]) {
      this.listeners[e] = []
    }
    this.listeners[e].push(fn)
    return fn
  }
  remove(event, fn) {
    const e = _toString(event)
    if (this.listeners[e]) {
      this.listeners[e] = this.listeners[e].filter(listener => listener !== fn)
    }
  }
  async notify(event, ...args) {
    const e = _toString(event)
    if (this.listeners[e]) {
      return await Promise.all(
        this.listeners[e].map(listener => listener(...args))
      )
    }
  }
}

export class RetryEventSource extends Observable {
  constructor(url, config) {
    super()
    this.retrying = {
      attempts: 0,
      timeout: null,
    }
    this.config = config
    this.url = url
  }
  notify(event, e) {
    this.retrying.attempts = 0
    super.notify(event, JSON.parse(e.data))
  }
  stopRetrying() {
    if (this.retrying.timeout) {
      clearTimeout(this.retrying.timeout)
      this.retrying.timeout = null
    }
  }
  close() {
    try {
      this.stopRetrying()
      if (this.eventSource) {
        this.eventSource.close()
      }
      return this
    } catch (err) {
      return this
    } finally {
      this.eventSource = null
    }
  }
  connect() {
    if (import.meta.env.SSR) return
    if (!this.eventSource) {
      this.retrying.attempts++
      this.stopRetrying()
      this.eventSource = new EventSource(this.url, {
        ...this.config,
        withCredentials: true,
      })
      this.eventSource.onerror = e => this.retry(e)
      for (const event in this.listeners) {
        this.eventSource.addEventListener(event, e => this.notify(event, e), {
          passive: true,
        })
      }
    }
    return this
  }
  on(event, fn) {
    this.connect()
    if (this.eventSource && !this.listeners[event]) {
      if (!event || event === "message") {
        this.eventSource.onmessage = e => this.notify("message", e)
        super.on("message", fn)
      } else {
        this.eventSource.addEventListener(event, e => this.notify(event, e), {
          passive: true,
        })
        super.on(event, fn)
      }
    }
    return this
  }
  retry() {
    this.close()
    const wait = Math.random() * 100 * 2 ** this.retrying.attempts
    this.retrying.timeout = setTimeout(() => this.connect(), wait)
  }
}

export class BufferedRetryEventSource extends RetryEventSource {
  constructor(...args) {
    super(...args)
    this._buffer = []
    this.pause = false
  }
  get buffered() {
    return this._buffer.length
  }
  buffer(data) {
    const _data = _isArray(data) ? data : [data]
    this._buffer.push(..._data)
  }
  flushFn(fn) {
    return _throttle(
      () => fn(this._buffer.splice(0, this._buffer.length)),
      500,
      {
        leading: true,
        trailing: true,
      }
    )
  }
  on(event, fn) {
    const flush = this.flushFn(fn)
    super.on(event, data => {
      this.buffer(data)
      if (!this.pause) {
        flush()
      }
    })
  }
}
