import * as fragments from "@graphql/fragments"
import { fromJson } from "@/src/utils/lang"
import { gql } from "@urql/core"

const mutation = gql`
  mutation savedComponentCreate(
    $code: String!
    $codeHash: String
    $entryPath: String
    $githubComponentRepoBranch: String
    $loc: String
    $orgId: String
    $zipBuffer64: String
  ) {
    saveComponentCode(
      code: $code
      codeHash: $codeHash
      entryPath: $entryPath
      githubComponentRepoBranch: $githubComponentRepoBranch
      loc: $loc
      orgId: $orgId
      zipBuffer64: $zipBuffer64
    ) {
      savedComponent {
        ...WorkflowSavedComponentParts
      }
      errors
    }
  }
  ${fragments.workflow.savedComponent}
`
export default async function savedComponentCreate({
  code,
  codeHash = null,
  entryPath,
  githubComponentRepoBranch,
  loc,
  orgId,
  zipBuffer64,
}) {
  const results = await this.mutation(mutation, {
    code,
    codeHash,
    entryPath,
    githubComponentRepoBranch,
    loc,
    orgId,
    zipBuffer64,
  }).toPromise()
  const { errors, savedComponent } = results.data.saveComponentCode
  // TODO: The JSON encoding seems like a bug in API
  if (errors && errors.length) throw errors.map(fromJson)
  if (!savedComponent) throw "Expected savedComponent!"
  return savedComponent
}
