import _pick from "lodash-es/pick"
import { adminUser } from "@graphql/fragments"

function userPrepare(userData) {
  return _pick(userData, [
    "admin",
    "extraDailyInvocations",
    "cmTag",
    "lambdaTimeQuota",
    "premium",
  ])
}

export default async function saveUser(userId, userData) {
  const userInput = userPrepare(userData)
  const resp = await this.mutation(
    `
      mutation saveUser($id: String, $user: UserInput!) {
        saveUser(id: $id, user: $user) {
          user {
            ...AdminUserParts
          }
          errors
        }
      }
      ${adminUser}
    `,
    {
      id: userId,
      user: userInput,
    }
  ).toPromise()
  const { user, errors } = resp.data.saveUser
  if (errors && errors.length) throw errors
  return user
}
