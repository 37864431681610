import { LRUMap } from "lru_map"
import _noop from "lodash-es/noop"

export class LRU extends LRUMap {
  // onEvict(key, value)
  constructor(limit, entries, onEvict = _noop) {
    const _entries = (entries || [])
      .filter(kv => kv && kv.key)
      .map(({ key, value }) => [key, value])
    super(limit, _entries)
    this._onEvict = onEvict
  }

  assign(entries) {
    this.clear()
    super.assign(entries)
  }

  set(key, value) {
    super.set(key, value)
  }

  clear() {
    super.forEach((v, k) => this._onEvict(k, v))
    return super.clear()
  }

  delete(key) {
    const value = super.delete(key)
    this._onEvict(key, value)
    return value
  }

  shift() {
    const entry = super.shift()
    this._onEvict(...entry)
    return entry
  }
}
