// TODO appSkips? bunch of places we only want id, name (ie. apps/edit for parent app and sub apps)
const withs = [
  "withAppExtraParts",
  "withAuthProvisions",
  "withAuthProvisionApp",
  "withNumAuthProvisions",
  "withOfficialOauthApp",
  "withSubAppIds",
  "withTestRequestCodeRaw",
]

export default withs
