import _get from "lodash-es/get"
import { invalidateOrgQueries } from "./orgCreate"
export default {
  async mutate({ id, usernameConfirmation, messageConfirmation }) {
    const resp = await this.mutation(
      `
    mutation orgDelete(
      $id: String!
      $usernameConfirmation: String!
      $messageConfirmation: String!
    ) {
      orgDelete(
        id: $id
        usernameConfirmation: $usernameConfirmation
        messageConfirmation: $messageConfirmation
      ) {
        errors
      }
    }
  `,
      {
        id,
        usernameConfirmation,
        messageConfirmation,
      }
    ).toPromise()
    const { errors } = resp.data.orgDelete
    if (errors && errors.length) throw errors
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "orgDelete.errors.length")) {
      const id = args.id
      if (id) {
        invalidateOrgQueries(cache)
        cache.invalidate({ __typename: "Org", id })
      }
    }
  },
}
