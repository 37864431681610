import _get from "lodash-es/get"
import { action as actionFragment } from "@graphql/fragments"
import { actionRevive } from "@graphql/types/action"
import { invalidateActionQueries } from "./saveAction"

export default {
  async mutate(id, versionMajor, versionMinor) {
    // TODO(apollo) cache update
    const resp = await this.mutation(
      `
    mutation publishAction(
      $id: String!
      $versionMajor: Int!
      $versionMinor: Int!
    ) {
      publishAction(
        id: $id
        versionMajor: $versionMajor
        versionMinor: $versionMinor
      ) {
        action {
          ...ActionParts
        }
        errors
      }
    }
    ${actionFragment}
  `,
      {
        id,
        versionMajor,
        versionMinor,
      }
    ).toPromise()
    const { action, errors } = resp.data.publishAction
    if (errors && errors.length) throw errors
    actionRevive(action)
    return action
  },

  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "publishAction.errors.length")) {
      const id = _get(result, "publishAction.action.id")
      if (id) {
        invalidateActionQueries(cache)
      }
    }
  },
}
