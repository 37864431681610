import { gql } from "@urql/core"
import _get from "lodash-es/get"
import { REDACTED_ENVIRONMENT_VARIABLES_QUERY } from "@graphql/queries/my"

export default {
  name: "my.createEnvironmentVariable",
  async mutate({ orgId, name, value }) {
    const mutation = gql`
      mutation createEnvironmentVariable(
        $orgId: String
        $name: String!
        $value: String!
      ) {
        my(orgId: $orgId) {
          createEnvironmentVariable(name: $name, value: $value) {
            errors
            redactedEnvironmentVariable {
              id
              name
              updatedAt
            }
          }
        }
      }
    `
    const variables = {
      orgId,
      name,
      value,
    }
    const resp = await this.mutation(mutation, variables).toPromise()
    const {
      redactedEnvironmentVariable,
      errors,
    } = resp.data.my.createEnvironmentVariable
    if (errors && errors.length) throw errors
    return redactedEnvironmentVariable
  },
  update(result, args, cache) {
    if (
      !result.error &&
      !_get(result, "my.createEnvironmentVariable.errors.length")
    ) {
      const {
        redactedEnvironmentVariable,
      } = result.my.createEnvironmentVariable
      cache.updateQuery(
        { query: REDACTED_ENVIRONMENT_VARIABLES_QUERY },
        data => {
          if (data)
            data.my.redactedEnvironmentVariables.push(
              redactedEnvironmentVariable
            )
          return data
        }
      )
    }
  },
}
