import { gql } from "@urql/core"

// XXX some of these fields are not expensive but also not needed in most of the app  (invocations / lambdaTime... )
export default gql`
  query me(
    $withDailyInvocations: Boolean = false
    $withInvocationBuckets: Boolean = false
    $withPeriodInvocations: Boolean = false
    $withSubscription: Boolean = false
    $withOrgConnections: Boolean = false
  ) {
    viewer {
      admin
      basePeriodInvocations
      completeOnboardingSteps {
        step
      }
      createdAt
      dailyInvocationsQuota @include(if: $withDailyInvocations)
      dailyInvocationsUsed @include(if: $withDailyInvocations)
      email
      extraDailyInvocations
      hasActions
      id
      invocationBucketsByDayTs @include(if: $withInvocationBuckets)
      lambdaTimeQuota
      lambdaTimeUsed
      orgConnections(first: 20) @include(if: $withOrgConnections) {
        nodes {
          id
          name
          email
          errorPipelineId
          stripeSubscriptionActive
          hasStripeSubscription
          dailyInvocationsQuota @include(if: $withDailyInvocations)
          dailyInvocationsUsed @include(if: $withDailyInvocations)
          lambdaTimeQuota
          lambdaTimeUsed
          orgUserCount
        }
      }
      periodInvocations @include(if: $withPeriodInvocations)
      premium
      hasStripeSubscription
      stripeSubscriptionActive
      stripeSubscription @include(if: $withSubscription) {
        id
        cancelAtPeriodEnd
        currentPeriodEnd
        currentPeriodStart
        status
      }
      username
    }
  }
`
