import { canonizeNameSlug } from "@/src/utils/nameSlug"
import _pick from "lodash-es/pick"
export default {
  prepare(stepInput, reservedNamespaces) {
    const _stepInput = _pick(stepInput, [
      "actionId",
      "actionParamsJson",
      "codeRaw",
      "codeConfigJson",
      "component",
      "componentKey",
      "componentOwnerId",
      "configuredPropsJson",
      "disabled",
      "lang",
      "namespace",
      "paramsJson",
      "savedComponentId",
      "sourceId",
      "sourceParams",
      "textRaw",
      "type",
    ])
    if (!_stepInput.configuredPropsJson && stepInput.configuredProps) {
      _stepInput.configuredPropsJson = JSON.stringify(stepInput.configuredProps)
    }
    _stepInput.configuredPropsJson = _stepInput.configuredPropsJson || undefined

    _stepInput.namespace = canonizeNameSlug(
      _stepInput.namespace,
      reservedNamespaces
    )

    if (!_stepInput.savedComponentId && stepInput.savedComponent) {
      _stepInput.savedComponentId = stepInput.savedComponent.id
    }
    if (!_stepInput.sourceId && stepInput.source) {
      _stepInput.sourceId = stepInput.source.id
    }
    if (!_stepInput.sourceParams) {
      _stepInput.sourceParams = "{}"
    }
    return _stepInput
  },
}
