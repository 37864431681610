const mutation = `
  mutation timerInterfaceDelete($id: String!, $orgId: String) {
    my(orgId: $orgId) {
      timerInterfaceDelete(id: $id) {
        errors
      }
    }
  }
`
export default async function timerInterfaceDelete(
  timerInterface,
  { orgId } = {}
) {
  const results = await this.mutation(mutation, {
    id: timerInterface.id,
    orgId,
  }).toPromise()
  const { errors } = results.data.my.timerInterfaceDelete
  if (errors && errors.length) throw errors
}
