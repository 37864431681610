import _get from "lodash-es/get"
import _set from "lodash-es/set"
import fetch from "cross-fetch"
import { pipe, tap } from "wonka"
import {
  createClient,
  dedupExchange,
  fetchExchange,
  ssrExchange,
  Source,
  OperationResult,
  Partial,
  Operation,
  OperationContext,
} from "@urql/core"
import { cacheExchange } from "@urql/exchange-graphcache"
// we must ask for at least $first when getting connections
// ... otherwise we get null for that field
// XXX we should probably always be handling that so it's a good issue
import { relayPagination } from "@urql/exchange-graphcache/extras"
import { API_BASE_URL } from "@/src/config"
import schema from "@graphql/schema.json"
import { mutations, cacheExchangeOptions } from "@graphql"
export { useQuery, useClientHandle } from "@urql/vue"
import { Sentry } from "@sentry"

const isServer = import.meta.env.SSR
const isClient = !isServer

export async function executeQuery(
  result: Source<OperationResult>,
  args: Partial<OperationContext> | undefined
): Source<OperationResult> {
  await result.executeQuery({
    ...(args || {}),
    requestPolicy: "network-only",
  })
  while (result.fetching.value || result.stale.value) {
    await new Promise(resolve => setTimeout(resolve, 25))
  }
  return result
}

// TODO logging out we might need to make a new client... (maybe pass option to refresh)
// https://spectrum.chat/urql/help/invalidate-all-cache-or-completely-clear-urql-exchange-graphcache~fb809fcf-759f-471a-b984-633160043764?m=MTU3NDUyNDIwNDYyMQ==
export async function createUrqlClient({
  agents,
  cookieHeader,
  hybridStorage,
}) {
  const _cacheExchange = cacheExchange({
    schema,
    keys: {
      AdminContext: () => null,
      App: o => o.id, // weird case see new-step-selector-apps document fragment thing
      AppAction: () => null,
      AppCustomField: () => null,
      AppSavedComponent: o => `${o.appId}-${o.savedComponentId}`,
      Authorization: () => null,
      Extract: o => o.field,
      CellParam: () => null,
      CompleteOnboardingStep: o => o.step,
      ConfiguredProp: () => null,
      ConfiguredPropKv: () => null,
      ExecutionTrace: o => o.trace,
      Kv: () => null,
      OauthReqsByType: () => null,
      OauthReq: () => null,
      PipelineUser: o => o.inviteeUserId || o.inviteeEmail || o.inviteeUsername,
      Price: () => null, // XXX return id from api?
      PriceRecurring: () => null, // XXX return id from api?
      Profile: o => (o.user ? o.user.id : o.org.id),
      PublishedComponent: o => o.key,
      Selectable: () => null,
      StepSearchResultDocument: () => null,
      SubscriptionTier: () => null,
      ResourceInvocations: o => o.resourceId,
      Usage: () => null,
    },
    resolvers: {
      AdminContext: {
        actions: relayPagination(),
        actionVersions: relayPagination(),
        apps: relayPagination(),
        orgs: relayPagination(),
        pipelines: relayPagination(),
        users: relayPagination(),
      },
      App: {
        actionConnections: relayPagination(),
      },
      MyContext: {
        actions: relayPagination(),
        actionVersions: relayPagination(),
        authProvisions: relayPagination(),
        authProvisionPipelines: relayPagination(),
        deployedComponents: relayPagination(),
        pipelines: relayPagination(),
        pipelinesSharedWithMe: relayPagination(),
        publishedComponents: relayPagination(),
      },
      Org: {
        orgUserConnections: relayPagination(),
        pipelineConnections: relayPagination(),
      },
      User: {
        actionConnections: relayPagination(),
        authProvisionConnections: relayPagination(),
        orgConnections: relayPagination(),
        pipelineConnections: relayPagination(),
        stepSearch: relayPagination(),
      },
      Query: {
        actionConnections: relayPagination(),
        components: relayPagination(),
        appConnections: relayPagination(),
        myEmitters: relayPagination(),
        pipelineConnections: relayPagination(),
        userConnections: relayPagination(),
      },
    },
    ...cacheExchangeOptions,
  })
  const _ssrExchange = ssrExchange({
    isClient,
    initialState: isServer ? undefined : window.__urql__,
  })
  const sentryBreadcrumbExchange = ({ forward }) => (
    ops$: Source<Operation>
  ) => {
    return pipe(
      ops$,
      tap((op: Operation) => {
        if (op.kind === "teardown") return
        const operationName = _get(op, "query.definitions[0].name.value")
        Sentry.addBreadcrumb({
          category: "graphql",
          message: `${op.kind} ${operationName || ""}`,
          data: {
            kind: op.kind,
            operationName,
            variables: op.variables,
          },
        })
      }),
      forward
    )
  }
  const urqlExchanges = [
    dedupExchange,
    _cacheExchange,
    _ssrExchange, // before fetchExchange
    sentryBreadcrumbExchange,
    fetchExchange,
  ]
  // TODO ensure not to include this in prod build!!
  if (import.meta.env.DEV && isClient) {
    const { devtoolsExchange } = await import("@urql/devtools")
    urqlExchanges.unshift(devtoolsExchange)
  }
  let agent
  if (agents) {
    if (API_BASE_URL.startsWith("https:")) {
      agent = agents.https
    } else {
      agent = agents.http
    }
  }
  const client = createClient({
    url: `${API_BASE_URL}/graphql`,
    requestPolicy: "cache-and-network",
    exchanges: urqlExchanges,
    fetch,
    fetchOptions() {
      const opts = {
        agent,
      }
      if (isClient) {
        opts.credentials = "include"
      } else if (cookieHeader) {
        opts.headers = { cookie: cookieHeader }
      }
      if (!hybridStorage.username) {
        const anonymousId = hybridStorage.anonymousId
        if (anonymousId) {
          if (!opts.headers) opts.headers = {}
          opts.headers["x-anonymous-id"] = anonymousId
        }
      }
      return opts
    },
  })
  client._ssrExchange = _ssrExchange

  // XXX ?debug=1 or something which sets this and other debug messages elsewhere?
  // client.subscribeToDebugTarget(event => {
  //   if (event.source === 'dedupExchange') return
  //   console.log(event) // { type, message, operation, data, source, timestamp }
  // })
  client.mutations = client.mutations || {}
  for (const [path, mutation] of Object.entries(mutations)) {
    _set(client.mutations, path, mutation.bind(client))
  }

  return client
}
