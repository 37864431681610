import { gql } from "@urql/core"
import {
  action,
  deployedComponent,
  deployedComponentWiths,
  eventStream,
  pipeline,
  pipelineWiths,
  source,
} from "@graphql/fragments"

export default gql`
  query viewerStepSearch(
    $q: String
    $adminOnly: Boolean = false
    $excludeIds: [String!]
    $explain: Boolean = false
    $onlyAppId: String
    $onlyTypes: [String!]
    $verified: Boolean
    $first: Int
    $after: String
    $appHasRegistrySources: Boolean
    $withDocument: Boolean = false
    ${pipelineWiths.fragmentPart}
    ${deployedComponentWiths.fragmentPart}
  ) {
    viewer {
      id
      stepSearch(q: $q, adminOnly: $adminOnly, excludeIds: $excludeIds, explain: $explain, onlyAppId: $onlyAppId, onlyTypes: $onlyTypes, first: $first, after: $after, appHasRegistrySources: $appHasRegistrySources, verified: $verified) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          adminOnly
          apps
          createdAt
          enabled
          featuredWeight
          updatedAt
          imgSrc
          klass
          description
          name
          nameSlug
          owner
          public
          rootId
          statistics
          users
          verified
          version
          _explanation
          _score
          _sort
          document @include(if: $withDocument) {
            ...ActionParts
            ...DeployedComponentParts
            ...EventStreamParts
            ...PipelineParts
            ...SourceParts
          }
        }
      }
    }
  }
  ${action}
  ${deployedComponent}
  ${eventStream}
  ${pipeline}
  ${source}
`
