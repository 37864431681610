import * as fragments from "@graphql/fragments"

const mutation = `
  mutation updateDeployedComponent(
    $id: String!
    $name: String
    $active: Boolean
    $savedComponentId: String
    $componentCode: String
    $componentUrl: String
    $configuredPropsJson: String
    $orgId: String
  ) {
    my(orgId: $orgId) {
      updateDeployedComponent(
        id: $id
        name: $name
        active: $active
        savedComponentId: $savedComponentId
        componentCode: $componentCode
        componentUrl: $componentUrl
        configuredPropsJson: $configuredPropsJson
      ) {
        deployedComponent {
          ...WorkflowDeployedComponentParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.deployedComponent}
  ${fragments.workflow.savedComponent}
`
export default async function deployedComponentUpdate(
  id,
  name,
  active,
  savedComponentId,
  componentCode,
  componentUrl,
  configuredPropsJson,
  { orgId } = {}
) {
  const results = await this.mutation(mutation, {
    id,
    name,
    active,
    savedComponentId,
    componentCode,
    componentUrl,
    configuredPropsJson,
    orgId,
  }).toPromise()
  const { errors, deployedComponent } = results.data.my.updateDeployedComponent
  if (errors && errors.length) throw errors
  if (!deployedComponent) throw "Expected deployedComponent!"
  return deployedComponent
}
