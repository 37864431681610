export default async function loadScript(src) {
  return await new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.src = src
    script.addEventListener("load", resolve)
    script.addEventListener("error", () => {
      reject(new Error(`failed to load the script at ${src}`))
    })
    document.head.appendChild(script)
  })
}
