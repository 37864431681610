import { createConsumer } from "@rails/actioncable"
import equal from "fast-deep-equal"

export default class {
  constructor(url) {
    this._cable = createConsumer(url)
    this.pools = []
  }

  subscribe(args, callback) {
    if (typeof args == "string") {
      args = { channel: args }
    }

    // pools shouldn't get too long so the linear search is ok
    for (const pool of this.pools) {
      if (equal(args, pool.args)) {
        const handle = new Handle(pool, this, callback)
        pool.handles.push(handle)
        return handle
      }
    }

    const pool = { args }
    this.pools.push(pool)
    const handle = new Handle(pool, this, callback)
    pool.handles = [handle]
    pool.ready = new Promise((resolve, reject) => {
      pool.subscription = this._cable.subscriptions.create(args, {
        connected() {
          resolve()
        },
        rejected(reason) {
          reject(reason)
        },
        received(d) {
          for (const handle of pool.handles) {
            if (handle.callback) handle.callback(d)
          }
        },
        disconnected({ willAttemptReconnect }) {
          if (!willAttemptReconnect)
            console.error("channel won't reconnect, shouldn't happen")
        },
      })
    })
    return handle
  }

  unsubscribe(handle) {
    const { pool } = handle
    const index = pool.handles.indexOf(handle)
    pool.handles.splice(index, 1)

    /*  XXX: we leak subscriptions, but that should mostly be ok.  This code below doesn't work
     *  because we don't know when the unsubscription actually happens unless we listen to the
     *  disconnected event, and we never actually get a connected event if we subscribe to a duplicate
     *  channel args, so we would have to do a bunch of state tracking to make sure everything happens in the
     *  right order if you have a subscribe that follows immediately after an unsubscribe (which happens when one
     *  component replaces another).
     *  Naive code implementation left as a warning:
    if (pool.handles.length == 0) {
      pool.subscription.unsubscribe()
      const sub_index = this.pools.indexOf(pool)
      this.pools.splice(sub_index, 1)
    }
    */
  }
}

class Handle {
  constructor(pool, manager, callback) {
    this.manager = manager
    this.pool = pool
    this.callback = callback
    this.ready = pool.ready
  }

  perform(...args) {
    this.pool.subscription.perform(...args)
  }

  unsubscribe() {
    this.manager.unsubscribe(this)
  }
}
