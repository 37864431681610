import { gql } from "@urql/core"
import _get from "lodash-es/get"
import _pick from "lodash-es/pick"
import { app as appFragment, appWiths } from "@graphql/fragments"

export function invalidateAppQueries(cache) {
  cache
    .inspectFields("Query")
    .filter(f => ["admin", "my", "viewer"].includes(f.fieldName))
    .forEach(c => {
      const context = cache.resolve("Query", c.fieldKey)
      cache
        .inspectFields(context)
        .filter(f => ["apps", "appConnections"].includes(f.fieldName))
        .forEach(f => {
          cache.invalidate(context, f.fieldKey)
        })
    })
  cache
    .inspectFields("Query")
    .filter(f => ["apps", "appConnections"].includes(f.fieldName))
    .forEach(f => cache.invalidate("Query", f.fieldKey))
}

function reqPrepare(_req) {
  const params = ["urlParams", "headerParams", "bodyParams"]
  const req = _pick(_req, [
    "httpMethod",
    "url",
    "authorization.type",
    "authorization.basicUsername",
    "authorization.basicPassword",
    "authorization.bearerToken",
    "extracts",
    ...params,
    "cellParams", // cellParams only for test request
  ])
  req.extracts = (req.extracts || []).map(extract =>
    _pick(extract, ["field", "path"])
  )
  for (const p of params) {
    if (!req[p]) continue
    for (let i = 0; i < req[p].length; i++) {
      req[p][i] = _pick(req[p][i], ["key", "value"])
    }
  }
  for (const idx in req.cellParams || []) {
    req.cellParams[idx] = _pick(req.cellParams[idx], ["name"])
  }
  return req
}

function appPrepare(app) {
  const appInput = _pick(app, [
    "adminOnly",
    "authType",
    "customFields",
    "customFieldsDescriptionMd",
    "description",
    "websiteUrl",
    "name",
    "oauthReqsByType.accessToken",
    "oauthReqsByType.authorization",
    "oauthReqsByType.refreshToken",
    "oauthReqsByType.requestToken",
    "oauthReqsByType.test",
    "oauthScopesNsv",
    "officialOauthApp.clientId",
    "officialOauthApp.clientSecret",
    "officialOauthApp.name",
    "officialOauthApp.oauthSignerUri",
    "parentAppId",
    "testRequest",
    "testRequestCodeRaw",
    "apphookDeployedComponentId",
  ])
  appInput.featuredWeight = parseInt(app.featuredWeight)
  for (const reqType in appInput.oauthReqsByType) {
    appInput.oauthReqsByType[reqType] = reqPrepare(
      appInput.oauthReqsByType[reqType]
    )
  }
  appInput.testRequest = reqPrepare(appInput.testRequest)
  appInput.customFields = appInput.customFields.map(obj => {
    const ret = _pick(obj, ["name", "type", "options"])
    if (ret.options)
      ret.options = ret.options.map(o => _pick(o, ["label", "value"]))
    return ret
  })
  appInput.oauthScopes = appInput.oauthScopesNsv.split("\n").filter(o => o)
  delete appInput.oauthScopesNsv
  return appInput
}

export default {
  async mutate(appData) {
    const appInput = appPrepare(appData)
    const resp = await this.mutation(
      gql`mutation saveApp(
      $id: String
      $app: AppInput!
      ${appWiths.fragmentPart}
    ) {
      saveApp(
        id: $id
        app: $app
      ) {
        app {
          ...AppParts
        }
        errors
      }
    }
    ${appFragment}
  `,
      {
        id: appData.id,
        app: appInput,
        withAppExtraParts: true,
        withOfficialOauthApp: true,
        withSubAppIds: true,
        withTestRequestCodeRaw: true,
      }
    ).toPromise()
    const { app, errors } = resp.data.saveApp
    if (errors && errors.length) throw errors
    return app
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "saveApp.errors.length")) {
      const id = _get(result, "saveApp.app.id")
      if (id) {
        invalidateAppQueries(cache)
      }
    }
  },
}
