import { gql } from "@urql/core"
import _get from "lodash-es/get"
import {
  deployedComponent as deployedComponentFragment,
  deployedComponentWiths,
} from "@graphql/fragments"
import { DEPLOYED_COMPONENTS_QUERY } from "@graphql/queries/my"

export default {
  async mutate({
    id,
    orgId,
    url,
    key,
    configuredProps,
    name,
    dynamicPropsId,
    ...withs
  }) {
    const mutation = gql`mutation deployComponent(
      $orgId: String
      $id: String
      $url: String
      $key: String
      $configuredPropsJson: String
      $name: String
      $dynamicPropsId: String
      ${deployedComponentWiths.fragmentPart}
    ) {
      deployComponent(
        orgId: $orgId
        id: $id
        url: $url
        key: $key
        configuredPropsJson: $configuredPropsJson
        name: $name
        dynamicPropsId: $dynamicPropsId
      ) {
        errors
        observations
        deployedComponent {
          ...DeployedComponentParts
        }
      }
    }
    ${deployedComponentFragment}`
    const variables = {
      id,
      url,
      key,
      name,
      ...withs,
    }
    if (orgId) variables.orgId = orgId
    if (dynamicPropsId) variables.dynamicPropsId = dynamicPropsId
    if (configuredProps)
      variables.configuredPropsJson = JSON.stringify(configuredProps)
    const result = await this.mutation(mutation, variables).toPromise()
    const {
      errors,
      deployedComponent,
      observations,
    } = result.data.deployComponent
    if (errors && errors.length) throw errors
    return { deployedComponent, observations }
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "deployComponent.errors.length")) {
      const { deployedComponent } = result.deployComponent
      cache.updateQuery({ query: DEPLOYED_COMPONENTS_QUERY }, data => {
        if (data) data.my.deployedComponents.unshift(deployedComponent)
        return data
      })
    }
  },
}
