import _get from "lodash-es/get"
import _pick from "lodash-es/pick"
import {
  authProvision as authProvisionFragment,
  authProvisionWiths,
} from "@graphql/fragments"

export function invalidateAuthProvisionQueries(cache) {
  cache
    .inspectFields("Query")
    .filter(f => ["admin", "my", "viewer"].includes(f.fieldName))
    .forEach(c => {
      const context = cache.resolve("Query", c.fieldKey)
      cache
        .inspectFields(context)
        .filter(f =>
          ["authProvisions", "authProvisionConnections"].includes(f.fieldName)
        )
        .forEach(f => {
          cache.invalidate(context, f.fieldKey)
        })
    })
  cache
    .inspectFields("Query")
    .filter(f =>
      ["authProvisions", "authProvisionConnections"].includes(f.fieldName)
    )
    .forEach(f => cache.invalidate("Query", f.fieldKey))
}

export default {
  async mutate(id, authProvision, orgId, test) {
    const authProvisionInput = _pick(authProvision, ["name", "appId"])
    if (authProvision.cfmap) {
      authProvisionInput.cfmapJson = JSON.stringify(authProvision.cfmap)
    }
    const resp = await this.mutation(
      `
      mutation saveAuthProvision(
        $id: String
        $authProvision: AuthProvisionInput!
        $orgId: String
        $test: Boolean
        ${authProvisionWiths.fragmentPart}
      ) {
        saveAuthProvision(id: $id, authProvision: $authProvision, orgId: $orgId, test: $test) {
          authProvision {
            ...AuthProvisionParts
          }
          errors
          testSuccess
        }
      }
      ${authProvisionFragment}
    `,
      {
        id,
        authProvision: authProvisionInput,
        orgId,
        test,
      }
    ).toPromise()
    // XXX testSuccess handling or move to other mutation!!!
    const {
      authProvision: savedAuthProvision,
      errors,
    } = resp.data.saveAuthProvision
    if (errors && errors.length) throw errors
    if (!savedAuthProvision) throw ["could not save auth provision"]
    // authProvisionRevive(savedAuthProvision)
    return savedAuthProvision
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "saveAuthProvision.errors.length")) {
      const id = _get(result, "saveAuthProvision.authProvision.id")
      if (id) {
        invalidateAuthProvisionQueries(cache)
      }
    }
  },
}
