import { Sentry } from "@sentry"

export const EXPERIMENTS = {
  HOME_EMBEDDED_SIGN_UP: {
    id: "0", // do not change this!!
    assign() {
      return Math.random() < 0.5 // simple coin flip, value is true | false
    },
    completed: true,
  },
  // experiment ended 01/28/20
  // EVENT_SOURCE_INSTEAD_OF_HTTP_NATIVE: {
  //   id: "1",
  //   assign() {
  //     return Math.random() < 0.5
  //   },
  // },
}

const localStorageKey = "pd:exp"

// TODO move this to hybridStorage!!
function loadState() {
  if (!localStorage) return null // if no storage supported, just don't assign to experiments
  let state
  try {
    state = JSON.parse(localStorage.getItem(localStorageKey))
  } catch (e) {
    Sentry.captureException(e)
  }
  state = state || {}
  if (!state.a) state.a = {} // assignments
  return state
}

function persistState(state) {
  localStorage.setItem(localStorageKey, JSON.stringify(state))
}

function generateTraits(state) {
  const ret = {}
  for (const id in state.a) {
    ret[`exp_${id}`] = state.a[id]
  }
  return ret
}

function generate$experiments(state) {
  const ret = {}
  for (const name in EXPERIMENTS) {
    const exp = EXPERIMENTS[name]
    ret[name] = state.a[exp.id]
  }
  return ret
}

// this gets assigned to Vue.prototype.$experiments (for now)
// XXX assumes all cohort assignments are done by the time this completes and are immutable
// XXX pass some context in here or the assign should be async and possibly do api calls, etc.?
export function init($analytics) {
  if (import.meta.env.SSR) return {}
  const state = loadState()
  if (!state) {
    return {}
  }
  for (const name in EXPERIMENTS) {
    const exp = EXPERIMENTS[name]
    if (exp.completed) continue
    if (exp.id in state.a) continue // already assigned
    if (exp.assign) state.a[exp.id] = exp.assign()
  }
  persistState(state)
  const traits = generateTraits(state)
  if (Object.keys(traits).length) {
    // By default, traits are cached in the browser’s localStorage and are attached to each subsequent Identify call.
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
    $analytics.identify(null, traits)
  }
  return generate$experiments(state)
}
