import { gql } from "@urql/core"
import { pipeline, pipelineWiths } from "@graphql/fragments"

export default gql`
  query pipelineConnections(
    $after: String
    $q: String
    $ids: [String!]
    $emits: Boolean
    $public: Boolean
    $state: PipelineStateField
    $orderBy: OrderByPipelineInput
    ${pipelineWiths.fragmentPart}
  ) {
    pipelineConnections(
      after: $after
      first: 20
      q: $q
      ids: $ids
      emits: $emits
      public: $public
      state: $state
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...PipelineParts
      }
    }
  }
  ${pipeline}
`
