import { reactive } from "vue"
import _camelCase from "lodash-es/camelCase"
import _keyBy from "lodash-es/keyBy"
import _upperFirst from "lodash-es/upperFirst"

// on windows default to control on mac default to windows
let isMac = false
try {
  isMac = navigator.platform.match(/^Mac/gi)
} catch (e) {
  // do nothing
}
const defaultMeta = isMac ? "meta" : "ctrl"

const shortcutsData = {
  "workflow.save": {
    label: "Save Workflow",
    defaultBinding: `${defaultMeta}.s`,
  },
  "workflow.deploy": {
    label: "Deploy Workflow",
    defaultBinding: `${defaultMeta}.d`,
  },
  "workflow.sendTestEvent": {
    label: "Send Test Event",
    defaultBinding: `${defaultMeta}.e`,
  },
  "workflow.replayLastEvent": {
    label: "Replay Most Recent Event",
    defaultBinding: `shift.${defaultMeta}.l`,
  },
}

// XXX changing this will break custom keybindings
function bindingFromEvent(e) {
  if (!e.altKey && !e.ctrlKey && !e.metaKey) return
  let binding = ""
  if (e.shiftKey) binding += "shift."
  if (e.ctrlKey) binding += "ctrl."
  if (e.metaKey) binding += "meta."
  if (e.key && e.key.length === 1) {
    binding += e.key
  } else {
    // they should at least have a key in there
    return
  }
  return binding
}

const LOCAL_STORAGE_KEY = "shortcuts:custom-bindings"

// XXX be more explicit about  meta vs. ctrl ?
export function createShortcuts() {
  let customBindings
  try {
    customBindings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) // id -> binding
  } catch (err) {
    // do nothing
  }
  customBindings = customBindings || {}

  const all = reactive([])
  for (const id in shortcutsData) {
    const d = shortcutsData[id]
    const binding = customBindings[id] || d.defaultBinding
    all.push({
      id,
      label: d.label,
      defaultBinding: d.defaultBinding,
      defaultBindingRendered: renderBinding(d.defaultBinding),
      binding,
      bindingRendered: renderBinding(binding),
    })
  }

  const shortcutsByBinding = _keyBy(all, "binding")

  const _setBinding = (shortcut, binding) => {
    if (shortcut.binding === binding) return

    // update shortcutsByBinding
    delete shortcutsByBinding[shortcut.binding]
    shortcut.binding = binding
    shortcutsByBinding[shortcut.binding] = shortcut

    shortcut.bindingRendered = renderBinding(binding)

    // update customBindings that get saved to localStorage
    if (shortcut.defaultBinding !== binding) {
      customBindings[shortcut.id] = binding
    } else {
      delete customBindings[shortcut.id]
    }
  }

  const setBinding = (shortcut, binding) => {
    // XXX validate binding is appropriate?
    for (const _shortcut of all) {
      // unset shortcuts with this binding already
      if (_shortcut.binding === binding) {
        _setBinding(_shortcut, "")
      }
    }
    _setBinding(shortcut, binding)
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(customBindings))
    return true
  }

  const test = e => {
    const binding = bindingFromEvent(e)
    if (!binding) return
    let shortcut = shortcutsByBinding[binding]
    if (shortcut) return shortcut.id
  }

  const setBindingToEvent = (shortcut, e) => {
    const binding = bindingFromEvent(e)
    if (!binding) return false
    return setBinding(shortcut, binding)
  }

  const setDefaultBinding = shortcut =>
    setBinding(shortcut, shortcut.defaultBinding)

  return {
    all,
    setBindingToEvent,
    setDefaultBinding,
    test,
  }
}

function renderBinding(binding) {
  if (!binding) return "" // if empty (ie. unset)
  let modifiers = binding.split(".")
  if (isMac) {
    return modifiers
      .map(m => {
        if (m == "ctrl") return "^"
        if (m == "meta") return "⌘"
        if (m == "shift") return "⇧"
        return _upperFirst(_camelCase(m))
      })
      .join("")
  } else {
    return modifiers
      .map(m => {
        return _upperFirst(_camelCase(m))
      })
      .join(" + ")
  }
}
