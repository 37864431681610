import { gql } from "@urql/core"
import { source } from "@graphql/fragments"

export default gql`
  query source($id: String!) {
    source(id: $id) {
      ...SourceParts
    }
  }
  ${source}
`
