import { gql } from "@urql/core"

export default gql`
  query adminOrgs(
    $after: String
    $q: String
    $first: Int = 20
    $orderBy: [OrgOrderByEnum!]
    $subscribed: Boolean
  ) {
    admin {
      orgs(
        after: $after
        first: $first
        q: $q
        orderBy: $orderBy
        subscribed: $subscribed
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          name
          nickname
          email
          accountType
          basePeriodInvocations
          extraDailyInvocations
          lambdaTimeQuota
          createdAt
          stripeCustomerId
          stripeSubscriptionActive
          stripeSubscriptionId
        }
      }
    }
  }
`
