import { Sentry } from "@sentry"
import { Validation } from "@/src/utils/validation"
import isIdentifier from "@/src/utils/isIdentifier"
import { identifier } from "@plugins/safe-identifier"
import { componentNeedsConfiguring } from "@/src/utils/component"
//import { Feature } from "@/src/features"

import _cloneDeep from "lodash-es/cloneDeep"
import _camelCase from "lodash-es/camelCase"
import _defaults from "lodash-es/defaults"
import _defaultsDeep from "lodash-es/defaultsDeep"
import _find from "lodash-es/find"
import _get from "lodash-es/get"
import _isEqual from "lodash-es/isEqual"
import _isFinite from "lodash-es/isFinite"
import _omit from "lodash-es/omit"
import _toNumber from "lodash-es/toNumber"
import _toPlainObject from "lodash-es/toPlainObject"
import _truncate from "lodash-es/truncate"
import _union from "lodash-es/union"
import _values from "lodash-es/values"
import { validate as jsonschemaValidate } from "jsonschema"
import { atob, btoa } from "@utils/base64"

// smart-text-input -> smarter-text-input conversion
// XXX if anyone actually uses these fields it could cause weirdness...
// ...should only do the conversion if we know pipeline hasn't been converted yet to minimize chance
// (ie. deployment updatedAt is before 08/14/20, etc.)
const LEFT_ARROW = "\u2af7" // ⫷
const RE_GLOBAL_LEFT_ARROW = new RegExp(LEFT_ARROW, "g")
const RIGHT_ARROW = "\u2af8" // ⫸
const RE_GLOBAL_RIGHT_ARROW = new RegExp(RIGHT_ARROW, "g")

export function Many(model) {
  return {
    prepare(datas = []) {
      if (model.prepare) {
        for (const data of datas) {
          model.prepare(data)
        }
      }
      return datas
    },
    revive(datas = []) {
      if (model.revive) {
        for (const data of datas) {
          model.revive(data)
        }
      }
      return datas
    },
  }
}

export const defaultTriggerSourceParams = {
  enabled: false,
  trigger_type: "timer_duration",
  timer_duration: 60, // every hour
  cron_expression: "0 */1 * * *", // every hour
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  pipeline_id: null,
}

export function isPropValueEmpty(o) {
  if (!o) return true
  if (Array.isArray(o)) {
    if (!o.length) return true
    for (const el of o) {
      if (!isPropValueEmpty(el)) return false
    }
    return true
  }
  if (typeof o === "object") {
    for (const k in o) {
      if (k !== "" || o[k] !== "") return false
    }
    return true
  }
  return false
}

export class Cell {
  static destinationParams(destination_kind) {
    switch (destination_kind) {
      default:
        return []
      case "sql":
        return ["table"]
      // prefix is strict -- you need to have slash if you want
      // key should be YYYY-MM-DD-TIMESTAMP or something
      case "s3-out":
        return ["bucket", "prefix"]
      case "http-out":
        return ["url"]
    }
  }
  static from(cell) {
    Cell.__nextId = Cell.__nextId || 0
    const newCell = _cloneDeep(cell)
    newCell.id = undefined
    newCell._id = `c_new-cell-${Math.floor(Math.random() * 100000000000)}`
    if (!newCell.disabled) newCell.disabled = false
    switch (newCell.type) {
      case "CodeCell":
        _defaults(newCell, {
          codeRaw: "",
          lang: "nodejs",
          _params: {},
          _flat_params_visibility: {},
          appConnections: [],
          authProvisionIds: [],
        })
        if (newCell.codeConfigJson)
          newCell._codeConfig = JSON.parse(newCell.codeConfigJson)
        break
      case "ActionCell":
        _defaults(newCell, {
          action_id: null,
          _action_params: {}, // TODO deprecate
          action_return_path: "",
          _params: {},
          _flat_params_visibility: {},
          namespace: newCell.action.defaultNamespace,
          authProvisionIds: [],
        })
        if (newCell.action && newCell.action.codeConfigJson) {
          newCell.action._codeConfig = JSON.parse(newCell.action.codeConfigJson)
        }
        break
      case "TextCell":
        _defaults(newCell, {
          text_raw: "",
        })
        break
      case "SourceCell":
        _defaultsDeep(newCell, {
          source_id: null,
          _source_params: defaultTriggerSourceParams,
        })
        break
    }
    return newCell
  }
  static groups(c) {
    const group = `cell-${c.id || c._id}`
    return {
      default: group,
      diff: `${group}-diff`,
      log: `${group}-log`,
    }
  }
  static placeholders(type) {
    return _get(
      {
        "http-out": {
          url: "Enter Webhook URL",
        },
        "s3-out": {
          bucket: "Enter S3 Bucket name",
          prefix: "Enter S3 Prefix (optional)",
        },
        sql: {
          table: "Enter table name",
        },
      },
      type,
      {}
    )
  }
  static peers(c) {
    return _values(Cell.groups(c))
  }
  static prepare(c) {
    if (c) {
      switch (c.type) {
        case "ActionCell":
          c.action_params_json = JSON.stringify(c._action_params || {})
          c.params_json = JSON.stringify(c._params || {})
          c.flat_params_visibility_json = JSON.stringify(
            c._flat_params_visibility || {}
          )
          // https://github.com/thoughtbot/til/blob/master/rails/deep_munge.md
          for (const idx in c.authProvisionIds) {
            if (!c.authProvisionIds[idx]) c.authProvisionIds[idx] = 0
          }
          break
        case "CodeCell":
          c.lang = c.lang || "nodejs"
          c.code_raw = c.codeRaw // until we graphql everything
          if (c._codeConfig) c.code_config_json = JSON.stringify(c._codeConfig)
          c.params_json = JSON.stringify(c._params || {})
          c.flat_params_visibility_json = JSON.stringify(
            c._flat_params_visibility || {}
          )
          // https://github.com/thoughtbot/til/blob/master/rails/deep_munge.md
          for (const idx in c.authProvisionIds) {
            if (!c.authProvisionIds[idx]) c.authProvisionIds[idx] = 0
          }
          if (c.configuredProps) {
            c.configured_props_json = JSON.stringify(c.configuredProps)
          }
          if (c.savedComponent) {
            c.saved_component_id = c.savedComponent.id
            c.component_key = c.savedComponent.key
            // TODO this doesn't seem to be a dupe of cell... would be nice to not send this though
            // delete c.savedCompoment
          }
          c.component_owner_id = c.componentOwnerId
          break
        case "SourceCell":
          c.source_params = JSON.stringify(c._source_params || {})
          break
      }
    }
  }
  static revive(c) {
    switch (c.type) {
      case "ActionCell":
        c.namespace = c.namespace || c.action.defaultNamespace
        try {
          c._action_params = JSON.parse(c.action_params_json || "{}")
        } catch (e) {
          Sentry.captureException(e)
          c._action_params = {}
        }
        c._params = JSON.parse(
          (c.params_json || "{}")
            .replace(RE_GLOBAL_LEFT_ARROW, "{{")
            .replace(RE_GLOBAL_RIGHT_ARROW, "}}")
        )
        try {
          c._flat_params_visibility = JSON.parse(
            c.flat_params_visibility_json || {}
          )
        } catch (e) {
          c._flat_params_visibility = {}
        }
        if (!c.authProvisionIds) c.authProvisionIds = []
        if (c.action.codeConfigJson)
          c.action._codeConfig = JSON.parse(c.action.codeConfigJson)
        break
      case "CodeCell":
        c.lang = c.lang || "nodejs"
        c._params = JSON.parse(
          (c.params_json || "{}")
            .replace(RE_GLOBAL_LEFT_ARROW, "{{")
            .replace(RE_GLOBAL_RIGHT_ARROW, "}}")
        )
        try {
          c._flat_params_visibility = JSON.parse(
            c.flat_params_visibility_json || {}
          )
        } catch (e) {
          c._flat_params_visibility = {}
        }
        if (c.authProvisionIds) {
          c.authProvisionIds = c.authProvisionIds.map(id =>
            id == 0 ? null : id
          )
        } else {
          c.authProvisionIds = []
        }
        if (c.codeConfigJson) c._codeConfig = JSON.parse(c.codeConfigJson)
        if (c.configured_props_json)
          c.configuredProps = JSON.parse(c.configured_props_json)
        if (c.component_owner_id) c.componentOwnerId = c.component_owner_id
        if (c.component_key) c.componentKey = c.component_key
        break
      case "SourceCell":
        try {
          c.namespace = c.namespace || "trigger"
          c._source_params = JSON.parse(c.source_params || "{}")
        } catch (e) {
          Sentry.captureException(e)
          c._source_params = {}
        }
        break
      case "TextCell":
        c.text_raw = c.text_raw || null
        break
    }
    return c
  }
  static canonizeNamespace(cell, deployment = {}) {
    if (cell.type == "SourceCell" && !cell.namespace) {
      cell.namespace = "trigger"
      return
    }
    const reserved = { trigger: true }
    for (const c of deployment.cells || []) {
      const ns = c.namespace
      if (ns && (c.id || c._id) != (cell.id || cell._id)) {
        reserved[ns] = true
        const { root, suffix } = _get(
          ns.match(/^(?<root>.+)_(?<suffix>\d+)$/),
          "groups",
          {}
        )
        if (root) {
          reserved[root] = true
          let suffixNum = _toNumber(suffix)
          if (_isFinite(suffixNum)) {
            while (suffixNum >= 0) {
              reserved[`${root}_${suffixNum}`] = true
              suffixNum--
            }
          }
        }
      }
    }
    let prefix = cell.namespace
    if (!prefix) {
      switch (cell.type) {
        case "CodeCell":
          prefix = "nodejs"
          break
        case "ActionCell":
          if (cell.action && cell.action.title) {
            const { seekingAlpha } = _get(
              cell.action.title.match(/\d*(?<seekingAlpha>[^0-9].*)/),
              "groups",
              {}
            )
            if (seekingAlpha) {
              prefix = _camelCase(seekingAlpha.replace(/\.|\[|\]/, " "))
              break
            }
          }
        // falls through
        default:
          prefix = "step"
      }
    }
    let canonized = identifier(prefix)
    let nextSuffix = 1
    while (reserved[canonized]) {
      canonized = `${prefix}_${nextSuffix}`
      nextSuffix++
    }
    cell.namespace = canonized
  }

  static get validators() {
    return {
      namespace: [
        v => !v && "Step name is empty",
        v =>
          !isIdentifier(v) &&
          `"${_truncate(v, {
            length: 10,
          })}" is not a valid javascript identifier`,
      ],
      _source_params: [
        v =>
          v &&
          v.enabled &&
          v.trigger_type == "timer_duration" &&
          v.timer_duration < 0 &&
          "Trigger duration is invalid",
      ],
      _params: [
        (v, path, cell) => {
          const actionLike = cell.action || cell
          if (
            !actionLike._codeConfig ||
            !actionLike._codeConfig.params_schema
          ) {
            return
          }
          const errors = []
          const schema = actionLike._codeConfig.params_schema
          let res
          try {
            res = jsonschemaValidate(v, schema)
          } catch (err) {
            Sentry.captureException(err)
          }
          if (res && !res.valid) {
            for (const error of res.errors) {
              let propPath = error.property.replace(/^instance/, "")
              if (error.name === "required") propPath = `.${error.argument}`
              propPath = propPath.replace(/^\./, "")
              const value = _get(v, propPath)
              if (/({{|\u2af7)/.test(value)) continue // don't validate if using pills
              let message = error.message
              errors.push({ message, path: [...path, ...propPath.split(".")] })
            }
          }
          // need to ensure required objects / arrays are not empty (or have single but empty "rows")
          // XXX handle more deeply nested objects... probably should stop relying on jsonschema and just handle roll
          // ... but probably move away from using json schema entirely?
          for (const prop in schema.properties || {}) {
            if (!schema.required) continue
            if (!schema.required.includes(prop)) continue
            if (!v[prop]) continue
            if (isPropValueEmpty(v[prop]))
              errors.push({
                message: `requires property "${prop}"`,
                path: [...path, prop],
              })
          }
          return errors
        },
      ],
      component: [
        (v, _, cell) => {
          if (!v) return
          if (!cell.savedComponent || cell.savedComponent.code !== cell.codeRaw)
            return "Must save component code"
          if (
            componentNeedsConfiguring(
              cell.savedComponent,
              cell.configuredProps,
              cell.dynamicProps
            )
          )
            return "Must configure component step"
        },
      ],
    }
  }
  static validate(c, path = []) {
    if (c && !c.disabled) {
      const errors = Validation.validate(Cell.validators, c, path)
      if (errors.length > 0) {
        return errors
      } else {
        return null
      }
    }
  }
}

export class Deployment {
  static get empty() {
    return Deployment.revive({})
  }
  static _renumberCells(d) {
    // Set all the cell indices correctly
    let idx = 0
    for (const c of d.cells || []) {
      c.idx = idx++
    }
  }
  static sourceId(d) {
    if (!d) return
    if (d.source) return d.source.id
    for (const cell of d.cells || []) {
      // XXX figure out why this happens on deleting a cell (old code so maybe f it)
      if (!cell) continue
      if (cell.type === "SourceCell") {
        return cell.source_id
      }
    }
  }
  static source(d) {
    if (!d) return
    if (d.source) return d.source // TODO just mimic'ing above but is this even a thing??
    for (const cell of d.cells || []) {
      if (cell.type === "SourceCell") return cell.source
    }
  }
  static sourceCell(d) {
    if (!d) return
    for (const cell of d.cells || []) {
      if (cell.type === "SourceCell") return cell
    }
    return null
  }
  static prepare(d) {
    if (!d) return
    Deployment._renumberCells(d)
    for (const cell of d.cells) Cell.prepare(cell)
  }
  static revive(d) {
    _defaultsDeep(d, {
      cells: [],
      kvs: [], // XXX remove eventually
      // created_at
      id: null,
      pipeline_id: null,
      pipeline_name: "",
      platform_version: null,
      // updated_at
      version: 1,
    })
    // would like to not do this here...
    if (!_find(d.cells, { type: "SourceCell" })) {
      d.cells.unshift(Cell.from({ type: "SourceCell" }))
    }
    for (const cell of d.cells || []) {
      Cell.revive(cell)
      if (!cell.namespace) {
        Cell.canonizeNamespace(cell, d)
      }
    }
  }

  static validate(d, path = []) {
    const errors = Validation.validate(Deployment.validators, d, path)
    return errors
  }

  static get validators() {
    return {
      cells: [
        (v, path) => Validation.each(Cell.validate, v, path),
        (v, path) => {
          const duplicates = Validation.hasDuplicates(
            v.filter(c => !c.disabled).map(c => c.namespace)
          )
          return (
            duplicates && [
              {
                path: [...path, duplicates.a, "namespace"],
                message: `Step name '${duplicates.key}' must be unique`,
              },
              {
                path: [...path, duplicates.b, "namespace"],
                message: `Step name '${duplicates.key}' must be unique`,
              },
            ]
          )
        },
      ],
    }
  }
}

export class Pipeline {
  static prepare(p) {
    if (!p) return
    if (p.deployment) Deployment.prepare(p.deployment)
  }
  static revive(p) {
    _defaultsDeep(p, {
      archived: false,
      // created_at
      deployment: null,
      deployments: [],
      description: "",
      edit: false, // whether pipeline is editable
      endpoint_id: null,
      error_pipeline_id: null,
      error_triggered: false,
      id: null,
      inactive: false,
      name: "",
      name_slug: null,
      owner_id: null,
      owner_name: null,
      owner_type: "User",
      public: true,
      data_public: false,
      readme_md: "",
      source_adapter_code_raw: "",
      test_event_json: null,
      parent_deployment: null,
      // updated_at
    })
    if (p.deployment) Deployment.revive(p.deployment)
  }
  static get validators() {
    return {
      deployment: Deployment.validate,
    }
  }
  static validate(p) {
    if (p) {
      // XXX move the validations underneat into the cell validations?
      // ... probably was only done this way to get the cell indices into errors?
      // ... need to order so account require comes before param validations
      const errors = Validation.validate(Pipeline.validators, p)
      let cellIdx = 0
      const usesNativeHttpTrigger =
        _get(p.deployment.cells[0], "source_id") === "s_Y53Czr"
      for (const c of p.deployment.cells) {
        if (!c.disabled) {
          let appConnections = null
          if (c.type === "SourceCell") {
            if (c._source_params.newDc) {
              errors.push({
                message: "Must first create trigger",
                path: ["deployment", "cells", `${cellIdx}`],
                canSave: true,
              })
            }
          } else if (c.type === "CodeCell" && c.appConnections) {
            appConnections = c.appConnections
          } else if (c.type === "ActionCell" && c.action.appConnections) {
            appConnections = c.action.appConnections
          }
          if (appConnections) {
            for (const idx in appConnections) {
              if (typeof c.authProvisionIds[idx] === "undefined") {
                errors.push({
                  message: `Account required at steps.${c.namespace}`,
                  path: ["deployment", "cells", `${cellIdx}`],
                  canSave: true,
                })
              }
            }
          }
          // parse errors
          if (
            c.type === "CodeCell" &&
            c.lang == "nodejs" &&
            c._codeConfig &&
            c._codeConfig.parseError
          ) {
            errors.push({
              message: `Syntax error in steps.${c.namespace}`,
              path: ["deployment", "cells", `${cellIdx}`],
              canSave: true,
            })
          }

          // unsupported symbols
          if (
            c.type === "CodeCell" &&
            c._codeConfig &&
            c._codeConfig.deprecatedNodes &&
            c._codeConfig.deprecatedNodes.length
          ) {
            errors.push({
              message: `Unsupported symbol in steps.${c.namespace}`,
              path: ["deployment", "cells", `${cellIdx}`],
              canSave: true,
            })
          }

          if (!usesNativeHttpTrigger) {
            let codeRaw
            if (c.type === "CodeCell") {
              codeRaw = c.codeRaw
            } else if (c.type === "ActionCell") {
              codeRaw = c.action.codeRaw
            }
            // XXX do this via parse instead of string search...
            if (
              codeRaw &&
              codeRaw.indexOf("$respond(") >= 0 &&
              true // XXX !Feature.flags.incremental
            ) {
              errors.push({
                message: `$respond can only be used with HTTP API trigger in steps.${c.namespace}`,
                path: ["deployment", "cells", `${cellIdx}`],
                canSave: true,
              })
            }
          }
        }
        cellIdx++
      }
      if (errors.length > 0) {
        return errors
      } else {
        return null
      }
    }
  }
  static get hashCellKeysByType() {
    const base = ["d", "n", "t"]
    return {
      ActionCell: _union(base, ["a", "f", "v"]),
      CodeCell: _union(base, ["_c", "_a", "c", "f", "v", "l"]),
      SourceCell: _union(base, ["s", "_s"]),
    }
  }
  static get hashCellKeyMap() {
    return {
      _a: "appConnections",
      _s: "_source_params",
      a: "action_id",
      c: "codeRaw",
      d: "disabled",
      f: "_params",
      n: "namespace",
      s: "source_id",
      t: "type",
      v: "_flat_params_visibility",
      l: "lang",
    }
  }
  static get hashCellValueDefaults() {
    return {
      _s: defaultTriggerSourceParams, // TODO minimize keys? also, timezone across hash restores
      a: [],
      c: "",
      d: false,
      f: {},
      n: "trigger", // this allows to save on source cell
      s: "s_GgdCwr", // cron trigger (XXX something else?)
      t: "CodeCell", // XXX or action cell? ... would need new key to change default (XXX could map to short versions)
      l: "nodejs",
      v: {},
    }
  }
  static toHash(p) {
    const o = {}
    if (p.name) o.n = p.name
    for (const cell of _get(p, "deployment.cells", [])) {
      const c = {}
      for (const k in Pipeline.hashCellKeyMap) {
        const key = Pipeline.hashCellKeyMap[k]
        if (_isEqual(cell[key], Pipeline.hashCellValueDefaults[k])) continue
        if (!Pipeline.hashCellKeysByType[cell.type].includes(k)) continue
        c[k] = cell[key]
      }
      if (!o.c) o.c = []
      o.c.push(c)
    }
    let hash = ""
    if (Object.keys(o).length) hash = btoa(JSON.stringify(o))
    // console.log("Pipeline.toHash")
    // console.log(o)
    // console.log(hash)
    if (hash.length > 2000) {
      throw new Error("hash greater than 2,000 chars, risky")
    }
    return hash
  }
  static fromHash(hash) {
    let o = {}
    try {
      o = JSON.parse(atob(hash))
    } catch (err) {
      /* bad hash */
    }
    // console.log("Pipeline.fromHash")
    // console.log(hash)
    // console.log(o)
    const pipeline = {}
    Pipeline.revive(pipeline)
    pipeline.deployment = {}
    const cells = (pipeline.deployment.cells = [])
    if (o.n) pipeline.name = o.n // XXX can show something bad in url
    for (const c of o.c || []) {
      const cell = { type: c.t || Pipeline.hashCellValueDefaults.t }
      for (const k in Pipeline.hashCellKeyMap) {
        if (k === "t") continue // done above (needed in loop so done before)
        if (!Pipeline.hashCellKeysByType[cell.type].includes(k)) continue
        const key = Pipeline.hashCellKeyMap[k]
        cell[key] = c[k] || Pipeline.hashCellValueDefaults[k]
      }
      cells.push(cell)
    }
    return pipeline
  }
  static fromDeployment(deployment) {
    const pipeline = {}
    pipeline.deployment = {}
    Pipeline.revive(pipeline)
    pipeline.deployment.cells = []
    pipeline.parent_deployment = deployment.id
    let id = 0
    for (const cell of deployment.cells || []) {
      cell._id = `c_new-cell-${id++}`
      const newCell = _omit(cell, ["id"])
      pipeline.deployment.cells.push(newCell)
    }
    return pipeline
  }
  static routerToParams(pipeline, deployment = false) {
    const toParams = {
      id: deployment ? pipeline.deployment.id : pipeline.id,
      ownerName: pipeline.owner_name,
    }
    if (pipeline.name_slug) {
      toParams.nameSlug = `${pipeline.name_slug}-`
    }
    return toParams
  }
  static sourceInterface(p) {
    const cells = _get(p, "deployment.cells")
    if (cells) {
      for (const cell of cells) {
        if (cell.type === "SourceCell") {
          return _get(cell, "source.interface")
        }
      }
    }
    return null
  }
}

export class Source {
  static validate(s) {
    if (!s.enabled) return {}
    if (!s.name) return { name: "Name required" }
    if (!s.img_src) return { img_src: "Image source required" }
  }
  static from(s = {}) {
    s = _defaults(s, {
      version: 1,
      interface: "HTTP",
      id: undefined,
      name: "",
      description: "",
      instructions: "",
      category: null,
      img_src: "",
      enabled: false,
      display_order: 0,
      test_event_json: null,
    })
    if (!s.display_order) s.display_order = 0
    return s
  }
  static prepare(s) {
    s = _toPlainObject(_defaults(s, Source.from()))
    s.display_order = _toNumber(s.display_order)
  }
  static revive(s) {
    return Source.from(s)
  }
}
