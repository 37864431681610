const {
  VITE_CONTAINER,
  VITE_API_BASE_URL,
  VITE_CATCHER_BASE_URL,
  VITE_CLIENT_BASE_URL,
  VITE_EVENT_API_BASE_URL,
  VITE_OAUTH_GITHUB_CLIENT_ID,
  VITE_OAUTH_GOOGLE_CLIENT_ID,
  VITE_PD_CONTACT_US,
  VITE_PD_SDK_PRODUCT_METRICS,
  VITE_PD_USER_REQUESTS,
  VITE_RT_BASE_URL,
  VITE_SEGMENT_WRITE_KEY,
  VITE_STRIPE_PK,
  VITE_PIPELINE_DATA_VIZ_EDITOR_UID,
} = import.meta.env

export let API_BASE_URL = VITE_API_BASE_URL
export let EVENT_API_BASE_URL = VITE_EVENT_API_BASE_URL
export let RT_BASE_URL = VITE_RT_BASE_URL
// XXX if we fix the networking issues, we should do something similar in prod
// XXX would be nice if these changes were logged at startup (vite.environment logging says otherwise)
if (!import.meta.env.PROD && VITE_CONTAINER && import.meta.env.SSR) {
  // XXX: Ideally we would take port numbers from API_SERVICE_PORT, but I couldn't make it work
  API_BASE_URL = `http://api:5001`
  EVENT_API_BASE_URL = `http://event-api:5004`
  RT_BASE_URL = `http://rt:5002`
}

export const CATCHER_BASE_URL = VITE_CATCHER_BASE_URL
export const CLIENT_BASE_URL = VITE_CLIENT_BASE_URL
export const OAUTH_GITHUB_CLIENT_ID = VITE_OAUTH_GITHUB_CLIENT_ID
export const OAUTH_GOOGLE_CLIENT_ID = VITE_OAUTH_GOOGLE_CLIENT_ID
export const PD_CONTACT_US = VITE_PD_CONTACT_US
export const PD_SDK_PRODUCT_METRICS = VITE_PD_SDK_PRODUCT_METRICS
export const PD_USER_REQUESTS = VITE_PD_USER_REQUESTS
export const SEGMENT_WRITE_KEY = VITE_SEGMENT_WRITE_KEY
export const STRIPE_PK = VITE_STRIPE_PK
export const PIPELINE_DATA_VIZ_EDITOR_UID = VITE_PIPELINE_DATA_VIZ_EDITOR_UID

export function getEndpointUrl(endpointId) {
  return CATCHER_BASE_URL.replace(/\/\/EID/, `//${endpointId}`)
}
