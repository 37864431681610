import * as lr from "@plugins/logrocket"
import { Sentry } from "@sentry"
import * as appcues from "@plugins/appcues"
import { SEGMENT_WRITE_KEY } from "@/src/config"

// localStorage.setItem("debug:analytics", 1) to see analytics calls in development
function debug(type, ...args) {
  if (import.meta.env.PROD) return
  if (!localStorage.getItem("debug:analytics")) return
  console.log(`[debug:analytics:${type}]`, ...args)
}

const noop = () => {}
const disabledAnalytics = {
  enabled: false,
  identify: noop,
  page: noop,
  reset: noop,
  track: noop,
}

function _createAnalytics(enabled = true) {
  if (!enabled) return disabledAnalytics

  // injected in index.html head
  let analytics = window.analytics

  analytics.load(SEGMENT_WRITE_KEY)

  const identify = async (user, traits) => {
    debug("identify", user, traits)

    try {
      if (user) {
        analytics.identify(user.id, traits)
      } else {
        analytics.identify(traits)
      }
    } catch (e) {
      Sentry.captureException(e)
    }

    if (user) {
      try {
        // https://docs.logrocket.com/reference#identify
        lr.identify(user.id, {
          name: user.username,
          admin: user.admin,
        })
      } catch (e) {
        Sentry.captureException(e)
      }
    }
  }

  const page = async route => {
    const { matched, ..._route } = route
    const page = []
    let category = null
    for (const partial of matched) {
      if (partial.meta) {
        category = partial.meta.category || category
        if (partial.meta.page) page.push(partial.meta.page)
      }
    }
    if (_route.redirectedFrom) {
      const _redirectedFrom = { ..._route.redirectedFrom }
      delete _redirectedFrom.matched
      _route.redirectedFrom = _redirectedFrom
    }
    const properties = {
      path: _route.path,
      route: _route,
      url: `${window.location.origin}${_route.fullPath}`,
      search: "",
    }
    if (Object.keys(_route.query).length) {
      properties.search =
        "?" + new URLSearchParams({ ..._route.query }).toString()
    }
    const pageName = page.join(":") || "Unknown"

    debug("page", pageName, properties)

    try {
      analytics.page(pageName, properties)
    } catch (e) {
      Sentry.captureException(e)
    }

    try {
      appcues.page()
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const track = async (action, properties = {}, options = {}) => {
    properties = { ...properties }
    // analytics.js is not correctly updating the page context. XXX ?
    // Set a better default.
    options = {
      context: {
        app: {
          name: "frontend-next",
        },
        page: {
          url: window.location.href,
          path: window.location.pathname,
          search: window.location.search,
        },
      },
      ...options,
    }
    properties.category = properties.category || "Frontend" // for GA

    debug("track", action, properties, options)

    try {
      // These properties are most salient for Google Analytics
      // See how these map to GA properties here:
      // https://segment.com/docs/connections/destinations/catalog/google-analytics/#track
      analytics.track(action, properties, options)
    } catch (e) {
      Sentry.captureException(e)
    }

    try {
      // Sentry has no Logrocket integration, so we additionally want to send
      // custom events there so we can search for events in Logrocket sessions
      // See https://docs.logrocket.com/reference#track
      lr.track(action)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const reset = async () => {
    Sentry.configureScope(scope => scope.setUser(null))

    try {
      analytics.reset()
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  try {
    lr.getSessionURL(function(sessionURL) {
      // We're not using this method as intended, but
      // it's a convenient way to ensure the session URL
      // gets sent to Google Analytics correctly
      track("LogRocket Session URL", { label: sessionURL })
      Sentry.configureScope(scope => scope.setExtra("sessionURL", sessionURL))
    })
  } catch (e) {
    Sentry.captureException(e)
  }

  return {
    enabled: true,
    identify,
    page,
    reset,
    track,
  }
}

// https://github.com/prerender/prerender-node/blob/master/index.js
const crawlerUserAgents = [
  /googlebot/,
  /yahoo! slurp/,
  /bingbot/,
  /yandex/,
  /baiduspider/,
  /facebookexternalhit/,
  /twitterbot/,
  /rogerbot/,
  /linkedinbot/,
  /embedly/,
  /quora link preview/,
  /showyoubot/,
  /outbrain/,
  /pinterest\/0\./,
  /developers\.google\.com\/\+\/web\/snippet/,
  /slackbot/,
  /vkshare/,
  /w3c_validator/,
  /redditbot/,
  /applebot/,
  /whatsapp/,
  /flipboard/,
  /tumblr/,
  /bitlybot/,
  /skypeuripreview/,
  /nuzzel/,
  /discordbot/,
  /google page speed/,
  /qwantify/,
  /pinterestbot/,
  /bitrix link preview/,
  /xing-contenttabreceiver/,
  /chrome-lighthouse/,
  // add new ones below
  /\+https:\/\/github.com\/prerender\/prerender/,
  /ahrefssiteaudit\//,
  /bytespider/,
  /petalbot/,
]

function isBot(userAgent) {
  const userAgentLowercase = userAgent.toLowerCase()
  for (const ua of crawlerUserAgents) {
    if (ua.test(userAgentLowercase)) {
      return true
    }
  }
  return false
}

export function createAnalytics() {
  let enabled = false
  if (!import.meta.env.SSR && !isBot(window.navigator.userAgent)) {
    enabled = true
  }
  return _createAnalytics(enabled)
}
