import { API_BASE_URL } from "@/src/config"
import fetch from "cross-fetch"
import _get from "lodash-es/get"
import { Sentry } from "@sentry"
import { computed, inject, provide, ref, watch } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { executeQuery, useQuery } from "@plugins/urql"
import { VIEWER_QUERY } from "@graphql/queries"
import {
  clearAuthMetadata,
  getAuthMetadata,
  getPostAuth,
  handlePostAuth,
  postAuthSave,
} from "@/src/auth"
import { enableTrackingDeploys } from "@/src/conversions"
import { PIPELINE_DATA_VIZ_EDITOR_UID } from "@/src/config"

const ME = Symbol("ME")

// XXX me computed must be called in context of setup()
// because it uses useQuery which calls useClient which calls inject
// but also we need to waitForViewer in app.js before router.beforeEach
// (and generally upfront to hydrate correctly)
// ... so we just need to export the query that will be used so
// that it can be awaited and ready in cache
// ... if there is a cleaner way to just initialize me and then
// wait for something in me without having to keep these in sync...
// we should do it
const viewerQuery = {
  query: VIEWER_QUERY,
  variables: {
    withDailyInvocations: true,
    withOrgConnections: true,
  },
}

export function createViewerClosure({ getUrqlClient, resetUrqlClient }) {
  // we need reactive viewer in app.js for router.beforeEach guard
  // cannot provideMe in app.js because things must be done in setup()
  // and having the router guards inside of app.vue means router.isReady fires before
  const viewer = ref({}) // viewer should always be an object

  const waitForViewer = async ({ hybridStorage }) => {
    if (hybridStorage.returning || hybridStorage.username !== null) {
      // if viewer is null does not get cached in window.__urql__? client makes another call
      const result = await getUrqlClient()
        .query(viewerQuery.query, viewerQuery.variables)
        .toPromise()
      viewer.value = _get(result.data, "viewer") || {} // viewer should always be an object
    }
  }

  const provideMe = () => {
    const api = inject("api")
    const $analytics = inject("$analytics")
    const route = useRoute()
    const $router = useRouter()
    const hybridStorage = inject("hybridStorage")
    const store = useStore()

    // this is mostly for sessionChannel to know the changes were
    // triggered in this tab... we are handling it
    const authChanging = ref(false)

    const { orgId } = route.query
    if (orgId) {
      hybridStorage.contextId = orgId
      // XXX do redirect on server instead?
      if (!import.meta.env.SSR) {
        $router.replace({
          query: { ...route.query, orgId: undefined },
        })
      }
    }
    const contextId = ref(hybridStorage.contextId || null)

    const viewerResult = useQuery(viewerQuery)
    watch(
      () => viewerResult.data.value,
      () => {
        viewer.value = _get(viewerResult.data.value, "viewer") || {} // always be an object
      },
      { deep: true }
    )
    const anonymous = computed(() => !_get(viewer.value, "username"))
    const subscribed = computed(
      () => !!_get(viewer.value, "stripeSubscriptionActive")
    )

    const refreshViewer = async () => await executeQuery(viewerResult)

    watch(
      () => _get(viewer.value, "username"),
      v => {
        // XXX check if this handles godmoding ok?
        if (v) {
          // invesere of this happens explicitely on signOut
          hybridStorage.username = v
          $analytics.identify(viewer.value)
          Sentry.configureScope(scope => {
            scope.setUser({
              id: viewer.value.id,
              username: v,
            })
          })
        } else {
          $analytics.reset()
        }
      },
      {
        immediate: true,
      }
    )

    const userIdentity = computed(() => {
      return {
        name: _get(viewer.value, "username"),
        id: _get(viewer.value, "id"),
        email: _get(viewer.value, "email"),
        hasStripeSubscription: _get(viewer.value, "hasStripeSubscription"),
        dailyInvocationsUsed: _get(viewer.value, "dailyInvocationsUsed"),
        dailyInvocationsQuota: _get(viewer.value, "dailyInvocationsQuota"),
        lambdaTimeQuota: _get(viewer.value, "lambdaTimeQuota"),
        lambdaTimeUsed: _get(viewer.value, "lambdaTimeUsed"),
        stripeSubscriptionActive: _get(
          viewer.value,
          "stripeSubscriptionActive"
        ),
        orgId: null,
        isOrg: false,
      }
    })
    const orgs = computed(() => {
      return _get(viewer.value, "orgConnections.nodes") || []
    })
    const orgContexts = computed(() => {
      return orgs.value.map(org => ({
        ...org,
        isOrg: true,
        orgId: org.id,
      }))
    })
    const contextsById = computed(() => {
      const ret = {}
      for (const orgContext of orgContexts.value) {
        ret[orgContext.orgId] = orgContext
      }
      ret[userIdentity.value.id] = userIdentity.value
      return ret
    })
    const identity = computed(() => {
      let ret = null
      if (contextId.value) {
        ret = contextsById.value[contextId.value]
      }
      return ret || userIdentity.value
    })
    const setContextId = id => {
      contextId.value = id
      hybridStorage.contextId = id
    }

    const completeOnboardingStep = step => {
      if (anonymous.value) return
      return getUrqlClient().mutations.saveViewer({ step })
    }
    const onboardingStepCompleted = step => {
      const steps = viewer.value.completeOnboardingSteps || []
      return steps.map(_ => _.step).includes(step)
    }

    // better to use requireUser in routes.js, but --
    // - if already on bad route, replaceRoute
    // - if before going to bad route, !replaceRoute
    const requireUser = (postAuthData, replaceRoute = false) => {
      if (!anonymous.value) return true
      postAuthSave(postAuthData)
      $router[replaceRoute ? "replace" : "push"]({ name: "signup" })
      return false
    }

    // old src/auth.js makeAuthCall
    const signIn = async (providerName, data, opts = {}) => {
      // currently this will only be forwarded on new_user
      if (!data.metadata) data.metadata = {}
      Object.assign(data.metadata, getAuthMetadata())
      let res
      try {
        authChanging.value = true
        const url = `${API_BASE_URL}/auth/${providerName}`
        const resp = await fetch(url, {
          method: "post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
        if (!resp.ok) {
          // perfectly valid if user denied access to app from github
          // XXX check the querystring error=access_denied to need to sentry
          throw new Error(`/auth/${providerName} session create failed`)
        }
        res = { data: await resp.json() }
        clearAuthMetadata()
      } catch (e) {
        Sentry.captureException(e)
        authChanging.value = false
        return e.message
      }
      try {
        if (res.data.errors && res.data.errors.length > 0) {
          return res.data.errors[0] // XXX propagate more?
        }
        const cliSkey = res.data.cli_skey
        if (cliSkey) {
          window.location.href = `http://localhost:11337/?skey=${cliSkey}`
          return
        }
        // should always use this so we can await viewer update
        await refreshViewer()
        if (res.data.confirm_username) {
          // this is for adwords conversion tracking
          $analytics.track("account created")
          // this is for adwords conversion tracking too (XXX move?)
          enableTrackingDeploys()
          // if no postauth intent yet,
          // set to /new tutorial before making them confirm-username
          // XXX if they click away from confirm-username screen,
          // we should probably clear postauth
          const d = getPostAuth()
          if (!d.route) {
            postAuthSave({
              route: "/new?tutorial=1",
            })
          }
          $router[opts.replaceRoute ? "replace" : "push"]({
            name: "confirm-username",
          })
          return
        }
        handlePostAuth({
          $router,
          replaceRoute: opts.replaceRoute,
        })
      } catch (e) {
        Sentry.captureException(e)
      } finally {
        authChanging.value = false
      }
    }

    const signOut = async () => {
      try {
        authChanging.value = true
        if (!anonymous.value) {
          await api.signOut()
        }
        hybridStorage.username = null
        await store.dispatch("clearLocalState")
        resetUrqlClient()
        await refreshViewer()
        await $router.replace({ name: "landing" })
      } catch (e) {
        Sentry.captureException(e)
      } finally {
        authChanging.value = false
      }
    }

    const godmodeOrigUserId = ref(hybridStorage.godmode || null)
    watch(
      () => godmodeOrigUserId.value,
      () => {
        hybridStorage.godmode = godmodeOrigUserId.value
      }
    )

    const me = computed(() => {
      const viewerCopy = JSON.parse(JSON.stringify(viewer.value))
      viewerCopy.hasSubscription = viewerCopy.hasStripeSubscription
      delete viewerCopy.hasStripeSubscription
      if (viewer.value.id === PIPELINE_DATA_VIZ_EDITOR_UID) {
        viewerCopy.canChangePipelineDataVizState = true
      }
      return {
        anonymous: anonymous.value,
        contexts: orgContexts.value,
        contextsById: contextsById.value,
        godmode: {
          impersonate: async userId => {
            try {
              authChanging.value = true
              await api.godmode(userId)
              setContextId(userId || godmodeOrigUserId.value)
              godmodeOrigUserId.value = userId ? viewer.value.id : null
              await refreshViewer()
              if (!userId) await $router.push("/admin")
            } catch (e) {
              Sentry.captureException(e)
            } finally {
              authChanging.value = false
            }
          },
          origUserId: godmodeOrigUserId.value,
          setOrigUserId: userId => {
            godmodeOrigUserId.value = userId || null
          },
        },
        identity: identity.value,
        userIdentity: userIdentity.value,
        refresh: refreshViewer,
        signIn,
        signOut,
        authChanging: authChanging.value,
        subscribed: subscribed.value,
        completeOnboardingStep,
        onboardingStepCompleted,
        requireUser,
        setContextId,
        ...viewerCopy,
      }
    })

    provide(ME, me)

    return me
  }

  return {
    viewer,
    waitForViewer,
    provideMe,
  }
}

export function useMe() {
  const me = inject(ME)
  if (!me) throw new Error("must provideMe")
  return me
}
