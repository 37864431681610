import * as fragments from "@graphql/fragments"
import _get from "lodash-es/get"

const mutation = `
  mutation pipeCreate(
    $emitterId: String!
    $enabled: Boolean
    $eventName: String
    $listenerId: String!
    $orgId: String
  ) {
    my(orgId: $orgId) {
      pipeCreate(
        emitterId: $emitterId
        listenerId: $listenerId
        enabled: $enabled
        eventName: $eventName
      ) {
        pipe {
          ...WorkflowPipeParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.pipe}
  ${fragments.workflow.deployedComponent}
  ${fragments.workflow.httpInterface}
  ${fragments.workflow.savedComponent}
  ${fragments.workflow.timerInterface}
`

export default {
  name: "my.pipeCreate",
  async mutate(
    emitterId,
    listenerId,
    enabled = true,
    eventName,
    { orgId } = {}
  ) {
    const results = await this.mutation(mutation, {
      emitterId,
      listenerId,
      enabled,
      eventName,
      orgId,
    }).toPromise()
    const { errors, pipe } = results.data.my.pipeCreate
    if (errors && errors.length) throw errors
    if (!pipe) throw "Expected pipe!"
    return pipe
  },
  update(result, _args, cache /* , info */) {
    // const args = info.variables
    if (_get(result, "my.pipeCreate.pipe")) {
      const id = _get(result, "my.pipeCreate.pipe.listener.id", "")
      if (id.startsWith("p_")) {
        const triggers = cache.resolve(
          { __typename: "Pipeline", id },
          "triggers"
        )
        if (triggers) {
          triggers.push(result.my.pipeCreate.pipe)
          cache.link({ __typename: "Pipeline", id }, "triggers", triggers)
        }
      }
    }
  },
}
