import { gql } from "@urql/core"
import { authProvision, authProvisionWiths } from "@graphql/fragments"

export default gql`
  query myAuthProvisions(
    $after: String
    $q: String
    $exceptIds: [String!]
    $orderBy: [AuthProvisionOrderByEnum!]
    $orgId: String
    ${authProvisionWiths.fragmentPart}
  ) {
    my(orgId: $orgId) {
      id
      authProvisions(
        first: 20
        after: $after
        q: $q
        exceptIds: $exceptIds
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...AuthProvisionParts
        }
      }
    }
  }
  ${authProvision}
`
