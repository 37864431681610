export function componentConfigurable(component, dynamicProps) {
  if (!component) return false
  const configurableProps =
    (dynamicProps && dynamicProps.configurableProps) ||
    component.configurableProps

  for (const configurableProp of configurableProps) {
    if (configurablePropConfigurable(configurableProp)) return true
  }
  return false
}

export function shouldShowConfigurableProp(configurableProp) {
  if ("static" in configurableProp) return false
  if (configurableProp.type === "$.service.db") return false
  if (configurableProp.type === "$.interface.http") return false
  if (configurableProp.type === "$.interface.apphook") return false
  return true
}

export function configurablePropKey(cp) {
  return cp.name
}

export function configurablePropConfigurable(configurableProp) {
  if (!shouldShowConfigurableProp(configurableProp)) return false
  return true
}

export function componentNeedsConfiguring(
  component,
  configuredProps,
  dynamicProps
) {
  if (!component) return false
  const configurableProps =
    (dynamicProps && dynamicProps.configurableProps) ||
    component.configurableProps

  for (const configurableProp of configurableProps) {
    if (configurablePropNeedsConfiguring(configurableProp, configuredProps)) {
      return true
    }
  }
  return false
}

export function configurablePropNeedsConfiguring(
  configurableProp,
  configuredProps
) {
  if (configurableProp.optional) return false
  if ("default" in configurableProp) return false
  if (!configurablePropConfigurable(configurableProp)) return false
  const v = configuredProps[configurableProp.name]
  if (v === false) return false
  if (!v) return true
  if (configurableProp.type === "$.interface.timer") {
    if (!v.intervalSeconds && !v.cron) return true
  }
  if (configurableProp.type === "app") {
    if (typeof v.authProvisionId === "undefined") return true
  }
  return false
}

export function configurablePropRemoteOptions(cp) {
  if (cp.remoteOptions) return true
  if (cp.type.startsWith("$.airtable.")) return true
  if (cp.type.startsWith("$.discord.channel")) return true
  return false
}
