import _get from "lodash-es/get"
import _pick from "lodash-es/pick"
import { action as actionFragment } from "@graphql/fragments"
import { actionRevive } from "@graphql/types/action"

export function invalidateActionQueries(cache) {
  cache
    .inspectFields("Query")
    .filter(f => ["admin", "my", "viewer"].includes(f.fieldName))
    .forEach(c => {
      const context = cache.resolve("Query", c.fieldKey)
      cache
        .inspectFields(context)
        .filter(f =>
          ["actions", "actionConnections", "actionVersions"].includes(
            f.fieldName
          )
        )
        .forEach(f => {
          cache.invalidate(context, f.fieldKey)
        })
    })
  cache
    .inspectFields("Query")
    .filter(f =>
      ["actions", "actionConnections", "actionVersions"].includes(f.fieldName)
    )
    .forEach(f => cache.invalidate("Query", f.fieldKey))
}

export default {
  async mutate(id, actionData) {
    const actionInput = _pick(actionData, [
      "appConnections",
      "codeRaw",
      "defaultNamespace",
      "description",
      "hidden",
      "title",
    ])
    for (const idx in actionInput.appConnections) {
      actionInput.appConnections[idx] = _pick(actionInput.appConnections[idx], [
        "appId",
        "nameSlug",
      ])
    }
    if (actionData._codeConfig) {
      actionInput.codeConfigJson = JSON.stringify(actionData._codeConfig)
    }
    // TODO(apollo) cache update
    const resp = await this.mutation(
      `
      mutation saveAction($id: String, $action: ActionInput!) {
        saveAction(id: $id, action: $action) {
          action {
            ...ActionParts
          }
          errors
        }
      }
      ${actionFragment}
    `,
      {
        id,
        action: actionInput,
      }
    ).toPromise()
    const { action, errors } = resp.data.saveAction
    if (errors && errors.length) throw errors
    actionRevive(action)
    return action
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "saveAction.errors.length")) {
      const id = _get(result, "saveAction.action.id")
      if (id) {
        invalidateActionQueries(cache)
      }
    }
  },
}
