import { getCurrentInstance } from "vue"
import createMyApp from "./app.js"
import { VueClipboard, toClipboard } from "@soerenmartius/vue3-clipboard"
import { oauthCallbackInProgress, storeAuthMetadata } from "@/src/auth"
import Track, { getTrackProperties } from "@/src/directives/track"
import { Focus, FocusDirective, setFocus } from "@/src/directives/focus"
import { initProgress } from "@plugins/nprogress"
import { createClientSentry } from "@plugins/sentry.client"
import Visible from "@/src/directives/visible"
import ActionCableManager from "@/src/utils/actionCableManager"
import { API_BASE_URL } from "@/src/config"

// disable stupid auto highglighting
window.Prism = window.Prism || {}
window.Prism.manual = true
;(async function() {
  const { app, router, store } = await createMyApp()
  const vuePrototype = app.config.globalProperties

  createClientSentry({ app, router })
  app.use(VueClipboard)
  vuePrototype.$copyText = toClipboard
  app.directive("Track", Track)

  const wsApiBaseUrl = API_BASE_URL.replace(/^http/, "ws")
  const connectionUrl = `${wsApiBaseUrl}/websocket`
  vuePrototype.$cable = new ActionCableManager(connectionUrl)

  // XXX make sure non of these are needed for ssr
  app.directive("Visible", Visible)
  Focus.$store = store
  app.directive("Focus", FocusDirective)
  vuePrototype.$focus = setFocus

  vuePrototype.$track = function(action, _properties = {}) {
    let vm = this
    if (!vm) vm = getCurrentInstance() // in case we are in setup()
    // get properties from parent nodes
    const properties = getTrackProperties(vm.$el)
    Object.assign(properties, _properties)
    vuePrototype.$analytics.track(action, properties)
  }
  app.provide("$track", vuePrototype.$track)

  // don't do this when redirecting back from sign-x oauth flow
  if (!oauthCallbackInProgress()) {
    const referrer = document.referrer
    const landingPage = window.location.href
    storeAuthMetadata({ referrer: referrer, landing_page: landingPage })
    vuePrototype.$analytics.track("Initial App Load")
    if (vuePrototype.$analytics.enabled) {
      vuePrototype.$api.analyticsInitialAppLoad(referrer, landingPage)
    }
  }
  initProgress(router)
  router.afterEach(to => {
    // delay just so v-track.click fires first
    setTimeout(() => vuePrototype.$analytics.page(to), 50)
  })

  await router.isReady()

  // https://github.com/vuejs/vue-devtools/issues/1376#issuecomment-794544045
  // XXX remove after https://github.com/vuejs/vue-next/commit/2b52d5d7c53f7843f4a1e85fd7f1720dc2847ebc
  const instance = app.mount("#app")
  app._container._vnode = instance.$.vnode
})()
