import { gql } from "@urql/core"
import { deployedComponent, deployedComponentWiths } from "@graphql/fragments"

export default gql`
  query deployedComponent(
    $id: String!
    ${deployedComponentWiths.fragmentPart}
  ) {
    deployedComponent(id: $id) {
      ...DeployedComponentParts
    }
  }
  ${deployedComponent}
`
