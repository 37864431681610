import _get from "lodash-es/get"
export function invalidateSavedComponentQueries(cache) {
  cache
    .inspectFields("Query")
    .filter(f => ["admin", "my", "viewer"].includes(f.fieldName))
    .forEach(c => {
      const context = cache.resolve("Query", c.fieldKey)
      cache
        .inspectFields(context)
        .filter(f =>
          [
            "publishedComponents",
            "savedComponents",
            "savedComponentConnections",
          ].includes(f.fieldName)
        )
        .forEach(f => {
          cache.invalidate(context, f.fieldKey)
        })
    })
  cache
    .inspectFields("Query")
    .filter(f =>
      [
        "components",
        "publishedComponents",
        "registryComponents",
        "registryComponentApps",
        "savedComponents",
        "savedComponentConnections",
      ].includes(f.fieldName)
    )
    .forEach(f => cache.invalidate("Query", f.fieldKey))
}
export default {
  name: "saveComponentCode",
  async mutate(vars = {}) {
    const variables = { ...vars, codeHash: null }
    const resp = await this.mutation(
      `
      mutation saveComponent(
        $code: String!
        $orgId: String
        $codeHash: String
        $loc: String
        $zipBuffer64: String
        $entryPath: String
      ) {
        saveComponentCode(
          code: $code
          orgId: $orgId
          codeHash: $codeHash
          loc: $loc
          zipBuffer64: $zipBuffer64
          entryPath: $entryPath
        ) {
          errors
          savedComponent {
            id
            code
            codeHash
            name
            version
            configurablePropsJson
            zipBuffer64
            entryPath
          }
        }
      }
    `,
      variables
    ).toPromise()
    const data = resp.data.saveComponentCode
    const { savedComponent, errors } = data
    if (errors && errors.length) throw errors
    return savedComponent
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "saveComponentCode.errors.length")) {
      const id = _get(result, "saveComponentCode.savedComponent.id")
      if (id) {
        invalidateSavedComponentQueries(cache)
      }
    }
  },
}
