import { gql } from "@urql/core"
import { authProvision, authProvisionWiths } from "@graphql/fragments"

export default gql`
  query viewerAuthProvisions(
    ${authProvisionWiths.fragmentPart}
  ) {
    viewer {
      id
      authProvisions {
        ...AuthProvisionParts
      }
    }
  }
  ${authProvision}
`
