import { gql } from "@urql/core"

// XXX want these to be in their folders as index.js but wasn't resolving? might have been webpack cache issue...
// (event tried resolve.mainFiles even though index should be default)
import * as fragments from "./fragments"
import { cacheExchangeOptions, mutations } from "./mutations"
import * as queries from "./queries"

function getPipelineMutation(pipelineId) {
  return `
  ${pipelineId}: updatePipeline(id: "${pipelineId}", pipeline: $pipeline) {
    pipeline {
      id
      inactive
      archived
      updatedAt
    }
  }
`
}

function getBulkPipelineMutation(mutations) {
  return gql`
  mutation($pipeline: PipelineInput!) {
    ${mutations}
  }
`
}

export {
  cacheExchangeOptions,
  fragments,
  mutations,
  queries,
  // TODO deal with the next two...
  getBulkPipelineMutation,
  getPipelineMutation,
}
