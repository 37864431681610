import { gql } from "@urql/core"

export default async function orgUpdate(id, data) {
  const resp = await this.mutation(
    gql`
      mutation orgUpdate($id: String!, $name: String, $desiredEmail: String) {
        orgUpdate(id: $id, name: $name, desiredEmail: $desiredEmail) {
          org {
            id
            name
            nickname
            email
            desiredEmail
          }
          errors
        }
      }
    `,
    {
      id,
      name: data.name,
      desiredEmail: data.desiredEmail,
    }
  ).toPromise()
  // XXX add update?
  const { org, errors } = resp.data.orgUpdate
  if (errors && errors.length) throw errors
  return org
}
