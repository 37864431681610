import * as fragments from "@graphql/fragments"

const mutation = `
  mutation pipeUpdate(
    $id: String!
    $emitterId: String
    $enabled: Boolean
    $eventName: String
    $listenerId: String
    $orgId: String
  ) {
    my(orgId: $orgId) {
      pipeUpdate(
        id: $id
        emitterId: $emitterId
        listenerId: $listenerId
        enabled: $enabled
        eventName: $eventName
      ) {
        pipe {
          ...WorkflowPipeParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.deployedComponent}
  ${fragments.workflow.httpInterface}
  ${fragments.workflow.pipe}
  ${fragments.workflow.savedComponent}
  ${fragments.workflow.timerInterface}
`
export default async function pipeUpdate(
  id,
  emitterId,
  listenerId,
  enabled = true,
  eventName,
  { orgId } = {}
) {
  const results = await this.mutation(mutation, {
    id,
    emitterId,
    listenerId,
    enabled,
    eventName,
    orgId,
  }).toPromise()
  const { errors, pipe } = results.data.my.pipeUpdate
  if (errors && errors.length) throw errors
  if (!pipe) throw "Expected pipe!"
  return pipe
}
