//import "intersection-observer"
import { createApp, createSSRApp, nextTick } from "vue"

import createApi from "@/src/api.js"
import App from "@/src/app.vue"
import createMyRouter from "@/src/router"
import createMyStore from "@/src/store"
import { createFeature } from "@/src/features"
import * as experiments from "@/src/experiments"
import * as pdsdk from "@plugins/pdsdk"
import { createUrqlClient } from "@plugins/urql"
import useRouterLinkPrefetch from "./routerLinkPrefetch"
import { createViewerClosure } from "@composables/me"
import { createHead } from "@composables/head"
import { createNetworkStatus } from "@composables/network-status"
import { createHybridStorage } from "@/src/utils/hybridStorage" // XXX move to composables?
import { createSettings } from "@/src/utils/settings"
import { createShortcuts } from "@/src/utils/shortcuts"
import VTooltip from "v-tooltip"
import {
  default as DocContext,
  setCurrentContextHere,
  docRegisterStore,
} from "@/src/directives/doc"
import { createAnalytics } from "@plugins/analytics"

import "@/src/assets/styles/tailwind.css"
import "@/src/assets/styles/tooltip.css"
import "@/src/assets/styles/markdown.css"
import "@/src/assets/styles/pd.css" // actually has markdown syntax highlighting css

const isServer = import.meta.env.SSR
const isClient = !isServer

// because beforeRouteEnter api is so stupid
const routeInjectVuePrototype = (route, vuePrototype) => {
  route.vuePrototype = vuePrototype
  for (const child of route.children || []) {
    routeInjectVuePrototype(child, vuePrototype)
  }
}

// XXX maybe inject all with $ prefix (to match this.$...)
export default async function createMyApp(opts = {}, rootComponent = App) {
  const { cookieHeader, agents } = opts

  let _createApp = createSSRApp
  if (!import.meta.env.SSR) {
    // check just in case ?disableserver=1
    const appEl = document.querySelector("#app")
    if (!appEl.childNodes.length) {
      _createApp = createApp
    }
  }

  const app = _createApp(rootComponent)
  const vuePrototype = app.config.globalProperties

  // assumes only used on components that are only active once at a time
  // ... so just use component name as key (which we can't get automatically unfortunately)
  // ... tried using getCurrentInstance() but doesn't work on client in beforeRouteEnter
  // ... also ideally would use next(vm => vm.state =...) but that doesn't run on server before SSR
  vuePrototype.$beforeRouteEnterState = {}

  const hybridStorage = createHybridStorage(cookieHeader)
  app.provide("hybridStorage", hybridStorage)
  vuePrototype.$hybridStorage = hybridStorage

  vuePrototype.$settings = createSettings({ hybridStorage })

  vuePrototype.$shortcuts = createShortcuts()

  const $analytics = createAnalytics()
  app.provide("$analytics", $analytics)
  vuePrototype.$analytics = $analytics

  const $feature = createFeature(hybridStorage)
  vuePrototype.$feature = $feature
  app.provide("$feature", $feature)

  createApi({ app, agents, cookieHeader, hybridStorage, $feature })

  // XXX https://github.com/posva/vite-tailwind-starter/blob/master/src/main.js
  const router = createMyRouter()
  app.use(router)
  if (isClient) useRouterLinkPrefetch(app)

  createNetworkStatus({ app, router })

  const head = createHead()
  head.install(app)

  const store = createMyStore({ app })

  vuePrototype.$pdsdk = pdsdk

  vuePrototype.$experiments = experiments.init($analytics)

  // https://www.amuponda.com/2019/01/28/handling-404-errors-with-vue-router/
  const notFound = (vuePrototype.$notFound = route => {
    const pathChunks = route.path.split("/")
    pathChunks.shift()
    return {
      name: "not-found",
      params: {
        pathMatch: pathChunks,
      },
      query: route.query,
    }
  })
  vuePrototype.$gotoNotFound = async () => {
    await router.push(notFound(router.currentRoute.value))
  }

  app.use(VTooltip) // XXX maybe leave in entry-client but **need to stub v-popover on server side**!!!

  docRegisterStore(store)
  vuePrototype.$doc = {
    async setCurrentContextHere(el) {
      await nextTick()
      setCurrentContextHere(el)
    },
  }
  app.directive("DocContext", DocContext)

  // this is so beforeRouteEnter can access things on prototype (ie. this.$urql)
  for (const route of router.getRoutes()) {
    routeInjectVuePrototype(route, vuePrototype)
  }

  let urql
  const getUrqlClient = () => urql
  const resetUrqlClient = async () => {
    urql = await createUrqlClient({ agents, cookieHeader, hybridStorage })
    app.provide("$urql", urql)
    vuePrototype.$urql = urql
  }
  await resetUrqlClient()

  const { viewer, waitForViewer, provideMe } = createViewerClosure({
    getUrqlClient,
    resetUrqlClient,
  })
  vuePrototype.$provideMe = provideMe // must happen in app.vue because some injects need setup

  // resolve viewer right away so components like onboarding start properly hydrated
  // if maybe signed in (ie. has username stored) then we should also await upfront
  // so proper nav type can be shown on non requireUser route)
  await waitForViewer({ hybridStorage })

  router.beforeEach(async (to, _from, next) => {
    // XXX doesn't matter? (since bots dont navigate client-side)
    // head.resetRobots()
    if (to.path === "/") {
      if (viewer.value.id) {
        return next({ name: "workflows", query: to.query })
      }
    }
    for (let i = to.matched.length - 1; i >= 0; i--) {
      const route = to.matched[i]
      if (!route.meta) continue
      let { requireUser } = route.meta
      if (requireUser !== undefined) {
        if (typeof requireUser === "function") requireUser = requireUser(to)
        if (requireUser && !viewer.value.id) {
          // XXX would be good to say "SIGN IN REQUIRED" on the page
          // if user has already logged in before -- go to login
          const routeName = hybridStorage.returning ? "login" : "signup"
          return next({ name: routeName, query: { r: to.fullPath } })
        }
        if (requireUser === "admin" && !viewer.value.admin) {
          return next(notFound(to))
        }
        if (requireUser === null && viewer.value.id) {
          if (route.path.startsWith("/auth/")) {
            return next(to.query.r || "/")
          }
          // could route error but only /auth/login so just go to /
          return next("/")
        }
      }
    }
    next()
  })

  router.afterEach(to => {
    let title, description
    for (const { meta } of to.matched) {
      if (!meta) continue
      if (typeof meta.title !== "undefined") title = meta.title
      if (typeof meta.description !== "undefined")
        description = meta.description
    }
    if (title && typeof title === "function") title = title(to)
    head.setTitle(title)
    if (description && typeof description === "function") {
      description = description(to)
    }
    head.setDescription(description)
  })

  return {
    app,
    router,
    store,
    urqlSsrExchange: urql._ssrExchange,
    head,
  }
}
