let io

function warn(msg) {
  if (import.meta.env.PROD) return
  console.warn(`[debug:v-track] ${msg}`)
}

export function getTrackProperties(el) {
  if (!el) return {} // when ConnectAccountNew emits null because of !app.authType, triggers track before parents are mounted maybe?
  const tree = []
  do {
    tree.unshift(el)
    el = el.parentNode
  } while (el)
  const ret = {}
  for (const node of tree) {
    Object.assign(ret, node._trackProperties)
  }
  return ret
}

function beforeMount(el, binding, vnode) {
  if (binding.arg === "properties") {
    el._trackProperties = binding.value
    return
  }
  if (el._track) {
    unmounted(el)
  }
  if (!Object.keys(binding.modifiers).length) {
    warn("v-track must use modifiers. at least one of: click, visible")
    return
  }
  el._track = {}
  el._track.action = binding.value
  el._track.vnode = vnode
  // XXX might want this only has a hookable if visible
  // ... this is used if someone wants to target an element via appcues for example
  el.dataset.pdT = binding.value
  if (binding.modifiers.visible) {
    el._track.visible = false
    el._track.onVisibleChange = visible => {
      if (el._track.visible === visible) {
        return
      }
      el._track.visible = visible
      // XXX find another way to solve this but for the track call in unbind, el is removed from dom
      // so we are not able to recurse up tree to get track properties at that time, so just use cached
      el._cachedTrackProperties = getTrackProperties(el)
      binding.instance.$analytics.track(
        `${el._track.action} - ${visible ? "visible" : "hidden"}`,
        el._cachedTrackProperties
      )
    }
  }
  if (binding.modifiers.click) {
    el._track.onClick = e => {
      // if we have nested elements, because we use capture (to fix some @click [.native] cases), parent will fire first
      // ... but we only want child to actually send track event
      // so we setTimeout on the event and only the last one will do the track
      if (e._timeout) clearTimeout(e._timeout)
      // evaluate these before the timeout otherwise bindings will be wrong
      const action = `${el._track.action} - clicked`
      const properties = getTrackProperties(el)
      e._timeout = setTimeout(() =>
        binding.instance.$analytics.track(action, properties)
      )
    }
    el._track.onClickOptions = { capture: true, passive: true } // capture so we go first! (icon-button, router-link)
    el.addEventListener("click", el._track.onClick, el._track.onClickOptions)
  }
}

function unmounted(el, binding) {
  if (el._track) {
    delete el.dataset.pdT
    if (el._track.onVisibleChange) {
      io.unobserve(el)
      if (el._track.visible) {
        binding.instance.$analytics.track(
          `${el._track.action} - hidden`,
          el._cachedTrackProperties || getTrackProperties(el)
        )
      }
    }
    if (el._track.onClick) {
      el.removeEventListener(
        "click",
        el._track.onClick,
        el._track.onClickOptions
      )
    }
    delete el._track
  }
  if (el._trackProperties) {
    delete el._trackProperties
  }
}

// v-track(.click|.visible)
// v-track:properties (will recurse up tree for these -- XXX does not work on template "nodes"!)
export default {
  name: "Track",
  beforeMount,
  mounted(el, binding) {
    if (!io) {
      io = new IntersectionObserver(entries => {
        for (const entry of entries) {
          const el = entry.target
          if (!el._track || !el._track.onVisibleChange) continue
          el._track.onVisibleChange(entry.isIntersecting)
        }
      })
    }
    if (binding.modifiers.visible) {
      io.observe(el)
    }
  },
  updated(el, binding, vnode) {
    if (binding.arg === "properties") {
      el._trackProperties = binding.value
      return
    }
    el._track.action = binding.value
    el._track.vnode = vnode
  },
  unmounted,
}
