import { gql } from "@urql/core"

export default gql`
  query myRedactedEnvironmentVariables($orgId: String) {
    my(orgId: $orgId) {
      id
      redactedEnvironmentVariables {
        id
        name
        updatedAt
      }
    }
  }
`
