<template lang="pug">
.font-sans.text-base.leading-normal.h-full(v-doc-context.root.listen="")
  #root.h-full
    RouterView(v-slot="{ Component }")
      template(v-if="Component")
        Suspense
          component(:is="Component")
  #drop-down-container
  #modal-container
  #modal-drop-down-container
</template>

<script>
import { getCurrentInstance } from "vue"
import { sendClickToPipedream } from "@plugins/pipedream-analytics/sendClickToPipedream"

export default {
  name: "App",
  setup() {
    // because of createViewerClosure (see app.js) we need to get enclosed provideMe
    const app = getCurrentInstance().appContext.app
    const vuePrototype = app.config.globalProperties
    return {
      me: vuePrototype.$provideMe(),
      raf: null,
    }
  },
  beforeMount() {
    // XXX can probably rewrite a lot of below without store
    document.addEventListener("click", this.onClick, { passive: true })
    const store = this.$store
    this.announcementsChannel = this.$cable.subscribe(
      "AnnouncementsChannel",
      data => store.commit("SET_ANNOUNCEMENT", data)
    )

    this.clientUpdatesChannel = this.$cable.subscribe(
      "ClientUpdatesChannel",
      () => store.commit("CLIENT_HAS_UPDATES")
    )

    this.sessionChannel = this.$cable.subscribe(
      "SessionChannel",
      async data => {
        if (this.me.authChanging) return
        window.cancelAnimationFrame(this.raf)
        this.raf = window.requestAnimationFrame(async () => {
          // check if the local data matches expected state
          const { userId, godmodeAdminUserId } = data
          const wasGodmoded = !!this.me.godmode.origUserId
          this.me.godmode.setOrigUserId(godmodeAdminUserId)
          if (userId !== this.me.id) {
            if (!userId) {
              this.$router.push({ name: "logout" })
            } else {
              // either god mode or signing in
              await this.me.refresh()
              if (this.me.admin && wasGodmoded) {
                this.$router.push("/admin")
              }
            }
          }
        })
      }
    )
  },
  beforeUnmount() {
    document.removeEventListener("click", this.onClick)
    if (this.raf) window.cancelAnimationFrame(this.raf)
    if (this.announcementsChannel) this.announcementsChannel.unsubscribe()
    if (this.sessionChannel) this.sessionChannel.unsubscribe()
    if (this.clientUpdatesChannel) this.clientUpdatesChannel.unsubscribe()
  },
  methods: {
    onClick: function(ev) {
      sendClickToPipedream(ev)
    },
  },
}
</script>

<style>
html,
body {
  @apply h-full;
  @apply font-sans;
  @apply font-normal;
  @apply text-black;
  @apply bg-white;
  font-size: 16px; /* establishes default rem */
  overscroll-behavior: none;
}
::selection {
  @apply bg-bluegrey-light !important;
}

/* Font Features -- XXX update comment or delete post inter removal */
/* See https://rsms.me/inter/ for details */
.font-sans {
  font-feature-settings: "ss01" 1;
}
/* enable the mono-space variant of Inter */
.font-sans-mono {
  @apply font-sans;
  font-feature-settings: "cv08" 1, "tnum" 1, "zero" 1;
}

code.font-code,
pre.font-code,
.font-code pre,
.font-code code {
  font-feature-settings: "cv08", "tnum" 1, "zero" !important;
}

/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
  outline: none;
}

/* Customize .focus-visible - disabled for now.
   TODO accessibility */
*:focus,
.focus-visible {
  outline: none !important;
}

.placeholder {
  opacity: 0.9;
}
:placeholder-shown {
  opacity: 0.9;
}
::placeholder {
  opacity: 0.9;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.9;
}
::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.9;
}
:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.9;
}
:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.9;
}
button.focus-visible {
  outline: none !important;
}
.loading-gradient {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
body > .monaco-aria-container {
  @apply fixed bottom-0;
  z-index: -9999;
}

/* https://www.cssmojo.com/aspect-ratio-using-custom-properties-and-calc/ */
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

/* Hack to workaround jit issue */
.group:hover .group-hover_visible {
  @apply visible;
}
</style>
