import _get from "lodash-es/get"
import fetch from "cross-fetch"
import { API_BASE_URL, EVENT_API_BASE_URL, RT_BASE_URL } from "@/src/config"
import { Deployment, Many, Pipeline, Source } from "@/src/models"
import {
  BufferedRetryEventSource,
  RetryEventSource,
} from "@/src/utils/event-source"
import { LRU } from "@/src/utils/lru"
import { Sentry } from "@sentry"

const toQueryString = params =>
  Object.keys(params || {})
    .map(key => {
      if (typeof params[key] === "undefined") return ""
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    })
    .join("&")

class SSEApi {
  constructor(baseURL, opts = {}) {
    const { maxClients = 2, buffer = false } = opts
    this.baseURL = baseURL
    this.buffer = buffer
    this.clients = new LRU(maxClients, null, this.onEvictClient)
    this.disabled = false
  }
  disable() {
    this.disabled = true
    this.unwatchAll()
  }
  enable() {
    this.disabled = false
  }
  onEvictClient(path, client) {
    client && client.close()
  }
  unwatchAll() {
    this.clients.clear()
  }
  unwatch(path) {
    this.clients.delete(path)
  }
  async watch(path, options = {}) {
    if (this.disabled) throw new Error("SSEApi disabled")
    let client
    if (this.buffer) {
      client = new BufferedRetryEventSource(
        `${this.baseURL}${path}?client=ui`,
        options
      )
    } else {
      client = new RetryEventSource(`${this.baseURL}${path}?client=ui`, options)
    }
    this.clients.set(path, client)
    return client
  }
}

// mimics axios a bit but translates to fetch... no problems with cross-fetch
// ... but axios even @bundled-es-modules/axios/axios.js was not working
class FetchWrapper {
  constructor(baseURL, baseConfig = {}) {
    this.baseURL = baseURL
    this.baseConfig = baseConfig
    this.enable()
    ;["get", "delete", "post", "put"].forEach(method => {
      this[method] = (url, config) => this.request(url, { ...config, method })
    })
  }
  async request(url, config = {}) {
    if (this.disabled && !config.force) return
    const mergedConfig = Object.assign({}, this.baseConfig, config) // XXX ensure this is deep
    const queryString = toQueryString(mergedConfig.query)
    let mergedUrl = this.baseURL + url
    if (queryString) mergedUrl += `?${queryString}`
    const options = {
      method: mergedConfig.method,
      headers: mergedConfig.headers || {},
      redirect: "manual",
      signal: config.force ? undefined : this.abortController.signal,
    }
    if (!import.meta.env.SSR) {
      options.credentials = "include"
    }
    if (mergedConfig.agents) {
      if (mergedUrl.startsWith("https:")) {
        options.agent = mergedConfig.agents.https
      } else {
        options.agent = mergedConfig.agents.http
      }
    }
    if (mergedConfig.hybridStorage) {
      if (!mergedConfig.hybridStorage.username) {
        const anonymousId = mergedConfig.hybridStorage.anonymousId
        if (anonymousId) {
          options.headers["x-anonymous-id"] = anonymousId
        }
      }
    }
    // XXX remove this... just for custom_lambdamaker feature basically...
    if (mergedConfig.$feature) {
      const { flags } = mergedConfig.$feature
      const truthyFlags = Object.keys(flags).reduce((acc, k) => {
        if (flags[k]) {
          acc[k] = flags[k]
        }
        return acc
      }, {})
      if (Object.keys(truthyFlags).length) {
        options.headers.features = JSON.stringify(truthyFlags)
      }
    }
    const data = "body" in mergedConfig ? mergedConfig.body : mergedConfig.data
    if (typeof data === "object") {
      if (mergedConfig.provides && mergedConfig.provides.prepare) {
        mergedConfig.provides.prepare(data)
      }
      if (data.constructor.name === "FormData") {
        options.body = data
      } else {
        options.body = JSON.stringify(data)
        options.headers["Content-Type"] = "application/json"
      }
    } else if (typeof data === "string") {
      options.body = data
    } else {
      // XXX warn about unexpected data
    }
    // console.log("[api.js:request]", mergedUrl, JSON.stringify(options))
    const response = await fetch(mergedUrl, options)
    if (!response.ok) {
      // XXX add request + response to sentry scope
      throw new Error(response.statusText || "bad api response")
    }
    let respData = {}
    try {
      respData = await response.json()
    } catch (err) {
      // do nothing... XXX probably do this better though >_<
    }
    if (mergedConfig.expects && mergedConfig.expects.revive) {
      mergedConfig.expects.revive(respData)
    }
    // console.log(">> [api.js:response]", JSON.stringify(respData))
    return { data: respData } // XXX other stuff too?
  }
  enable() {
    this.disabled = false
    if (import.meta.env.SSR) {
      this.abortController = {
        abort: () => {},
      }
    } else {
      this.abortController = new AbortController()
    }
  }
  disable() {
    this.disabled = true
    this.abortController.abort()
  }
}

class ApiV1 {
  constructor(_config = {}) {
    const config = { ..._config }
    if (!config.query) config.query = {}
    config.query.client = "ui"
    this._api = new FetchWrapper(API_BASE_URL, config)
    this._eventApi = new SSEApi(EVENT_API_BASE_URL, { buffer: true })
  }
  enable() {
    this._api.enable()
    this._eventApi.enable()
  }
  disable() {
    this._api.disable()
    this._eventApi.disable()
  }
  async getComponent(id, opts = {}) {
    let url = `/v1/components/${id}`
    if (opts.includeRegistry) url += "?registry=1"
    return _get(await this._api.get(url), "data.data")
  }
  async createComponent(data) {
    return _get(await this._api.post(`/v1/components`, { data }), "data.data")
  }
  async deleteEvents(id, query = {}) {
    return _get(
      await this._api.delete(`/v1/sources/${id}/events`, {
        query,
      })
    )
  }
  async events(sourceId) {
    // @deprecated
    return _get(
      await this._api.get(`/v1/sources/${sourceId}/events`),
      "data.data",
      []
    )
  }
  async eventSummaries(sourceId, eventName = "", query = {}) {
    if (!sourceId) throw new Error("eventSummaries: sourceId required")
    const partial = eventName ? `${sourceId}/${eventName}` : sourceId
    return await this._api.get(`/v1/sources/${partial}/event_summaries`, {
      query,
    })
  }
  async eventDetail(sourceId, eventId, eventName = "", query = {}) {
    const partial = eventName ? `${sourceId}/${eventName}` : sourceId
    return _get(
      await this._api.get(`/v1/sources/${partial}/event_detail/${eventId}`, {
        query,
      }),
      "data.data",
      []
    )
  }
  async nextEvent(sourceId, eventName = "", query = {}) {
    const { timeout = 0, ..._query } = query
    const partial = eventName ? `${sourceId}/${eventName}` : sourceId
    let client = await this._eventApi.watch(`/sources/${partial}/sse`, {
      query: _query,
    })
    let _reject
    const promise = new Promise((resolve, reject) => {
      _reject = reject
      let timeoutId = null
      try {
        client.on(null, data => {
          resolve(data)
          if (client) {
            client.close()
            client = null
          }
          clearTimeout(timeoutId)
        })
        if (timeout > 0) {
          timeoutId = setTimeout(() => {
            if (client) {
              client.close()
              client = null
              reject(new Error("Timeout: waitForEvent"))
            }
          }, timeout)
        }
      } catch (e) {
        clearTimeout(timeoutId)
        reject(e)
      }
    })
    return {
      promise,
      reject: _reject,
    }
  }
  async updateActive(sourceId, active) {
    await this._api.post(`/v1/sources/${sourceId}`, { data: { active } })
  }
  async watch(sourceId, fn, eventName = "", query = {}) {
    this._watch = this._watch || 1
    const _watch = this._watch++
    try {
      const events = _get(
        await this.eventSummaries(sourceId, eventName, query),
        "data.data",
        []
      ).reverse()
      await fn(events, false, _watch)
    } catch (e) {
      Sentry.captureException(e)
    }
    const partial = eventName ? `${sourceId}/${eventName}` : sourceId
    const client = await this._eventApi.watch(`/sources/${partial}/sse`, {
      query,
    })
    client.on(null, data => {
      fn && fn(data, true, _watch)
    })
    client.id = _watch
    return client
  }
}

class Api {
  constructor({ agents, cookieHeader, hybridStorage, $feature }) {
    const config = {}
    config.hybridStorage = hybridStorage // for lazy anonymous id insertion
    config.$feature = $feature
    if (cookieHeader) {
      config.headers = {}
      config.headers.cookie = cookieHeader
    }
    config.agents = agents
    this._http = new FetchWrapper(API_BASE_URL, config)
    this._rt = new FetchWrapper(RT_BASE_URL, config)
    this.v1 = new ApiV1(config)
    this._rtsse = new SSEApi(RT_BASE_URL)
  }
  async signOut() {
    this.v1.disable()
    this._http.disable()
    this._rt.disable()
    this._rtsse.disable()
    try {
      // XXX figure out why a call to GET /session is started after DELETE /sessions starts but before it ends
      // ... if we await api.signOut correctly before doing other stuff, it shouldn't happen!
      await this._http.delete("/session", {
        force: true,
      })
    } finally {
      this._http.enable()
      this._rt.enable()
      this._rtsse.enable()
      this.v1.enable()
    }
  }
  analyticsInitialAppLoad(referrer, landing) {
    return this._http.post("/analytics", { data: { referrer, landing } })
  }
  godmode(userId) {
    return this._http.post(`/godmode`, { data: { user_id: userId } })
  }
  sendSlackInvite(email, token) {
    return this._http.post(`/slack_invite`, { data: { email, token } })
  }
  getMyErrorPipeline() {
    return this._http.get("/user/error_pipeline", { expects: Pipeline })
  }
  getPipeline(id) {
    return this._http.get(`/pipelines/${id}`, { expects: Pipeline })
  }
  createPipeline(pipeline, nonce, deploy, creationContext = null) {
    const headers = {}
    if (creationContext) {
      const jsonStr = JSON.stringify(creationContext)
      headers["X-PD-WORKFLOW-CREATION-CONTEXT"] = jsonStr
    }
    return this._http.post("/pipelines", {
      query: {
        nonce,
        no_deploy: deploy ? undefined : 1,
      },
      data: pipeline,
      expects: Pipeline,
      provides: Pipeline,
      headers,
    })
  }
  updatePipeline(id, pipeline, nonce, deploy) {
    return this._http.put(`/pipelines/${id}`, {
      query: {
        nonce,
        no_deploy: deploy ? undefined : 1,
      },
      data: pipeline,
      expects: Pipeline,
      provides: Pipeline,
    })
  }
  getUploadParameters(id, file) {
    return this._http.get(
      `/pipelines/${id}/presign?filename=${file.meta.name}&type=${file.type}`
    )
  }
  addPipelineAttachment(id, file) {
    return this._http.post(`/pipelines/${id}/attachments`, {
      data: {
        file: JSON.stringify(file),
      },
    })
  }
  getPipelineAttachments(id) {
    return this._http.get(`/pipelines/${id}/attachments`)
  }
  deletePipelineAttachments(pipeline_id, attachment_id) {
    return this._http.delete(
      `/pipelines/${pipeline_id}/attachments/${attachment_id}`
    )
  }
  redeployDeployment(pipelineId, deploymentId) {
    return this._http.post(`/pipelines/${pipelineId}/redeploy`, {
      data: {
        deployment_id: deploymentId,
      },
      expects: Pipeline,
    })
  }
  deleteAllEvents(pipelineId) {
    if (!pipelineId) throw new Error("deleteAllEvents: pipelineId required")
    return this._rt.delete(`/events/${pipelineId}`)
  }
  pipelinesLastEvents(orgId) {
    return this._rt.get("/pipelines", { query: { orgId } })
  }
  deleteEvent(pipelineId, eventId, entryIds = []) {
    // Note: axios doesn't support DELETE with a body naturally,
    // hence the use of "data" in the config object
    if (!pipelineId) throw new Error("deleteEvent: pipelineId required")
    if (!eventId) throw new Error("deleteEvent: eventId required")
    this._rt.delete(`/events/${pipelineId}/${eventId}`, {
      data: { entry_ids: entryIds },
    })
  }
  pipelineExecSql(sqlQuery) {
    return this._rt.post(`/sql`, { data: { query: sqlQuery } })
  }
  getSqlTables() {
    return this._rt.get("/sql/tables")
  }
  sendEvent(id, exports) {
    return this._http.post(`/pipelines/${id}/send_event`, { data: { exports } })
  }
  sendTestEvent(id, source_id) {
    return this._http.post(`/pipelines/${id}/send_test_event`, {
      data: { source_id },
    })
  }
  createSurvey(survey) {
    return this._http.post("/user_surveys", { data: { survey } })
  }
  getDeployment(id) {
    return this._http.get(`/deployments/${id}`, { expects: Deployment })
  }
  createSource(source) {
    return this._http.post("/sources", { data: source, expects: Source })
  }
  updateSource(source) {
    return this._http.put(`/sources/${source.id}`, {
      data: source,
      expects: Source,
      provides: Source,
    })
  }
  fetchSource(id) {
    return this._http.get(`/sources/${id}`, { expects: Source })
  }
  fetchSources() {
    return this._http.get("/sources", { expects: Many(Source) })
  }
  updateAppLogo(appId, formData) {
    return this._http.post(`/apps/${appId}/logo`, { data: formData })
  }
  unwatchAllPipelines() {
    return this._rtsse.unwatchAll()
  }
  unwatchPipeline(id = null) {
    return this._rtsse.unwatch(`/sse/events/${id}`)
  }
  async watchPipeline(id, fn) {
    const client = await this._rtsse.watch(`/sse/events/${id}`)
    const [_url, _query] = client.url.split("?")
    client.on("event", data => {
      // In case of reconnect, we want to restart from the last received event.
      client.url = `${_url}/${data[0]}?${_query}`
      fn && fn({ on: "event", events: [data] })
    })
  }
  v1Test(deployedComponentId) {
    return this._http.get(`/v1/sources/${deployedComponentId}`)
  }
}

export default function createApi({
  app,
  agents,
  cookieHeader,
  hybridStorage,
  $feature,
}) {
  const api = new Api({ agents, cookieHeader, hybridStorage, $feature })
  app.config.globalProperties.$api = api
  app.provide("api", api)
  return api
}
