import mobile from "is-mobile"

// We're currently not interested in mobile users,
// and those sessions cost us more for little value
async function getLogRocket() {
  if (import.meta.env.DEV || mobile()) return null
  const logRocket = (await import("logrocket")).default
  logRocket.init("dgy8s6/pipedreamcom", {
    release: import.meta.env.VITE_SENTRY_RELEASE,
    console: {
      isEnabled: false,
    },
  })
  return logRocket
}

export async function identify(...args) {
  const lr = await getLogRocket()
  if (!lr) return
  lr.identify(...args)
}

export async function getSessionURL(...args) {
  const lr = await getLogRocket()
  if (!lr) return
  lr.getSessionURL(...args)
}

export async function track(...args) {
  const lr = await getLogRocket()
  if (!lr) return
  lr.track(...args)
}
