import { PD_SDK_PRODUCT_METRICS } from "@/src/config"
import * as pdsdk from "@plugins/pdsdk"

async function sendClickToPipedream(clickEvent) {
  if (!PD_SDK_PRODUCT_METRICS) return
  try {
    const element = isTargetMonitored(clickEvent)
    if (element) {
      const target = clickEvent.target
      const matched = element.matched
      delete element.matched
      element.data = matched.dataset
      element.eventType = clickEvent.type

      switch (element.type) {
        case "icon-button":
          element.innerText = target.innerText
          element.icon = matched.getAttribute("icon")
          break
        case "v-input":
          // event.dataset = clickEvent.target.dataset
          break
        case "a":
          element.innerText = target.innerText
          element.href = target.href
          break
      }

      const event = {
        client: {
          referrer: document.referrer,
          uri: document.baseURI,
          cookie: document.cookie,
          query: window.location.search,
          user_agent: navigator.userAgent,
        },
        timestamp: new Date().toISOString(),
        frontend: element,
      }
      await pdsdk.sendEvent(PD_SDK_PRODUCT_METRICS, event)
    }
  } catch (err) {
    // console.log("Failed to send click event to PDSDK: ", err)
  }
}

function isTargetMonitored(clickEvent) {
  let info
  info = getByType(clickEvent, "a")
  if (info) return info
  info = getByClass(clickEvent, "icon-button")
  if (info) return info
  info = getByClass(clickEvent, "v-input")
  if (info) {
    const trackedTypes = ["checkbox", "toggle", "icon"]
    for (let trackedType of trackedTypes) {
      if (info.matched.classList.contains(trackedType)) {
        info.inputType = trackedType
        return info
      }
    }
  }
  return null
}

function getContext(clickEvent) {
  const contexts = [
    ".header-container",
    ".dashboard",
    ".pipeline-edit",
    ".navbar",
    ".pipeline-header",
    ".pipeline-sql",
    ".secrets",
    ".settings",
  ]
  const selector = contexts.join(",")
  for (let elm of clickEvent.path) {
    if (elm.matches(selector)) {
      for (let parent of contexts) {
        if (elm.classList.contains(parent.substr(1))) {
          return parent
        }
      }
    }
  }
  return null
}

function getByClass(clickEvent, klass) {
  for (let e of clickEvent.path) {
    if (e instanceof HTMLElement && e.classList.contains(klass)) {
      const context = getContext(clickEvent)
      return { type: klass, context, matched: e }
    }
  }
  return null
}

function getByType(clickEvent, type) {
  for (let e of clickEvent.path) {
    if (e instanceof HTMLElement && e.matches(type)) {
      const context = getContext(clickEvent)
      return { type, context, matched: e }
    }
  }
  return null
}

export { sendClickToPipedream }
