import { gql } from "@urql/core"
import _pick from "lodash-es/pick"
import { pipeline as pipelineFragment, pipelineWiths } from "@graphql/fragments"

export default async function updatePipeline(id, pipelineData, withs = {}) {
  const pipelineInput = _pick(pipelineData, [
    "archived",
    "concurrency",
    "description",
    "emitterConnected",
    "forwardToGlobalErrorWorkflow",
    "inactive",
    "lambdaMemory",
    "lambdaTimeout",
    "name",
    "public",
    "dataPublic",
    "rateLimit",
    "rateLimitBucketSizeSeconds",
    "readmeMd",
    "redisDelivery",
    "testEventJson",
    "maxEventQueueSize",
  ])
  const result = await this.mutation(
    gql`
      mutation updatePipeline(
        $id: String!
        $pipeline: PipelineInput!
        ${pipelineWiths.fragmentPart}
      ) {
        updatePipeline(id: $id, pipeline: $pipeline) {
          pipeline {
            _can
            ...PipelineParts
          }
        }
      }
      ${pipelineFragment}
    `,
    {
      id,
      pipeline: pipelineInput,
      ...withs,
    }
  ).toPromise()
  if (!result.data) throw new Error("updatePipeline failed")
  const { errors, pipeline } = result.data.updatePipeline
  if (errors && errors.length) throw errors
  // TODO pipelineRevive
  return pipeline
}
