import { gql } from "@urql/core"
import _pick from "lodash-es/pick"
import {
  deployedComponent as deployedComponentFragment,
  deployedComponentWiths,
} from "@graphql/fragments"

export default {
  async mutate({ id, orgId, data, ...withs }) {
    const args = _pick(data, [
      "active",
      "name",
      "savedComponentId",
      "componentCode",
      "componentUrl",
      "configuredPropsJson",
      "dynamicPropsId",
    ])
    const result = await this.mutation(
      gql`
        mutation updateDeployedComponent(
          $id: String!
          $active: Boolean
          $orgId: String
          $name: String
          $savedComponentId: String
          $componentCode: String
          $componentUrl: String
          $configuredPropsJson: String
          $dynamicPropsId: String
          ${deployedComponentWiths.fragmentPart}
        ) {
          updateDeployedComponent(
            id: $id
            active: $active
            orgId: $orgId
            name: $name
            savedComponentId: $savedComponentId
            componentCode: $componentCode
            componentUrl: $componentUrl
            configuredPropsJson: $configuredPropsJson
            dynamicPropsId: $dynamicPropsId
          ) {
            observations
            deployedComponent {
              ...DeployedComponentParts
            }
          }
        }
        ${deployedComponentFragment}
      `,
      {
        id,
        orgId: orgId || undefined,
        ...args,
        ...withs,
      }
    ).toPromise()
    const {
      errors,
      deployedComponent,
      observations,
    } = result.data.updateDeployedComponent
    if (errors && errors.length) throw errors
    return { deployedComponent, observations }
  },
}
