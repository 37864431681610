import { gql } from "@urql/core"
import _get from "lodash-es/get"

export default {
  name: "adminBanUser",
  async mutate(id) {
    const resp = await this.mutation(
      gql`
        mutation adminBanUser($id: String!) {
          adminBanUser(id: $id) {
            errors
          }
        }
      `,
      {
        id,
      }
    ).toPromise()
    const { errors } = resp.data.adminBanUser
    if (errors && errors.length) throw errors
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "adminBanUser.errors.length")) {
      cache.invalidate({ __typename: "User", id: args.id })
    }
  },
}
