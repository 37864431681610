export default async function regenerateApiKey() {
  const resp = await this.mutation(
    `
    mutation regenerateApiKey {
      regenerateApiKey {
        apiKey
        errors
      }
    }
  `
  ).toPromise()
  // TODO update(store) {
  const { apiKey, errors } = resp.data.regenerateApiKey
  if (errors && errors.length) throw errors
  return apiKey
}
