import _get from "lodash-es/get"
export default {
  name: "pipelineAddCollaborator",
  async mutate(pipelineId, addKey) {
    // TODO(apollo) cache update
    const resp = await this.mutation(
      `
      mutation addPipelineCollaborator($pipelineId: String!, $addKey: String!) {
        pipelineAddCollaborator(pipelineId: $pipelineId, addKey: $addKey) {
          collaborator {
            inviteeUserId
            inviteeEmail
            inviteeUsername
            username
            createdAt
          }
          errors
        }
      }
    `,
      {
        pipelineId,
        addKey,
      }
    ).toPromise()
    const { errors } = resp.data.pipelineAddCollaborator
    if (errors && errors.length) throw errors
  },
  update(result, args, cache /* , info */) {
    const collaborator = _get(result, "pipelineAddCollaborator.collaborator")
    if (collaborator) {
      cache.invalidate(
        { __typename: "Pipeline", id: args.pipelineId },
        "collaborators"
      )
    }
  },
}
