import { gql } from "@urql/core"

// XXX should this be PIPELINE_CONNECTIONS_QUERY with ownerName set to viewer.username?
export default gql`
  query viewerPipelines(
    $offset: Int
    $limit: Int
    $orderBy: String
    $direction: String
    $inactive: Boolean
    $archived: Boolean
    $search: String
    $collaborations: Boolean
    $ids: [String!]
  ) {
    viewer {
      id
      pipelines(
        offset: $offset
        limit: $limit
        orderBy: $orderBy
        direction: $direction
        inactive: $inactive
        archived: $archived
        search: $search
        collaborations: $collaborations
        ids: $ids
      ) {
        id
        name
        description
        nameSlug
        emits
        updatedAt
        inactive
        archived
        user {
          id
          username
        }
        latestDeployment {
          id
          version
          updatedAt
          cells {
            id
            sourceParams
            type
            action {
              id
              imgSrc
              appConnections {
                app {
                  id
                }
              }
            }
            source {
              id
              imgSrc
              interface
            }
          }
        }
      }
    }
  }
`
