import { gql } from "@urql/core"
import { action } from "@graphql/fragments"

export default gql`
  query viewerActionConnections(
    $after: String
    $q: String
    $published: Boolean
    $appId: String
    $latest: Boolean
    $hidden: Boolean
    $orderBy: OrderByActionInput
  ) {
    viewer {
      id
      actionConnections(
        first: 20
        after: $after
        q: $q
        published: $published
        appId: $appId
        latest: $latest
        hidden: $hidden
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...ActionParts
        }
      }
    }
  }
  ${action}
`
