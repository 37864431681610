const mutation = `
  mutation httpInterfaceDelete($id: String!, $orgId: String) {
    my(orgId: $orgId) {
      httpInterfaceDelete(id: $id) {
        errors
      }
    }
  }
`
export default async function httpInterfaceDelete(
  httpInterface,
  { orgId } = {}
) {
  const results = await this.mutation(mutation, {
    id: httpInterface.id,
    orgId,
  }).toPromise()
  const { errors } = results.data.my.httpInterfaceDelete
  if (errors && errors.length) throw errors
}
