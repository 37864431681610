import { Sentry } from "@sentry"
import { getWindow } from "ssr-window"

const __window = getWindow()

// XXX anlaytics.js autoloads gtag.... I don't even think this is necessary... but w.e
__window.dataLayer = __window.dataLayer || []
__window.dataLayer.push(["js", new Date()], ["config", "AW-770996949"])

const LOCAL_STORAGE_KEY = "pd:conv"

// state format:
// "pd:conv" {
//   d: [NUM_DEPLOYS, SENT_EVENT_CONVERSION_CALL],
// }
const state = (function() {
  let loaded
  try {
    loaded = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}
  } catch (err) {
    /* do nothing */
  }
  return loaded || {}
})()

function persistState() {
  let success = true
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
  } catch (err) {
    success = false
  }
  return success
}

// should be manually enabled for new users
export function enableTrackingDeploys() {
  if (import.meta.env.DEV) return
  state.d = [0, false]
  persistState()
}

export async function trackDeploy() {
  if (state.d == null) return
  state.d[0] += 1
  if (!persistState()) return
  if (state.d[0] >= 5 && !state.d[1]) {
    // XXX maybe use analytics track instead? dunno if timing issue that analytics doesn't load in time
    if (!__window.gtag) {
      Sentry.captureMessage("could not track deploy, window.gtag not defined")
      return
    }
    __window.gtag("event", "conversion", {
      send_to: "AW-770996949/2qlICMegvO0BENX10e8C",
      // XXX this is no longer right even though docs say to do this?
      // event_callback() {
      //   state.d[1] = true
      //   persistState()
      // },
    })
    state.d[1] = true
    persistState()
  }
}
