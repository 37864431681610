import { gql } from "@urql/core"
import { adminUser } from "@graphql/fragments"

// XXX admin only root query (for now -- either change this or make the naming more explicit)
export default gql`
  query userConnections(
    $after: String
    $q: String
    $orderBy: OrderByUserInput
  ) {
    userConnections(after: $after, first: 20, q: $q, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...AdminUserParts
      }
    }
  }
  ${adminUser}
`
