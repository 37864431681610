import { gql } from "@urql/core"
import { deployedComponent, deployedComponentWiths } from "@graphql/fragments"

export default gql`
  query myDeployedComponents(
    $first: Int = 20
    $after: String
    $q: String
    $active: Boolean
    $exceptIds: [String!]
    $orderBy: [DeployedComponentOrderByEnum!]
    $orgId: String
    ${deployedComponentWiths.fragmentPart}
  ) {
    my(orgId: $orgId) {
      id
      deployedComponents(
        first: $first
        after: $after
        q: $q
        active: $active
        exceptIds: $exceptIds
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...DeployedComponentParts
        }
      }
    }
  }
  ${deployedComponent}
`
