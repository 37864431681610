import { gql } from "@urql/core"
import { pipeline, pipelineWiths } from "@graphql/fragments"

export default gql`
  query myAuthProvisionPipelines(
    $after: String
    $id: String!
    $exceptIds: [String!]
    $orderBy: [PipelineOrderByEnum!]
    $orgId: String
    ${pipelineWiths.fragmentPart}
  ) {
    my(orgId: $orgId) {
      id
      authProvisionPipelines(
        after: $after
        first: 20
        id: $id
        exceptIds: $exceptIds
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          _can
          ...PipelineParts
        }
      }
    }
  }
  ${pipeline}
`
