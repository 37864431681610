import { gql } from "@urql/core"
import _get from "lodash-es/get"

export function invalidateDeployedComponentsQueries(cache) {
  cache
    .inspectFields("Query")
    .filter(f => ["admin", "my", "viewer"].includes(f.fieldName))
    .forEach(c => {
      const context = cache.resolve("Query", c.fieldKey)
      cache
        .inspectFields(context)
        .filter(f => f.fieldName == "deployedComponents")
        .forEach(f => {
          cache.invalidate(context, f.fieldKey)
        })
    })
  cache
    .inspectFields("Query")
    .filter(f => f.fieldName == "deployedComponents")
    .forEach(f => cache.invalidate("Query", f.fieldKey))
}
export default {
  async mutate(id, opts = {}) {
    const mutation = gql`
      mutation deleteDeployedComponent(
        $id: String!
        $ignoreHookErrors: Boolean
      ) {
        deleteDeployedComponent(id: $id, ignoreHookErrors: $ignoreHookErrors) {
          errors
        }
      }
    `
    const variables = {
      id,
      ignoreHookErrors: opts.ignoreHookErrors,
    }
    const resp = await this.mutation(mutation, variables).toPromise()
    const { errors } = resp.data.deleteDeployedComponent
    if (errors && errors.length) throw errors
  },
  update(result, args, cache) {
    if (
      !result.error &&
      !_get(result, "deleteDeployedComponent.errors.length")
    ) {
      cache.invalidate({
        __typename: "DeployedComponent",
        id: args.id,
      })
      invalidateDeployedComponentsQueries(cache)
    }
  },
}
