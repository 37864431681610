import deployedComponentUpdate from "./workflow/deployedComponentUpdate"
import executeTestRequest from "./workflow/executeTestRequest"
import httpInterfaceCreate from "./workflow/httpInterfaceCreate"
import httpInterfaceDelete from "./workflow/httpInterfaceDelete"
import httpInterfaceUpdate from "./workflow/httpInterfaceUpdate"
import pipelineAddStep from "./workflow/pipelineAddStep"
import pipelineCreate from "./workflow/pipelineCreate"
import pipelineDeleteStep from "./workflow/pipelineDeleteStep"
import pipelineDeploy from "./workflow/pipelineDeploy"
import pipelineUpdate from "./workflow/pipelineUpdate"
import pipelineUpdateStep from "./workflow/pipelineUpdateStep"
import savedComponentCreate from "./workflow/savedComponentCreate"
import savedComponentDelete from "./workflow/savedComponentDelete"
import pipeCreate from "./workflow/pipeCreate"
import pipeDelete from "./workflow/pipeDelete"
import pipeUpdate from "./workflow/pipeUpdate"
import timerInterfaceCreate from "./workflow/timerInterfaceCreate"
import timerInterfaceDelete from "./workflow/timerInterfaceDelete"
import timerInterfaceUpdate from "./workflow/timerInterfaceUpdate"

export default {
  deployedComponentUpdate,
  executeTestRequest,
  httpInterfaceCreate,
  httpInterfaceDelete,
  httpInterfaceUpdate,
  pipeCreate,
  pipeDelete,
  pipeUpdate,
  pipelineAddStep,
  pipelineCreate,
  pipelineDeleteStep,
  pipelineDeploy,
  pipelineUpdate,
  pipelineUpdateStep,
  savedComponentCreate,
  savedComponentDelete,
  timerInterfaceCreate,
  timerInterfaceDelete,
  timerInterfaceUpdate,
}
