import SuspendedRouterView from "@components/suspended-router-view.vue"

// routes are shared between frontend server (for unfurling) and client (obviously)
// src/router.js does the reviving of components
export default [
  {
    path: "/auth/google",
    component: () => import("@views/auth/oauth-callback.vue"),
    props: { providerName: "google" },
    meta: { requireUser: null },
  },
  {
    path: "/auth/github",
    component: () => import("@views/auth/oauth-callback.vue"),
    props: { providerName: "github" },
    meta: { requireUser: null },
  },
  {
    path: "",
    component: () => import("@views/layouts/root.vue"),
    children: [
      {
        path: "docs/api/:subroute?",
        props: true,
        meta: {
          layout: { contentControlOverflow: true, contentNoPx: true },
          page: "ApiDocs",
          requireUser: "admin",
          title: "API Docs",
        },
        component: () => import("@views/docs/api.vue"),
      },
      {
        path: "sources",
        meta: {
          page: "Sources",
          requireUser: true,
        },
        component: SuspendedRouterView,
        children: [
          {
            name: "sources-index",
            path: "",
            props: true,
            meta: {
              layout: { onboarding: true },
              title: "Sources",
              page: "Index",
            },
            component: () => import("@views/sources/list.vue"),
          },
          {
            path: "new",
            name: "sources-new",
            meta: {
              layout: { contentControlOverflow: true, contentNoPx: true },
              title: "New Source",
              page: "New Source",
            },
            component: () => import("@views/sources/new.vue"),
          },
          {
            path: ":dcId",
            props: true,
            meta: {
              layout: { contentControlOverflow: true, contentNoPx: true },
              page: "Source",
            },
            component: () => import("@views/sources/source/layout.vue"),
            children: [
              {
                path: "",
                props: true,
                meta: {
                  page: "Events",
                },
                component: () => import("@views/sources/source/events.vue"),
                children: [
                  {
                    name: "deployed-component-events",
                    path: "",
                    component: () => import("@views/sources/source/index.vue"),
                  },
                ],
              },
              {
                path: "logs",
                meta: {
                  page: "Logs",
                },
                component: () => import("@views/sources/source/logs.vue"),
              },
              {
                path: "configuration",
                meta: {
                  page: "Config",
                },
                component: () =>
                  import("@views/sources/source/configuration.vue"),
              },
            ],
          },
        ],
      },
      {
        name: "alpha",
        path: "alpha",
        meta: {
          page: "Alpha",
          title: "Alpha",
          requireUser: true,
        },
        component: () => import("@views/features.vue"),
      },
      {
        path: "actions",
        component: () => import("@views/actions/list.vue"),
        meta: {
          layout: { onboarding: true },
          page: "Actions",
          title: "Actions",
          requireUser: true,
        },
      },
      {
        path: "actions/new",
        component: () => import("@components/action-form.vue"),
        meta: {
          page: "New Action",
          title: "Create New Action",
          requireUser: true,
        },
      },
      {
        path: "actions/:actionId/edit",
        component: () => import("@components/action-form.vue"),
        props: true,
        meta: {
          page: "Action",
          title: route => `Edit Action ${route.params.actionId}`,
          requireUser: true,
        },
      },
      {
        path: "admin",
        meta: {
          page: "Admin",
          requireUser: "admin",
        },
        component: () => import("@views/admin/_layout.vue"),
        children: [
          {
            name: "alpha-internal",
            path: "alpha",
            meta: {
              page: "Alpha",
              title: "Alpha",
              requireUser: "admin",
            },
            component: () => import("@views/features.vue"),
          },
          {
            path: "",
            meta: {
              page: "Active Sessions",
              title: "Admin | Active Sessions",
            },
            component: () => import("@views/admin/index.vue"),
          },
          {
            path: "sources/:id?", // XXX moved since we replaces /sources
            meta: {
              page: "Sources",
              title: "Admin | Sources",
            },
            component: () => import("@views/edit-source.vue"),
          },
          {
            path: "actions",
            component: () => import("@views/admin/actions/list.vue"),
            meta: {
              page: "Actions",
              title: "Admin | Actions",
            },
          },
          {
            path: "actions/new",
            component: () => import("@views/admin/actions/edit.vue"),
            meta: {
              page: "New Action",
              title: "Admin | Create New Action",
            },
          },
          {
            path: "actions/:actionId/edit",
            component: () => import("@views/admin/actions/edit.vue"),
            props: true,
            meta: {
              page: "Action",
              title: route => `Admin | Actions | ${route.params.actionId}`,
            },
          },
          {
            path: "apps",
            component: () => import("@views/admin/apps/list.vue"),
            meta: {
              page: "Apps",
              title: "Admin | Apps",
            },
          },
          {
            path: "apps/new",
            component: () => import("@views/admin/apps/edit.vue"),
            meta: {
              page: "New App",
              title: "Admin | Create New App",
            },
          },
          {
            path: "apps/:appIdOrNameSlug",
            redirect: to => `/admin/apps/${to.params.appIdOrNameSlug}/edit`,
            props: true,
          },
          {
            path: "apps/:appIdOrNameSlug/edit",
            component: () => import("@views/admin/apps/edit.vue"),
            props: true,
            meta: {
              page: "App",
              title: route => `Admin | Apps | ${route.params.appIdOrNameSlug}`,
            },
          },
          {
            path: "users",
            component: () => import("@views/admin/users/list.vue"),
            meta: {
              page: "Users",
              title: "Admin | Users",
            },
          },
          {
            path: "organizations",
            component: () => import("@views/admin/organizations/list.vue"),
            meta: {
              page: "Orgs",
              title: "Admin | Organizations",
            },
          },
          {
            path: "workflows",
            component: () => import("@views/admin/workflows/list.vue"),
            meta: {
              page: "Workflows",
              title: "Admin | Workflows",
            },
          },
        ],
      },
      {
        path: "accounts",
        name: "accounts",
        component: () => import("@views/accounts/list.vue"),
        meta: {
          layout: { onboarding: true },
          page: "Accounts",
          title: "Connected Accounts",
          requireUser: true,
        },
      },
      {
        path: "workflows",
        name: "workflows",
        component: () => import("@views/workflows/list.vue"),
        meta: {
          layout: { onboarding: true },
          page: "Workflows",
          title: "Workflows",
          requireUser: true,
        },
      },
      {
        path: "",
        name: "landing",
        component: () => import("@views/index.vue"),
        meta: {
          page: "Landing",
          title: "Connect APIs, Remarkably Fast",
          layout: { contentNoPx: true },
        },
      },
      {
        path: "environment",
        redirect: "/settings/env-vars",
      },
      {
        path: "settings",
        component: () => import("@views/settings/_layout.vue"),
        meta: {
          layout: { contentControlOverflow: true, contentNoPx: true },
          page: "Settings",
          requireUser: true,
        },
        children: [
          {
            path: "",
            redirect: "/settings/account",
          },
          {
            path: "account",
            meta: {
              page: "Account",
              title: "Settings | Account",
            },
            component: () => import("@views/settings/account.vue"),
          },
          {
            path: "users",
            meta: {
              page: "Account",
              title: "Settings | Users",
            },
            component: () => import("@views/settings/users.vue"),
          },
          {
            path: "app",
            meta: {
              page: "App",
              title: "Settings | App",
            },
            component: () => import("@views/settings/app.vue"),
          },
          {
            path: "env-vars",
            meta: {
              page: "Env",
              title: "Settings | Env",
            },
            component: () => import("@views/settings/env-vars.vue"),
          },
          {
            path: "billing",
            meta: {
              page: "Billing",
              title: "Settings | Billing",
            },
            component: () => import("@views/settings/billing.vue"),
          },
        ],
      },
      {
        path: "sql",
        component: () => import("@views/sql.vue"),
        meta: {
          layout: { contentControlOverflow: true, contentNoPx: true },
          page: "SQL",
          title: "SQL",
          requireUser: true,
        },
      },
      {
        path: "help",
        redirect: { path: "support" },
      },
      {
        path: "support",
        component: () => import("@views/support.vue"),
        props: { page: "Support" },
        meta: {
          page: "Support",
          title: "Support",
        },
      },
      {
        path: "errors",
        component: () => import("@views/errors.vue"),
        meta: {
          layout: { contentControlOverflow: true, contentNoPx: true },
          page: "Errors",
          title: "Errors",
          requireUser: true,
        },
      },
      {
        path: "about",
        component: () => import("@views/about.vue"),
        meta: {
          layout: { contentNoPx: true },
          page: "About Us",
          title: "About Pipedream",
        },
      },
      {
        path: "requestbin",
        name: "requestbin",
        component: () => import("@views/requestbin.vue"),
        meta: {
          page: "RequestBin",
          title:
            "RequestBin — A modern request bin to collect, inspect and debug HTTP requests and webhooks",
          layout: { contentNoPx: true },
        },
      },
      {
        path: "explore",
        name: "explore",
        component: () => import("@views/explore/index.vue"),
        meta: {
          page: "Explore",
          title: "Explore Popular APIs, Triggers, Actions and More",
        },
      },
      {
        path: "apps",
        component: () => import("@views/explore/apps/index.vue"),
        meta: {
          page: "App",
          title: "Integrated APIs & Applications",
        },
      },
      {
        path: "apps/:dashedNameSlug",
        props: true,
        component: () => import("@views/explore/apps/_layout.vue"),
        meta: {
          title: null,
        },
        children: [
          {
            path: "",
            name: "explore-app",
            component: () => import("@views/explore/apps/show.vue"),
            meta: {
              page: "App",
            },
          },
          {
            path: "integrations/:otherDashedNameSlug",
            name: "explore-app-to-app",
            props: true,
            component: () => import("@views/explore/apps/with-other-app.vue"),
            meta: {
              page: "App With Other App",
            },
          },
        ],
      },
      {
        path: "pricing",
        component: () => import("@views/pricing/index.vue"),
        meta: {
          layout: { headerDark: true },
          page: "Pricing",
          title: "Pricing",
        },
      },
      {
        path: "privacy",
        component: () => import("@views/markdown-page.vue"),
        props: { page: "privacy" },
        meta: {
          layout: { contentControlOverflow: true, contentNoPx: true },
          page: "Privacy",
          title: "Privacy Statement",
        },
      },
      {
        // this is important to keep old sign-in links working!
        path: "sign-in",
        redirect: { name: "login" },
      },
      {
        path: "auth",
        component: () => import("@views/auth/_layout.vue"),
        meta: {
          layout: { navHidden: true },
          page: "Sign In",
          title: "Sign In",
        },
        children: [
          {
            name: "login",
            path: "login",
            component: () => import("@views/auth/sign-x.vue"),
            props: {
              x: "in",
            },
            meta: {
              page: "Log In",
              title: "Log In",
              requireUser: null,
            },
          },
          {
            name: "logout",
            path: "logout",
            component: () => import("@views/auth/logout.vue"),
          },
          {
            name: "signup",
            path: "signup",
            component: () => import("@views/auth/sign-x.vue"),
            props: {
              x: "up",
            },
            meta: {
              page: "Sign Up",
              title: "Sign Up",
              requireUser: null,
            },
          },
          {
            name: "forgot-password",
            path: "forgot-password",
            component: () => import("@views/auth/forgot-password.vue"),
            meta: {
              page: "Forgot Password",
              title: "Forgot Password",
              requireUser: null,
            },
          },
          {
            name: "reset-password",
            path: "reset-password",
            component: () => import("@views/auth/reset-password.vue"),
            meta: {
              page: "Reset Password",
              title: "Reset Password",
              requireUser: null,
            },
          },
          {
            name: "confirm-username",
            path: "confirm-username",
            component: () => import("@views/auth/confirm-username.vue"),
            meta: {
              page: "Confirm Username",
              title: "Confirm Username",
              requireUser: true,
            },
          },
          {
            name: "survey",
            path: "survey",
            component: () => import("@views/auth/survey.vue"),
            meta: {
              page: "Survey",
              title: "Survey",
              requireUser: true,
            },
          },
          {
            name: "verify-email",
            path: "verify-email",
            component: () => import("@views/auth/verify-email.vue"),
            meta: {
              page: "Verify Email",
              title: "Verify Email",
              requireUser: true,
            },
          },
          {
            path: ":pathMatch(.*)*",
            redirect: { name: "signup" },
          },
        ],
      },
      {
        path: "organizations/new",
        component: () => import("@views/organizations/new.vue"),
        meta: {
          page: "New Organization",
          title: "New Organization",
          requireUser: true,
        },
      },
      {
        path: "terms",
        component: () => import("@views/markdown-page.vue"),
        props: { page: "terms" },
        meta: {
          layout: { contentControlOverflow: true, contentNoPx: true },
          page: "Terms",
          title: "Terms of Service",
        },
      },
      {
        path: "contributing",
        component: () => import("@views/markdown-page.vue"),
        props: { page: "contributing" },
        meta: {
          page: "Contributing",
          title: "Contributing to Pipedream",
          layout: { contentControlOverflow: true, contentNoPx: true },
        },
      },
      {
        path: "dpa",
        component: () => import("@views/markdown-page.vue"),
        props: { page: "dpa" },
        meta: {
          page: "DPA",
          title: "Data Protection Addendum",
        },
      },
      {
        path: "new",
        // this is to match /edit route dom structure so vue doesn"t re-render
        meta: {
          layout: { contentControlOverflow: true, contentNoPx: true },
          page: "New Pipeline",
          requireUser: true,
        },
        component: SuspendedRouterView,
        children: [
          {
            path: "",
            component: () => import("@views/pipeline.vue"),
            meta: {
              title: null,
            },
            children: [
              {
                name: "pipeline-new",
                path: "",
                component: () => import("@views/pipeline/inspect.vue"),
              },
              {
                name: "pipeline-new-readme",
                path: "readme",
                props: true,
                component: () => import("@views/pipeline/readme.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "@:ownerName?",
        meta: {
          page: "@User",
        },
        component: SuspendedRouterView,
        children: [
          {
            name: "profile",
            path: "",
            component: () => import("@views/profile.vue"),
            meta: {
              page: "Profile",
              title: route => `@${route.params.ownerName}`,
            },
          },
          {
            path: ":nameSlug(.+-)?:id/inc",
            name: "workflow-edit",
            props: true,
            meta: {
              layout: { contentControlOverflow: true, contentNoPx: true },
            },
            component: () => import("@views/workflow/index.vue"),
          },
          {
            path: ":nameSlug(.+-)?:id",
            component: () => import("@views/pipeline.vue"),
            props: true,
            meta: {
              layout: { contentControlOverflow: true, contentNoPx: true },
              page: "Pipeline",
              noscroll: true,
              title: null,
            },
            children: [
              {
                name: "edit-pipeline",
                path: "",
                redirect: { name: "pipeline-edit" },
              },
              {
                name: "pipeline-edit",
                path: ":subEdit?/edit",
                props: true,
                component: () => import("@views/pipeline/inspect.vue"),
                meta: {
                  page: "Inspect",
                  requireUser: to => to.query.copy === "true",
                },
              },
              {
                name: "pipeline-view",
                path: "view",
                props: true,
                component: () => import("@views/pipeline/inspect.vue"),
                meta: {
                  page: "Inspect",
                },
              },
              {
                name: "pipeline-readme",
                path: "readme",
                props: true,
                component: () => import("@views/pipeline/readme.vue"),
                meta: {
                  page: "ReadMe",
                },
              },
              {
                name: "pipeline-settings",
                path: "settings",
                props: true,
                component: () => import("@views/pipeline/settings.vue"),
                meta: {
                  page: "Settings",
                },
              },
            ],
          },
        ],
      },
      {
        path: ":pathMatch(.*)*",
        name: "not-found",
        meta: {
          layout: { contentControlOverflow: true, contentNoPx: true },
          page: "404",
          title: "404: Not Found",
        },
        component: () => import("@views/not-found.vue"),
      },
    ],
  },
]
