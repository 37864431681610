import { gql } from "@urql/core"
import _get from "lodash-es/get"
import _pick from "lodash-es/pick"
import { actionRevive } from "@graphql/types/action"
import { action as actionFragment } from "@graphql/fragments"
import { invalidateActionQueries } from "../saveAction"

export default {
  name: "adminSaveAction",
  async mutate(id, actionData) {
    const actionInput = _pick(actionData, [
      "adminOnly",
      "appConnections",
      "codeRaw",
      "defaultNamespace",
      "description",
      "hidden",
      "verified",
      "imgSrc",
      "title",
    ])
    for (const idx in actionInput.appConnections) {
      actionInput.appConnections[idx] = _pick(actionInput.appConnections[idx], [
        "appId",
        "nameSlug",
      ])
    }
    actionInput.featuredWeight = parseInt(actionData.featuredWeight)
    if (actionData._codeConfig) {
      actionInput.codeConfigJson = JSON.stringify(actionData._codeConfig)
    }
    const resp = await this.mutation(
      gql`
        mutation adminSaveAction($id: String, $action: AdminActionInput!) {
          adminSaveAction(id: $id, action: $action) {
            action {
              ...ActionParts
            }
            errors
          }
        }
        ${actionFragment}
      `,
      {
        id,
        action: actionInput,
      }
    ).toPromise()
    const { action, errors } = resp.data.adminSaveAction
    if (errors && errors.length) throw errors
    if (!action) throw ["could not save action"]
    actionRevive(action)
    return action
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "adminSaveAction.errors.length")) {
      const id = _get(result, "adminSaveAction.action.id")
      if (id) {
        invalidateActionQueries(cache)
      }
    }
  },
}
