// XXX prepare

export function actionRevive(action) {
  if (!action.id) action.id = null
  if (!action.title) action.title = ""
  if (!action.hidden) action.hidden = false
  if (!action.imgSrc) action.imgSrc = ""
  if (!("adminOnly" in action)) action.adminOnly = true
  if (!action.defaultNamespace) action.defaultNamespace = ""
  if (!action.description) action.description = ""
  if (!action.featuredWeight) action.featuredWeight = 0
  if (!action.publishedAt) action.publishedAt = null
  if (!action.publishedVersionMajor) action.publishedVersionMajor = 0
  if (!action.publishedVersionMinor) action.publishedVersionMinor = 0
  if (!action.codeRaw) action.codeRaw = ""
  if (!action.appConnections) action.appConnections = []
  if (!action._codeConfig && action.codeConfigJson) {
    action._codeConfig = JSON.parse(action.codeConfigJson)
  }
}
