import * as fragments from "@graphql/fragments"

const mutation = `
  mutation httpInterfaceCreate(
    $customResponse: Boolean!
    $orgId: String
  ) {
    my(orgId: $orgId) {
      httpInterfaceCreate(customResponse: $customResponse) {
        httpInterface {
          ...WorkflowHttpInterfaceParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.httpInterface}
`
export default async function httpInterfaceCreate(
  customResponse = false,
  { orgId } = {}
) {
  const results = await this.mutation(mutation, {
    customResponse,
    orgId,
  }).toPromise()
  const { errors, httpInterface } = results.data.my.httpInterfaceCreate
  if (errors && errors.length) throw errors
  if (!httpInterface) throw "Expected httpInterface!"
  return httpInterface
}
