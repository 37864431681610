const settings = {
  app_clockFormat: {
    label: "Clock format",
    default: 12,
  },
  editor_tabSize: {
    label: "Tab Size",
    default: 2,
  },
  editor_vimMode: {
    label: "Enable Vim Keyboard Shortcuts",
    default: false,
  },
  editor_wordWrap: {
    label: "Enable Word Wrap",
    default: false,
  },
}

const labelMap = {}
for (const k in settings) {
  labelMap[k] = settings[k].label
}

export function createSettings({ hybridStorage }) {
  return new Proxy(settings, {
    get(o, k) {
      if (k === "_labels") return labelMap
      if (!(k in settings)) return o[k]
      let ret = settings[k].default
      if (hybridStorage.settings) {
        if (k in hybridStorage.settings) {
          ret = hybridStorage.settings[k]
        }
      }
      // console.log("[debug] settings get", k, ret)
      return ret
    },
    set(o, k, v) {
      if (!(k in settings)) return (o[k] = v)
      if (settings[k].default === v) {
        if (hybridStorage.settings) {
          delete hybridStorage.settings[k]
        }
      } else {
        if (!hybridStorage.settings) {
          hybridStorage.settings = {}
        }
        hybridStorage.settings[k] = v
      }
      return true
    },
  })
}
