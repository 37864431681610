import _get from "lodash-es/get"
export function invalidateUsersQueries(cache) {
  cache
    .inspectFields("Query")
    .filter(f => ["admin", "my", "viewer"].includes(f.fieldName))
    .forEach(c => {
      const context = cache.resolve("Query", c.fieldKey)
      cache
        .inspectFields(context)
        .filter(f => f.fieldName == "users")
        .forEach(f => {
          cache.invalidate(context, f.fieldKey)
        })
    })
  cache
    .inspectFields("Query")
    .filter(f => f.fieldName == "users")
    .forEach(f => cache.invalidate("Query", f.fieldKey))
}
export default {
  async mutate(userId) {
    const resp = await this.mutation(
      ` mutation deleteUser($id: String!) {
      deleteUser(id: $id) {
        errors
      }
    }
  `,
      {
        id: userId,
      }
    ).toPromise()
    const { errors } = resp.data.deleteUser
    if (errors && errors.length) throw errors
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "deleteUser.errors.length")) {
      cache.invalidate({ __typename: "User", id: args.id })
      invalidateUsersQueries(cache)
    }
  },
}
