import _get from "lodash-es/get"

const mutation = `
  mutation pipeDelete($id: String!, $orgId: String) {
    my(orgId: $orgId) {
      pipeDelete(id: $id) {
        errors
      }
    }
  }
`
export default {
  name: "my.pipeDelete",
  async mutate(pipe, { orgId } = {}) {
    const results = await this.mutation(mutation, {
      id: pipe.id,
      orgId,
    }).toPromise()
    const { errors } = results.data.my.pipeDelete
    if (errors && errors.length) throw errors
  },
  update(result, _args, cache, info) {
    const args = info.variables
    if (!_get(result, "my.pipeDelete.errors.length")) {
      const id =
        cache.resolve(
          cache.resolve({ __typename: "Pipe", id: args.id }, "listener"),
          "id"
        ) || ""
      if (id.startsWith("p_")) {
        const triggers = cache.resolve(
          { __typename: "Pipeline", id },
          "triggers"
        )
        if (triggers && triggers.length) {
          const toDelete = triggers.findIndex(
            t => cache.resolve(t, "id") == args.id
          )
          if (toDelete >= 0) {
            triggers.splice(toDelete, 1)
            cache.link({ __typename: "Pipeline", id }, "triggers", triggers)
          }
        }
      }
    }
  },
}
