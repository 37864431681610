import { gql } from "@urql/core"

export default gql`
  query viewerLiveAuthProvisionLinks {
    viewer {
      id
      liveAuthProvisionLinks {
        authProvisionId
        pipelineIds
      }
    }
  }
`
