import * as fragments from "@graphql/fragments"

const mutation = `
  mutation timerInterfaceUpdate(
    $id: String!
    $cron: String
    $intervalSeconds: Int
    $orgId: String
    $timezone: String
  ) {
    my(orgId: $orgId) {
      timerInterfaceUpdate(
        id: $id
        cron: $cron
        intervalSeconds: $intervalSeconds
        timezone: $timezone
      ) {
        timerInterface {
          ...WorkflowTimerInterfaceParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.timerInterface}
`
export default async function timerInterfaceUpdate(
  id,
  { cron, intervalSeconds, timezone },
  { orgId } = {}
) {
  const results = await this.mutation(mutation, {
    id,
    cron,
    intervalSeconds,
    orgId,
    timezone,
  }).toPromise()
  const { errors, timerInterface } = results.data.my.timerInterfaceUpdate
  if (errors && errors.length) throw errors
  if (!timerInterface) throw "Expected timerInterface!"
  return timerInterface
}
