// esm friendly replacement for https://www.npmjs.com/package/safe-identifier

const reserved = {
  ES3: {
    break: true,
    continue: true,
    delete: true,
    else: true,
    for: true,
    function: true,
    if: true,
    in: true,
    new: true,
    return: true,
    this: true,
    typeof: true,
    var: true,
    void: true,
    while: true,
    with: true,
    case: true,
    catch: true,
    default: true,
    do: true,
    finally: true,
    instanceof: true,
    switch: true,
    throw: true,
    try: true,
  },
  ESnext: {
    // in addition to reservedES3
    await: true,
    debugger: true,
    class: true,
    enum: true,
    extends: true,
    super: true,
    const: true,
    export: true,
    import: true,
    null: true,
    true: true,
    false: true,
    implements: true,
    let: true,
    private: true,
    public: true,
    yield: true,
    interface: true,
    package: true,
    protected: true,
    static: true,
  },
}

// from https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
function hashCode(str) {
  let hash = 0
  for (let i = 0; i < str.length; ++i) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export function identifier(key, unique) {
  if (unique) key += " " + hashCode(key).toString(36)
  const id = key.trim().replace(/\W+/g, "_")
  return reserved.ES3[id] || reserved.ESnext[id] || /^\d/.test(id)
    ? "_" + id
    : id
}
