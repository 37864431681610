import {
  API_BASE_URL,
  CLIENT_BASE_URL,
  OAUTH_GITHUB_CLIENT_ID,
  OAUTH_GOOGLE_CLIENT_ID,
} from "@/src/config"
import { randomString } from "@/src/utils/lang"

const providers = {
  github: {
    clientId: OAUTH_GITHUB_CLIENT_ID,
    redirectUri: `${CLIENT_BASE_URL}/auth/github`,
    authorizationEndpoint: "https://github.com/login/oauth/authorize",
    scopes: ["user:email"],
    popupOptions: "width=1020, height=618",
  },
  google: {
    clientId: OAUTH_GOOGLE_CLIENT_ID,
    redirectUri: `${CLIENT_BASE_URL}/auth/google`,
    authorizationEndpoint: "https://accounts.google.com/o/oauth2/auth",
    scopes: ["openid", "profile", "email"],
    params: {
      display: "popup",
      prompt: "select_account",
    },
    popupOptions: "width=452, height=633",
  },
}

export function providerAuthUrl(providerName) {
  const provider = providers[providerName]
  if (!provider) throw new Error(`unsupported auth provider: ${providerName}`)

  const state = randomString(12)
  let url = provider.authorizationEndpoint
  url += "?response_type=code"
  url += `&state=${state}`
  url += `&client_id=${provider.clientId}`
  url += `&scope=${provider.scopes.join("%20")}`
  url += `&redirect_uri=${encodeURIComponent(provider.redirectUri)}`
  for (const key in provider.params || {}) {
    const value = provider.params[key]
    url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  }
  return { url, state }
}

export function oauthCallbackInProgress() {
  return (
    typeof sessionStorage !== "undefined" &&
    !!sessionStorage.getItem("pd:oauth-state")
  )
}

const AUTH_METADATA_LOCAL_STORAGE_KEY = "pd:auth-metadata"
let metadata = {}
export function storeAuthMetadata(o) {
  Object.assign(metadata, o)
  // we need to store this in sessionStorage to persist oauth redirect
  // clear it after making **successful** authCall, because will have been sent to new user workflow
  if (!sessionStorage) return
  sessionStorage.setItem(
    AUTH_METADATA_LOCAL_STORAGE_KEY,
    JSON.stringify(metadata)
  )
}

// merge what's in memory with what we've saved in storage
export function getAuthMetadata() {
  let ret = {}
  try {
    const json = sessionStorage.getItem(AUTH_METADATA_LOCAL_STORAGE_KEY)
    if (json) ret = JSON.parse(json)
  } catch (err) {
    // do nothing
  }
  Object.assign(ret, metadata)
  return ret
}

export function clearAuthMetadata() {
  metadata = {}
  if (sessionStorage) {
    sessionStorage.removeItem(AUTH_METADATA_LOCAL_STORAGE_KEY)
  }
}

export async function oauthProviderComplete({
  providerName,
  code,
  me,
  $analytics,
}) {
  const provider = providers[providerName]
  if (!provider) return `unsupported auth provider: ${providerName}`

  $analytics.track(`${providerName} oauth completed`)

  return await me.signIn(
    providerName,
    {
      code,
      redirectUri: provider.redirectUri,
    },
    {
      replaceRoute: true,
    }
  )
}

export function getPostAuth() {
  let d
  try {
    d = JSON.parse(sessionStorage.getItem("pd:postauth"))
  } catch (err) {
    // do nothing
  }
  return d || {}
}

export function postAuthSave(postAuthData) {
  // fix Converting circular structure to JSON
  if (
    postAuthData &&
    postAuthData.route &&
    typeof postAuthData.route === "object"
  ) {
    postAuthData.route = { ...postAuthData.route }
    delete postAuthData.route.matched
  }
  const json = JSON.stringify(postAuthData)
  sessionStorage && sessionStorage.setItem("pd:postauth", json)
}

export function handlePostAuth({ $router, replaceRoute = false }) {
  const d = getPostAuth()
  sessionStorage && sessionStorage.removeItem("pd:postauth")
  if (d.route) {
    $router[replaceRoute ? "replace" : "push"](d.route)
  } else if (d.discourse_sso) {
    window.location.href = `${API_BASE_URL}/discourse_sso?${d.discourse_sso}`
  } else {
    // XXX go straight to workflows instead of going to / and letting redirectWorkflowsIfSignedIn
    // ... handle BECAUSE google one tap from home screen will not trigger the beforeEnter (since already on route)
    $router[replaceRoute ? "replace" : "push"]({ name: "workflows" })
  }
}
