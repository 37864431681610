import deployedComponent from "./workflow/deployedComponent.gql"
import deployment from "./workflow/deployment.gql"
import httpInterface from "./workflow/httpInterface.gql"
import timerInterface from "./workflow/timerInterface.gql"
import pipeline from "./workflow/pipeline.gql"
import savedComponent from "./workflow/savedComponent.gql"
import pipe from "./workflow/pipe.gql"

export default {
  deployedComponent,
  deployment,
  httpInterface,
  pipe,
  pipeline,
  savedComponent,
  timerInterface,
}
