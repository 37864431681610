import _get from "lodash-es/get"
import _isFinite from "lodash-es/isFinite"
import _toNumber from "lodash-es/toNumber"
import { identifier } from "safe-identifier"

export function split(nameSlug) {
  const re = /^(?<root>.+)_(?<suffix>\d+)$/
  const { root, suffix } = _get(nameSlug.match(re), "groups", {
    root: nameSlug,
    suffix: "0",
  })
  const _suffix = _toNumber(suffix)
  if (_isFinite(_suffix)) {
    return { root, suffix: _suffix }
  }
  return { root, suffix: 0 }
}

export function nextNameSlug(requested, reserved = []) {
  const _requested = split(requested)
  let maxSuffix = _requested.suffix
  for (const r of reserved) {
    const _reserved = split(r)
    if (_reserved.root == _requested.root) {
      maxSuffix = Math.max(maxSuffix, _reserved.suffix)
    }
  }
  return `${_requested.root}_${maxSuffix + 1}`
}
export function canonizeNameSlug(nameSlug, reserved = []) {
  const _nameSlug = identifier(String(nameSlug))
  if (reserved.includes(_nameSlug)) {
    return nextNameSlug(_nameSlug, reserved)
  }
  return _nameSlug
}
