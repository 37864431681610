import _debounce from "lodash-es/debounce"
import _get from "lodash-es/get"
import _isArray from "lodash-es/isArray"
import _isObjectLike from "lodash-es/isObjectLike"
import _kebabCase from "lodash-es/kebabCase"

let allyModulesPromise
export class Focus {
  static init() {
    if (!Focus.initialized) {
      allyModulesPromise = Promise.all([
        import("ally.js/src/element/focus"),
        import("ally.js/src/query/first-tabbable"),
        import("ally.js/src/when/focusable"),
      ])
      Focus.initialized = true
      Focus.$store.watch(
        state => state.focus.current,
        Focus.onFocusChangedDebounced
      )
      Focus.listeners = {}
      document.addEventListener("focusin", Focus.blur, { passive: true })
    }
  }

  static blur() {
    Focus.setFocus(null)
  }

  static checkFocus() {
    const current = Focus.current
    if (current) {
      Focus.onFocusChangedDebounced(current)
    }
  }

  static get checkFocusDebounced() {
    return _debounce(Focus.checkFocus, 100, {
      leading: true,
      trailing: false,
    })
  }

  static get current() {
    return _get(Focus, "$store.state.focus.current")
  }

  static get DATA() {
    return "vFocusId"
  }

  static get nextId() {
    if (!Focus._nextId) Focus._nextId = 1
    return Focus._nextId++
  }

  static keysFrom(v) {
    let keys = []
    if (_isArray(v)) {
      keys = v
    } else if (_isObjectLike(v)) {
      keys = Object.keys(v).filter(k => v[k])
    } else {
      keys.push(v)
    }
    return keys
  }

  static onFocusChanged(nv, ov) {
    if (nv == ov) return
    const listener = _get(Focus.listeners || {}, nv)
    if (!listener) return
    const selector = `[data-${_kebabCase(Focus.DATA)}="${listener.id}"]`
    const listenerEl = document.querySelector(selector)
    if (!listenerEl) return
    ;(async () => {
      const modules = await allyModulesPromise
      const elementFocus = modules[0].default
      const queryFirstTabbable = modules[1].default
      const whenFocusable = modules[2].default
      const focusOn = queryFirstTabbable({
        context: selector,
        defaultToContext: true,
        includeOnlyTabbable: false,
        strategy: "strict",
      })
      if (focusOn) {
        elementFocus(focusOn)
        if (document.activeElement == focusOn) {
          Focus.blur()
        } else {
          whenFocusable({
            context: focusOn,
            callback: el => {
              elementFocus(el)
            },
          })
        }
      }
    })()
  }

  static get onFocusChangedDebounced() {
    return _debounce(Focus.onFocusChanged, 500, {
      leading: true,
      trailing: false,
    })
  }

  static onUpdate(el, binding) {
    Focus.watch(el, binding)
    Focus.checkFocus()
  }

  static setFocus(key) {
    if (Focus.initialized) {
      Focus.$store.dispatch("setFocus", key)
    }
  }

  static watch(el, binding) {
    if (!el.dataset[Focus.DATA]) {
      el.dataset[Focus.DATA] = Focus.nextId
    }
    if (binding.value != binding.oldValue) {
      Focus.unwatch(binding)
    }
    for (const key of Focus.keysFrom(binding.value)) {
      Focus.listeners[key] = { key, id: el.dataset[Focus.DATA] }
    }
  }

  static unwatch(binding) {
    for (const key of Focus.keysFrom(binding.value)) {
      delete Focus.listeners[key]
    }
    for (const oldKey of Focus.keysFrom(binding.oldValue)) {
      delete Focus.listeners[oldKey]
    }
  }
}

export function setFocus(key) {
  return Focus.setFocus(key)
}

export const FocusDirective = {
  name: "Focus",
  beforeMount(el, binding) {
    Focus.init()
    Focus.onUpdate(el, binding)
  },
  mounted(el, binding) {
    Focus.onUpdate(el, binding)
  },
  updated(el, binding) {
    Focus.onUpdate(el, binding)
  },
  unmounted(_, binding) {
    Focus.unwatch(binding)
  },
}
