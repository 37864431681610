import { gql } from "@urql/core"
import { action } from "@graphql/fragments"

export default gql`
  query adminActions(
    $after: String
    $q: String
    $adminOnly: Boolean
    $appId: String
    $exceptIds: [String!]
    $hidden: Boolean
    $status: ActionStatusEnum
    $verified: Boolean
    $orderBy: [ActionOrderByEnum!]
  ) {
    admin {
      actions(
        first: 20
        after: $after
        q: $q
        adminOnly: $adminOnly
        appId: $appId
        exceptIds: $exceptIds
        hidden: $hidden
        status: $status
        verified: $verified
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...ActionParts
        }
      }
    }
  }
  ${action}
`
