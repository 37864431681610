import { gql } from "@urql/core"
import { pipeline, pipelineWiths } from "@graphql/fragments"

export default gql`
  query pipeline(
    $id: String!
    ${pipelineWiths.fragmentPart}
  ) {
    pipeline(id: $id) {
      _can
      ...PipelineParts
    }
  }
  ${pipeline}
`
