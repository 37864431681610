import _find from "lodash-es/find"

export class Validation {
  static each(validate, arr, path = []) {
    let errors = []
    for (let key in arr) {
      const validateErrors = validate(arr[key], [...path, key])
      if (validateErrors) errors = [...errors, ...validateErrors]
    }
    return errors
  }
  static errorByPath(errors, path) {
    return _find(errors, error => error.path.join(".") == path)
  }
  static hasDuplicates(keys) {
    const found = {}
    for (const index in keys) {
      const key = keys[index]
      if (found[key]) {
        return {
          a: found[key].index,
          b: index,
          key,
        }
      } else {
        found[key] = { index }
      }
    }
  }
  // Validations = { <key>: Validator | [Validator], ... }
  // returns array of errors in form { message, path } where path is array
  static validate(validations, v, path = []) {
    let errors = []
    for (const key in validations) {
      let validators = validations[key]
      if (!Array.isArray(validators)) validators = [validators]
      for (const validator of validators) {
        const fullPath = [...path, key]
        let _errors = validator(v[key], fullPath, v) // pass parent object in as well
        if (!Array.isArray(_errors)) _errors = [_errors]
        for (const _error of _errors) {
          if (!_error) continue
          const error =
            typeof _error === "object" ? { ..._error } : { message: _error }
          if (!error.path) error.path = fullPath
          errors.push(error)
        }
      }
    }
    return errors
  }
}
