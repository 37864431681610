import { canonizeNameSlug } from "@/src/utils/nameSlug"
import _get from "lodash-es/get"

function prepare(stepAppInput, reservedNameSlugs = []) {
  const _stepAppInput = {
    appId: stepAppInput.appId || _get(stepAppInput, "app.id"),
    authProvisionId:
      stepAppInput.authProvisionId || _get(stepAppInput, "authProvision.id"),
    nameSlug: stepAppInput.nameSlug || _get(stepAppInput, "app.nameSlug"),
  }
  _stepAppInput.nameSlug = canonizeNameSlug(
    stepAppInput.nameSlug,
    reservedNameSlugs
  )
  return _stepAppInput
}

function prepareAll(stepAppInputs, reservedNameSlugs = []) {
  const _reserved = [...reservedNameSlugs]
  const _stepAppInputs = []
  for (const stepAppInput of stepAppInputs) {
    const _stepAppInput = prepare(stepAppInput, _reserved)
    _stepAppInputs.push(_stepAppInput)
    _reserved.push(_stepAppInput.nameSlug)
  }
  return _stepAppInputs
}

export default { prepare, prepareAll }
