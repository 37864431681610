import _get from "lodash-es/get"
export default {
  name: "pipelineRemoveCollaborator",
  async mutate(pipelineId, key) {
    // TODO(apollo) cache update
    const result = await this.mutation(
      `
    mutation deletePipelineCollaborator($pipelineId: String!, $key: String!) {
      pipelineRemoveCollaborator(pipelineId: $pipelineId, key: $key) {
        errors
      }
    }
  `,
      {
        pipelineId,
        key,
      }
    ).toPromise()
    const { errors } = result.data.pipelineRemoveCollaborator
    if (errors && errors.length) throw errors
  },
  update(result, args, cache /* , info */) {
    if (
      !result.error &&
      !_get(result, "pipelineRemoveCollaborator.errors.length")
    ) {
      cache.invalidate(
        { __typename: "Pipeline", id: args.pipelineId },
        "collaborators"
      )
    }
  },
}
