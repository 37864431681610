import { gql } from "@urql/core"
import _get from "lodash-es/get"
import { invalidateOrgQueries } from "../orgCreate"

export default {
  async mutate(orgId, orgData) {
    const resp = await this.mutation(
      gql`
        mutation adminOrgUpdate($id: String, $org: OrgInput!) {
          adminOrgUpdate(id: $id, org: $org) {
            org {
              id
              name
              nickname
              email
              extraDailyInvocations
              lambdaTimeQuota
            }
            errors
          }
        }
      `,
      {
        id: orgId,
        org: {
          ...orgData,
        },
      }
    ).toPromise()
    if (resp.error) throw resp.error
    const { org, errors } = resp.data.adminOrgUpdate
    if (errors && errors.length) throw errors
    return org
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "adminOrgUpdate.errors.length")) {
      const id = _get(result, "adminOrgUpdate.org.id")
      if (id) {
        invalidateOrgQueries(cache)
      }
    }
  },
}
