import { gql } from "@urql/core"
import { app, appWiths } from "@graphql/fragments"

export default gql`
  query appConnections(
    $first: Int = 20
    $after: String
    $q: String
    $adminOnly: Boolean
    $orderBy: OrderByAppInput
    ${appWiths.fragmentPart}
  ) {
    appConnections(
      first: $first
      after: $after
      q: $q
      adminOnly: $adminOnly
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ...AppParts
      }
    }
  }
  ${app}
`
