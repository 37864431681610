import { inject } from "vue"

// XXX maybe there is no point in updating many of the meta tags dynamically when in client
// XXX head[lang] ?
// TODO(after this is out) delete api oembed stuff

const HEAD = Symbol("HEAD")
const DEFAULT_TITLE = "Pipedream - Connect APIs, Remarkably Fast"
const DEFAULT_DESCRIPTION =
  "Pipedream is a low code integration platform for developers that allows you to connect APIs remarkably fast. Stop writing boilerplate code, struggling with authentication and managing infrastructure. Start connecting APIs with code-level control when you need it — and no code when you don't. Join 100,000+ developers using the Pipedream platform today. Get started for free."
const DEFAULT_IMAGE_URL =
  "https://res.cloudinary.com/pipedreamin/image/upload/v1558637647/oembed/default-image-url.png"

// while SSR collect everything so we can render out the parts at the end
export function createHead() {
  const head = {
    title: null,
    metasByKey: {}, // key -> attrs
  }

  head.install = app => {
    app.config.globalProperties.$head = head
    app.provide(HEAD, head)
  }

  head.set = config => {
    if (config.title) head.setTitle(config.title)
    if (config.description) head.setDescription(config.description)
    if (config.imageUrl) head.setImageUrl(config.imageUrl)
    for (const meta of config.metas || []) head.setMeta(meta)
    if (config.robots) head.setMeta({ name: "robots", content: config.robots }) // shortcut
  }

  head.reset = () => {
    head.setTitle()
    head.setDescription()
    head.setImageUrl()
    head.delMeta("robots")
  }

  head.setTitle = _title => {
    if (_title === null) return
    const title = _title ? `${_title} - Pipedream` : DEFAULT_TITLE
    head.title = title
    head.setMeta({ name: "title", content: title })
    head.setMeta({ property: "og:title", content: title })
    head.setMeta({ name: "twitter:title", content: title })
    if (import.meta.env.SSR) return
    document.title = title
  }

  head.setDescription = _description => {
    if (_description === null) return
    const description = _description || DEFAULT_DESCRIPTION
    head.setMeta({ name: "description", content: description })
    head.setMeta({ property: "og:description", content: description })
    head.setMeta({ name: "twitter:description", content: description })
  }

  head.setImageUrl = _imageUrl => {
    if (_imageUrl === null) return
    const imageUrl = _imageUrl || DEFAULT_IMAGE_URL
    head.setMeta({ property: "og:image", content: imageUrl })
    head.setMeta({ name: "twitter:image:src", content: imageUrl })
  }

  let domMetasByKey = {}
  head.setMeta = attrs => {
    const keyName = attrs.name ? "name" : "property" // XXX anything else? (and assumes there are no dupes)
    const key = attrs[keyName]
    head.metasByKey[key] = attrs
    if (import.meta.env.SSR) return
    let meta = domMetasByKey[key]
    if (!meta) {
      meta = document.querySelector(`meta[${keyName}="${key}"]`)
      if (!meta) {
        meta = document.createElement("meta")
        document.head.appendChild(meta)
      }
      domMetasByKey[key] = meta
    }
    // XXX doesn't delete previous attributes that are not passed in
    for (const k in attrs) {
      meta.setAttribute(k, attrs[k])
    }
  }

  head.delMeta = key => {
    delete head.metasByKey[key]
    if (import.meta.env.SSR) return
    const meta = head.metasByKey[key]
    if (meta) document.head.removeChild(meta)
  }

  // XXX could just use head.{description,title} during ssrRender and not do all the setMeta calls but w.e
  head.ssrRender = () => {
    // XXX any escaping to do?
    let headTagsHtml = ""
    for (const key in head.metasByKey) {
      const attrs = head.metasByKey[key]
      let html = "<meta"
      for (const k in attrs) {
        html += ` ${k}="${attrs[k]}"`
      }
      html += ` />`
      headTagsHtml += html
    }
    return {
      title: head.title, // <!--head-title-->
      headTagsHtml: headTagsHtml, // <!--head-tags-->
    }
  }

  return head
}

export function useHead() {
  const head = inject(HEAD)
  if (!head) throw new Error("must app.use(head) before useHead")
  return head
}
