import loadScript from "@/src/utils/loadScript"

export async function sendEvent(...args) {
  try {
    if (!window.pdsdk) {
      await loadScript("https://sdk.pipedream.net/pdsdk.min.js")
    }
    window.pdsdk.sendEvent(...args)
  } catch (err) {
    // do nothing
  }
}
