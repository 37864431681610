import { gql } from "@urql/core"
import { action, pipeline, pipelineWiths } from "@graphql/fragments"

export default gql`
  query profile(
    $nickname: String!
    $afterAction: String
    $afterPipeline: String
    $afterOrg: String
    ${pipelineWiths.fragmentPart}
  ) {
    profile(nickname: $nickname) {
      user {
        _can
        id
        nickname
        pipelineConnections(first: 20, after: $afterPipeline) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...PipelineParts
          }
        }
        actionConnections(first: 20, after: $afterAction) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...ActionParts
          }
        }
        orgConnections(first: 20, after: $afterOrg) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            nickname
          }
        }
      }
      org {
        _can
        id
        name
        nickname
        email
        pipelineConnections(first: 20, after: $afterPipeline) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...PipelineParts
          }
        }
        orgUserConnections(first: 20, after: $afterOrg) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            nickname
            admin
          }
        }
      }
    }
  }
  ${action}
  ${pipeline}
`
