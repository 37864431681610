import { gql } from "@urql/core"
import _get from "lodash-es/get"

export default {
  name: "my.deleteEnvironmentVariable",
  async mutate({ orgId, id }) {
    const mutation = gql`
      mutation deleteEnvironmentVariable($orgId: String, $id: String!) {
        my(orgId: $orgId) {
          deleteEnvironmentVariable(id: $id) {
            errors
          }
        }
      }
    `
    const variables = {
      orgId,
      id,
    }
    const resp = await this.mutation(mutation, variables).toPromise()
    const { errors } = resp.data.my.deleteEnvironmentVariable
    if (errors && errors.length) throw errors
  },
  update(result, args, cache, info) {
    if (
      !result.error &&
      !_get(result, "my.deleteEnvironmentVariable.errors.length")
    ) {
      // XXX nested updater args is empty so use info.variables for now
      cache.invalidate({
        __typename: "RedactedEnvironmentVariable",
        id: info.variables.id,
      })
    }
  },
}
