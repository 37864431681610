import * as fragments from "@graphql/fragments"

const mutation = `
  mutation httpInterfaceUpdate(
    $id: String!
    $customResponse: Boolean
    $generateNewKey: Boolean
    $orgId: String
  ) {
    my(orgId: $orgId) {
      httpInterfaceUpdate(
        id: $id
        customResponse: $customResponse
        generateNewKey: $generateNewKey
      ) {
        httpInterface {
          ...WorkflowHttpInterfaceParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.httpInterface}
`
export default async function httpInterfaceUpdate(
  id,
  { customResponse, generateNewKey },
  { orgId } = {}
) {
  const results = await this.mutation(mutation, {
    customResponse,
    generateNewKey,
    orgId,
  }).toPromise()
  const { errors, httpInterface } = results.data.my.httpInterfaceUpdate
  if (errors && errors.length) throw errors
  if (!httpInterface) throw "Expected httpInterface!"
  return httpInterface
}
