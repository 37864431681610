import _get from "lodash-es/get"
import _debounce from "lodash-es/debounce"
import _find from "lodash-es/find"
import _isEqual from "lodash-es/isEqual"
import _kebabCase from "lodash-es/kebabCase"

export const EVENTS = ["click", "focus", "focusin", "input", "change"]
export const loaded = {}

let $store = null
export function docRegisterStore(store) {
  $store = store
}

export class DocContext {
  static get nextId() {
    if (!this._nextId) this._nextId = 1
    return this._nextId++
  }
  static canonize(key) {
    if (!key) return ""
    return key
      .split("/")
      .map(_kebabCase)
      .join("/")
  }

  static delete(el) {
    $store.dispatch("deleteDocContextData", {
      id: el.dataset.docContextId,
    })
  }

  static load(el) {
    if (el && el.isConnected) {
      const contextEl = el.closest("[data-doc-context]")
      if (contextEl) {
        const id = _get(contextEl, "dataset.docContextId")
        const context = {
          id,
          ids: [id],
          key: contextEl.dataset.docContext,
          ignore: _get(contextEl, "__docContextIgnore"),
          root: _get(contextEl, "__docContextRoot"),
        }
        const parentContext = DocContext.load(contextEl.parentElement)
        if (parentContext) {
          return DocContext.merge(parentContext, context)
        }
        return context
      }
    }
  }

  static store(el, { key, data, ignore = false, root = false }) {
    if (!el.dataset.docContextId) {
      el.dataset.docContextId = DocContext.nextId
    }
    el.dataset.docContext = DocContext.canonize(key)
    el.__docContextIgnore = ignore
    el.__docContextRoot = root
    if (data != _get($store, `state.dataById[${el.dataset.docContextId}]`)) {
      $store.dispatch("setDocContextData", {
        id: el.dataset.docContextId,
        data,
      })
    }
  }

  static merge(parent, child) {
    return {
      id: child.id,
      ids: parent.ids.concat(child.ids),
      key: [parent.key, child.key].filter(x => x).join("/"),
      ignore: parent.ignore || child.ignore,
      root: parent.root,
    }
  }
}

export function addEventListeners(el, { modifiers } /*, vnode */) {
  if (!modifiers.ignore) {
    for (const event of EVENTS) {
      el.addEventListener(event, setContextFromEventDebounced, {
        passive: true,
      })
    }
  }
}

export function removeEventListeners(el, { modifiers } /*, vnode */) {
  if (!modifiers.ignore) {
    for (const event of EVENTS) {
      el.removeEventListener(event, setContextFromEventDebounced)
    }
  }
}

export function setContextFromEvent(event) {
  // Note: We do this in order to correctly set context when clicking on embedded
  // objects (like SVGs) that are detached from the DOM
  const target = _find(event.path, e => e.isConnected)
  if (target) {
    setCurrentContextHere(target)
  }
}

export function setCurrentContextHere(el) {
  const context = DocContext.load(el)
  if ($store && context && context.key && !context.ignore && context.root) {
    $store.dispatch("setDocContext", context)
    return context
  }
}

export const setContextFromEventDebounced = _debounce(
  setContextFromEvent,
  200,
  {
    leading: true,
    trailing: false,
  }
)

export function onUpdate(el, binding /*, vnode */) {
  if (binding.oldValue && _isEqual(binding.oldValue, binding.value)) return
  DocContext.store(el, {
    key: DocContext.canonize(binding.arg),
    data: binding.value,
    ignore: binding.modifiers.ignore,
    root: binding.modifiers.root,
  })
}

export default {
  name: "DocContext",
  mounted(el, binding, vnode) {
    onUpdate(el, binding, vnode)
    if (binding.modifiers.listen) {
      addEventListeners(el, binding, vnode)
    }
  },
  unmounted(el, binding, vnode) {
    DocContext.delete(el)
    if (binding.modifiers.listen) {
      removeEventListeners(el, binding, vnode)
    }
  },
  updated(el, binding, vnode) {
    onUpdate(el, binding, vnode)
  },
}
