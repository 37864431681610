import action from "./fragments/action.gql"
import adminUser from "./fragments/adminUser.gql"
import app from "./fragments/app.gql"
import appExtra from "./fragments/appExtra.gql"
import authProvision from "./fragments/authProvision.gql"
import deployedComponent from "./fragments/deployedComponent.gql"
import eventStream from "./fragments/eventStream.gql"
import oauthReq from "./fragments/oauthReq.gql"
import orgUser from "./fragments/orgUser.gql"
import pipeline from "./fragments/pipeline.gql"
import savedComponent from "./fragments/savedComponent.gql"
import source from "./fragments/source.gql"
import workflow from "./fragments/workflow"

import _appWiths from "./fragments/appWiths"
import _authProvisionWiths from "./fragments/authProvisionWiths"
import _deployedComponentWiths from "./fragments/deployedComponentWiths"
import _pipelineWiths from "./fragments/pipelineWiths"

// withs is an array of withVariables
function augmentWiths(withs) {
  const ret = {
    fragmentPart: "\n", // meant to be interpolated in fragment
    variables: {}, // used as supsert of vars (ie in fragmentUpdate)
  }
  for (const name of withs) {
    ret.variables[name] = false
  }
  for (const name of withs) {
    ret.fragmentPart += `  $${name}: Boolean = false\n`
  }
  return ret
}

const appWiths = augmentWiths(_appWiths)
const authProvisionWiths = augmentWiths(_authProvisionWiths)
const deployedComponentWiths = augmentWiths(_deployedComponentWiths)
const pipelineWiths = augmentWiths(_pipelineWiths)

export {
  action,
  adminUser,
  app,
  appExtra,
  authProvision,
  deployedComponent,
  eventStream,
  oauthReq,
  orgUser,
  pipeline,
  savedComponent,
  source,
  workflow,
  appWiths,
  authProvisionWiths,
  deployedComponentWiths,
  pipelineWiths,
}
