import { gql } from "@urql/core"

export default gql`
  query exploreClientAppsQuery {
    apps {
      id
      name
      nameSlug
      description
      adminOnly
    }
  }
`
