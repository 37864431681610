import { gql } from "@urql/core"
import _get from "lodash-es/get"

export default {
  async mutate(orgId, orgUserId) {
    const resp = await this.mutation(
      gql`
        mutation orgRemoveUser($orgId: String!, $orgUserId: String!) {
          orgRemoveUser(orgId: $orgId, id: $orgUserId) {
            errors
          }
        }
      `,
      {
        orgId,
        orgUserId,
      }
    ).toPromise()
    const { errors } = resp.data.orgRemoveUser
    if (errors && errors.length) throw errors
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "orgRemoveUser.errors.length")) {
      const id = args.id
      if (id) {
        cache.invalidate({ __typename: "OrgUser", id })
      }
    }
  },
}
