const withs = [
  "withDbs",
  "withConfiguredProps",
  "withRuntimeProps",
  "withSavedComponent",
  "withAppIds",
  "withDynamicProps",
]

export default withs
