import _cloneDeep from "lodash-es/cloneDeep"

export const FEATURES = {
  rr_debug: {
    title:
      "Debug Mode for Right Rail contextual docs. Prints messages in console when the doc context changes",
    type: "checkbox",
  },
  custom_lambdamaker: {
    title: "Advanced: use a custom lambda maker suffix (e.g. your whoami)",
    type: "text",
  },
  python: {
    title: "Python code cells",
    type: "checkbox",
  },
  show_search_scores: {
    title: "Display score in search results",
    type: "checkbox",
  },
  component_namespace: {
    title:
      "Enable selection of your own published components when creating a source",
    type: "checkbox",
  },
  onboarding_always: {
    title: "Always show onboarding checklist",
    type: "checkbox",
  },
  // new_explore: {
  //   title: "New explore",
  //   type: "checkbox",
  // },
  // connect_account_new: {
  //   title: "connect_account_new (only in workflow cells for now)",
  //   type: "checkbox",
  // },
  incremental: {
    title: "Enable incremental workflow view",
    type: "checkbox",
  },
  // teams: {
  //   title: "Enable organizations",
  //   type: "checkbox",
  //   public: true,
  // },
  // paid_organizations: {
  //   title: "Enable Paid Organizations",
  //   type: "checkbox",
  // },
  // enable_canary_redirect: {
  //   title:
  //     "ENABLE Canary Redirect (admin only) -- disable via ?disable_canary_redirect=1",
  //   type: "checkbox",
  // },
}

const feature_flags_key = "pd_features"

export function createFeature(hybridStorage) {
  return {
    FEATURES() {
      return _cloneDeep(FEATURES)
    },
    get flags() {
      let fromStorage = {}
      const _fromStorage = hybridStorage[feature_flags_key] || { v: 1 }
      if (_fromStorage.v) {
        fromStorage = _fromStorage
      } else {
        // upgrade from v0 array version
        for (const flag of _fromStorage) fromStorage[flag] = true
      }

      return Object.keys(FEATURES).reduce((flags, flag) => {
        flags[flag] = fromStorage[flag]
        return flags
      }, {})
    },
    save(flags = {}) {
      const toStorage = Object.keys(flags).reduce((toStorage, flag) => {
        if (FEATURES[flag]) toStorage[flag] = flags[flag]
        return toStorage
      }, {})
      if (Object.keys(toStorage).length) {
        toStorage.v = 1
        hybridStorage[feature_flags_key] = toStorage
      } else {
        delete hybridStorage[feature_flags_key]
      }
    },
  }
}
