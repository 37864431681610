import { reactive, watch } from "vue"
import { parse } from "cookie-esm"
import { atob, btoa } from "@utils/base64"
import { v4 as uuidv4 } from "uuid"

const isServer = import.meta.env.SSR
const HYBRID_STORAGE_KEY = "pdhs"

// [HYBRID_STORAGE_KEY] = base64encode(json(state))

export function createHybridStorage(cookieHeader) {
  // make these smaller since in cookie :/
  const oldStorageKeys = [
    "pd_snippets_state",
    "drawer.closed",
    "pd_notifications",
    "leftnav.closed",
    "pd_features",
    "appcue-workflow-tutorial-seen",
  ]

  let parsedCookie
  if (isServer) {
    parsedCookie = parse(cookieHeader || "")
  } else {
    parsedCookie = parse(document.cookie)
  }

  // we make this reactive for things like $settings to be automatically reactive
  let state = reactive({})
  let returning = false

  const hybridStorageCookie = parsedCookie[HYBRID_STORAGE_KEY]
  if (hybridStorageCookie) {
    returning = true
    try {
      state = reactive(JSON.parse(atob(hybridStorageCookie)))
      // console.log("[debug] hybridStorage state from cookie", state)
    } catch (err) {
      // XXX sentry -- this should not really happen, maybe doctored?
    }
  } else {
    // only if cookie is not found should we try to migrate from localStorage
    if (typeof localStorage !== "undefined") {
      const pd_state_json = localStorage.getItem("pd_state")
      if (pd_state_json) {
        returning = true
        const pd_state = JSON.parse(pd_state_json)
        state.username = pd_state.username
      }
      // settings
      const settings_json = localStorage.getItem("settings")
      if (settings_json) {
        returning = true
        const s = JSON.parse(settings_json) // XXX try/catch
        state.settings = s
      } else {
        // try loading old method
        const pd_app_settings_json = localStorage.getItem("pd_app_settings")
        if (pd_app_settings_json) {
          returning = true
          if (!state.settings) state.settings = {}
          const s = JSON.parse(pd_app_settings_json)
          // XXX delete if different from default to keep smaller
          // ... the setter in settings.js should be smarter
          // and delete... but inside of settings object
          state.settings.app_clockFormat = s.clockFormat
        }
        const pd_editor_settings_json = localStorage.getItem(
          "pd_editor_settings"
        )
        if (pd_editor_settings_json) {
          returning = true
          if (!state.settings) state.settings = {}
          const s = JSON.parse(pd_editor_settings_json)
          state.settings.editor_tabSize = s.tabSize
          state.settings.editor_vimMode = s.vimMode
          state.settings.editor_wordWrap = s.wordWrap
        }
      }
      for (const key of oldStorageKeys) {
        const strValue = localStorage.getItem(key)
        if (strValue) {
          returning = true
          state[key] = JSON.parse(strValue)
        }
      }
      // console.log("[debug] hybridStorage state from localStorage", state)
    }
  }

  if (parsedCookie.godmode) {
    state.godmode = parsedCookie.godmode
  }

  if (parsedCookie._pd_session) {
    returning = true
  }
  state.returning = returning

  if (!state.username && !state.anonymousId) {
    if (parsedCookie.ajs_anonymous_id) {
      state.anonymousId = parsedCookie.ajs_anonymous_id.replace(/"/g, "")
    } else {
      // since we don't use this unless we are on the client-side
      // and we don't currently set cookies from the server, only do on client-side
      if (typeof window !== "undefined") {
        state.anonymousId = uuidv4()
        if (window.analytics) {
          window.analytics.setAnonymousId(state.anonymousId)
        }
      }
    }
  }

  function persistState(v) {
    if (typeof document !== "undefined") {
      document.cookie = `${HYBRID_STORAGE_KEY}=${btoa(
        JSON.stringify(v)
      ).replace(/=*$/, "")}; path=/; samesite=lax; max-age=31536000`
    }
    // also persist to "old" locations to be compatible with both frontend types
    // TODO when we migrate, remove all of this and just compact pdhs cookie
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(
        "pd_state",
        JSON.stringify({ username: v.username, returning: v.returning })
      )
      for (const key of oldStorageKeys) {
        if (v[key]) {
          localStorage.setItem(key, JSON.stringify(v[key]))
        } else {
          localStorage.removeItem(key)
        }
      }
    }
  }

  watch(
    () => state,
    v => persistState(v),
    {
      immediate: true,
      deep: true,
    }
  )

  return state
}
