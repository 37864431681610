import { gql } from "@urql/core"

export default gql`
  query dayInvocationsByResource($orgId: String, $dayTs: Int!) {
    my(orgId: $orgId) {
      id
      usage {
        dayInvocationsByResource(dayTs: $dayTs) {
          resourceId
          resourceName
          invocations
        }
      }
    }
  }
`
