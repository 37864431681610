import { gql } from "@urql/core"
export default gql`
  query {
    subscriptionTiers {
      name
      platformPrice {
        currency
        unitAmountDecimal
      }
      usagePrice {
        currency
        unitAmountDecimal
      }
    }
  }
`
