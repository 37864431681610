import { gql } from "@urql/core"

export default gql`
  query usage($orgId: String) {
    my(orgId: $orgId) {
      id
      stripeSubscriptionActive
      stripeSubscription {
        id
        cancelAtPeriodEnd
        currentPeriodEnd
        currentPeriodStart
        status
      }
      usage {
        basePeriodInvocations
        dailyInvocationsQuota
        dailyInvocationsUsed
        extraDailyInvocations
        invocationBucketsByDayTs
        lambdaTimeQuota
        lambdaTimeUsed
        periodInvocations
      }
    }
  }
`
