import { gql } from "@urql/core"

export default async function configureComponentReloadProps(variables) {
  const resp = await this.mutation(
    gql`
      mutation(
        $codeHash: String!
        $configuredPropsJson: String!
        $dynamicPropsId: String
        $orgId: String
      ) {
        configureComponentReloadProps(
          codeHash: $codeHash
          configuredPropsJson: $configuredPropsJson
          dynamicPropsId: $dynamicPropsId
          orgId: $orgId
        ) {
          errors
          observations
          dynamicProps {
            id
            configurableProps
          }
        }
      }
    `,
    variables
  ).toPromise()
  const {
    errors,
    observations,
    dynamicProps,
  } = resp.data.configureComponentReloadProps
  if (errors && errors.length) throw errors
  return { observations, dynamicProps }
}
