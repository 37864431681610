// XXX
// 1) optimizing less event handlers?
// 2) maybe futurelink-style mouse prediction is better?
export default function useRouterLinkPrefetch(app) {
  const RouterLink = app.component("RouterLink") || app.component("router-link")
  if (import.meta.env.DEV && !RouterLink)
    throw new Error("use vue-router before calling useRouterLinkPrefetch")
  let configured
  RouterLink.mixins = [
    {
      mounted() {
        // XXX requestIdleCallback?
        if (!configured) {
          document.addEventListener("mousemove", e => {
            if (e.target.__prefetch) e.target.__prefetch()
          })
          configured = true
        }
        let prefetching = false
        this.$el.__prefetch = async () => {
          if (prefetching) {
            delete this.$el.__prefetch
            return
          }
          prefetching = true
          const resolvedRoute = this.$router.resolve(this.to)
          if (resolvedRoute.__prefetching) {
            delete this.$el.__prefetch
            return
          }
          resolvedRoute.__prefetching = true
          const promises = []
          for (const record of resolvedRoute.matched || []) {
            for (const Component of Object.values(record.components || {})) {
              if (typeof Component === "function") {
                // best effort, this happens in dev when server restarts and chunks become stale
                promises.push(Component().catch(() => {}))
              }
            }
          }
          await Promise.all(promises)
        }
      },
    },
  ]
}
