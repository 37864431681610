import { gql } from "@urql/core"
import { orgUser } from "@graphql/fragments"

export default gql`
  query org($id: String!, $afterOrg: String) {
    org(id: $id) {
      _can
      id
      name
      orgname
      email
      emailVerified
      desiredEmail
      orgUserConnections(first: 20, after: $afterOrg) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...OrgUserParts
        }
      }
    }
  }
  ${orgUser}
`
