import * as fragments from "@graphql/fragments"

const mutation = `
  mutation pipelineDeploy(
    $deploymentId: String!
    $redeploy: Boolean
    $lambdaMakerSuffix: String
    $orgId: String
  ) {
    my(orgId: $orgId) {
      pipelineDeploy(
        deploymentId: $deploymentId
        redeploy: $redeploy
        lambdaMakerSuffix: $lambdaMakerSuffix
      ) {
        deployment {
          ...WorkflowDeploymentParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.deployment}
  ${fragments.workflow.savedComponent}
`
export default async function pipelineDeploy(
  pipeline,
  redeploy,
  { lambdaMakerSuffix = null, orgId }
) {
  await this.mutation(mutation, {
    deploymentId: pipeline.latestDeployment.id,
    redeploy,
    lambdaMakerSuffix,
    orgId,
    //},
    //update(store, { data }) {
    //  const { errors, deployment } = data.pipelineDeploy
    //  if (errors && errors.length) throw errors
    //  if (!deployment) throw "Expected deployment!"
    //  fragments.workflow.pipeline.update({
    //    id: pipeline.id,
    //    latestDeployment: deployment,
    //  })
  }).toPromise()
}
