import { gql } from "@urql/core"
import { app, appWiths } from "@graphql/fragments"

export default gql`
  query adminApps(
    $after: String
    $q: String
    $adminOnly: Boolean
    $exceptIds: [String!]
    $orderBy: [AppOrderByEnum!]
    ${appWiths.fragmentPart}
  ) {
    admin {
      apps(
        first: 20
        after: $after
        q: $q
        adminOnly: $adminOnly
        exceptIds: $exceptIds
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...AppParts
        }
      }
    }
  }
  ${app}
`
