import _merge from "lodash-es/merge"
import _set from "lodash-es/set"
import admin from "./mutations/admin/index"
import addPipelineCollaborator from "./mutations/addPipelineCollaborator"
import deployComponent from "./mutations/deployComponent"
import deleteAuthProvision from "./mutations/deleteAuthProvision"
import deleteDeployedComponent from "./mutations/deleteDeployedComponent"
import deletePipelineCollaborator from "./mutations/deletePipelineCollaborator"
import deletePipelines from "./mutations/deletePipelines"
import deleteUser from "./mutations/deleteUser"
import deleteViewer from "./mutations/deleteViewer"
import createEnvironmentVariable from "./mutations/createEnvironmentVariable"
import deleteEnvironmentVariable from "./mutations/deleteEnvironmentVariable"
import updateEnvironmentVariable from "./mutations/updateEnvironmentVariable"
import orgAddUser from "./mutations/orgAddUser"
import orgReinviteUser from "./mutations/orgReinviteUser"
import orgRemoveUser from "./mutations/orgRemoveUser"
import orgCreate from "./mutations/orgCreate"
import orgDelete from "./mutations/orgDelete"
import orgUpdate from "./mutations/orgUpdate"
import publishAction from "./mutations/publishAction"
import regenerateApiKey from "./mutations/regenerateApiKey"
import saveAction from "./mutations/saveAction"
import saveApp from "./mutations/saveApp"
import saveAuthProvision from "./mutations/saveAuthProvision"
import saveComponent from "./mutations/saveComponent"
import savePipelineCheckpoint from "./mutations/savePipelineCheckpoint"
import saveUser from "./mutations/saveUser"
import saveViewer from "./mutations/saveViewer"
import updateDeployedComponent from "./mutations/updateDeployedComponent"
import updatePipeline from "./mutations/updatePipeline"
import workflow from "./mutations/workflow"
import configureComponentReloadProps from "./mutations/configureComponentReloadProps"

function nested(name, updater) {
  if (typeof updater == "object") {
    return (result, args, cache, info) => {
      for (const child of Object.keys(info.parent[name])) {
        const childUpdater = updater[child]
        if (childUpdater) {
          nested(child, childUpdater)(result, args, cache, info)
        }
      }
    }
  } else {
    return updater
  }
}

function prepare(fromTemplates, withPath = null) {
  const mutations = {}
  const updaters = {}
  for (const [name, mutation] of Object.entries(fromTemplates)) {
    const path = withPath ? `${withPath}.${name}` : name
    if (typeof mutation == "function") {
      mutations[path] = mutation
    } else if (typeof mutation == "object" && mutation.mutate) {
      mutations[path] = mutation.mutate
      if (mutation.update) {
        _set(updaters, mutation.name || name, mutation.update)
      }
    } else {
      throw `[graphql/mutations/${name}] Expected function or template in the form of { name, mutate, update }`
    }
  }
  return { mutations, updaters }
}

function createCacheExchangeOptions(updaters) {
  const _updaters = {}
  // For nested updaters, the parent must be a function.
  for (const [name, updater] of Object.entries(updaters)) {
    _updaters[name] = nested(name, updater)
  }
  return { updates: { Mutation: _updaters } }
}

const { mutations, updaters } = _merge(
  {},
  prepare({
    addPipelineCollaborator,
    deployComponent,
    deleteAuthProvision,
    deleteDeployedComponent,
    deletePipelineCollaborator,
    deletePipelines,
    deleteUser,
    deleteViewer,
    createEnvironmentVariable,
    deleteEnvironmentVariable,
    updateEnvironmentVariable,
    orgAddUser,
    orgReinviteUser,
    orgRemoveUser,
    orgCreate,
    orgDelete,
    orgUpdate,
    publishAction,
    regenerateApiKey,
    saveAction,
    saveApp,
    saveAuthProvision,
    saveComponent,
    savePipelineCheckpoint,
    saveUser,
    saveViewer,
    updateDeployedComponent,
    updatePipeline,
    configureComponentReloadProps,
  }),
  prepare(admin, "admin"),
  prepare(workflow, "workflow")
)

const cacheExchangeOptions = createCacheExchangeOptions(updaters)
export { mutations, cacheExchangeOptions }
