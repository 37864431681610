import _pick from "lodash-es/pick"
export default {
  prepare(pipelineInput) {
    const _pipelineInput = _pick(pipelineInput, [
      "archived",
      "concurrency",
      "description",
      "emitterConnected",
      "forwardToGlobalErrorWorkflow",
      "inactive",
      "lambdaMemory",
      "lambdaTimeout",
      "name",
      "public",
      "dataPublic",
      "rateLimit",
      "rateLimitBucketSizeSeconds",
      "readmeMd",
      "redisDelivery",
      "testEventJson",
      "maxEventQueueSize",
      "version",
    ])
    return _pipelineInput
  },
}
