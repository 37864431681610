import _isEqual from "lodash-es/isEqual"

export function onVisibilityChanged(intersections) {
  if (intersections && intersections[0]) {
    const event = new CustomEvent("update:visible", {
      detail: { visible: intersections[0].isIntersecting },
    })
    this.dispatchEvent(event)
  }
}

export function onUpdateBinding(el, binding) {
  if (binding.oldValue && _isEqual(binding.oldValue, binding.value)) return
  if (el._visibleObserver) {
    el._visibleObserver.disconnect()
  }
  el._visibleObserver = new IntersectionObserver(onVisibilityChanged.bind(el))
  el._visibleObserver.observe(el)
}
export default {
  name: "Visible",
  beforeMount(el, binding) {
    onUpdateBinding(el, binding)
  },
  mounted(el, binding) {
    onUpdateBinding(el, binding)
  },
  updated(el, binding) {
    onUpdateBinding(el, binding)
  },
  unmounted(el) {
    if (el._visibleObserver) {
      el._visibleObserver.disconnect()
    }
  },
}
