export default async function deleteUser({
  usernameConfirmation,
  messageConfirmation,
}) {
  const resp = await this.mutation(
    `
    mutation deleteViewer(
      $usernameConfirmation: String!
      $messageConfirmation: String!
    ) {
      deleteViewer(
        usernameConfirmation: $usernameConfirmation
        messageConfirmation: $messageConfirmation
      ) {
        errors
      }
    }
  `,
    {
      usernameConfirmation,
      messageConfirmation,
    }
  ).toPromise()
  const { errors } = resp.data.deleteViewer
  if (errors && errors.length) throw errors
}
