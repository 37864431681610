import { createStore } from "vuex"
import { findDeep, mergeState } from "@/src/utils/lang"
import { LRU } from "@/src/utils/lru"
import { regexp } from "@/src/utils/regexp"

import _clone from "lodash-es/clone"
import _filter from "lodash-es/filter"
import _findIndex from "lodash-es/findIndex"
import _forEach from "lodash-es/forEach"
import _get from "lodash-es/get"
import _inRange from "lodash-es/inRange"
import _isEqual from "lodash-es/isEqual"
import _map from "lodash-es/map"
import _sortedIndexBy from "lodash-es/sortedIndexBy"
import _throttle from "lodash-es/throttle"
import { atob } from "@utils/base64"

function isObject(v) {
  return v && typeof v === "object" && !Array.isArray(v)
}

function scopeMerge(scope, o) {
  for (const k in o) {
    const v = o[k]
    const vIsObject = isObject(v)
    if (!(k in scope)) {
      scope[k] = v
    } else {
      const sk = scope[k]
      const skIsObject = isObject(sk)
      if (skIsObject && vIsObject) {
        scopeMerge(sk, v)
      } else if (vIsObject) {
        scope[k] = v
      }
    }
  }
}

export default function createMyStore({ app }) {
  const api = app.config.globalProperties.$api
  if (!api) {
    throw new Error("call createMyStore after createApi")
  }

  function defaultState() {
    return {
      // api cache / shared state
      deployments: {
        byId: {},
      },
      events: {
        byId: {}, // eventId => { id, deplolymentId, start, execution, dest: [] }
        connected: false,
        ids: [],
        pid: null, // Pipeline ID of current events
        paused: false, // Set to { ids: ..., count: ... } when paused
      },
      sources: {
        byId: {},
        ids: [],
      },

      // test event cache (LRU) Array<{key: dcId, value: selectedEvent}>
      testEventSummaries: [],

      // for autocomplete of smart-text-input and code-editor
      exportsScopesByPipelineId: {},

      // local state
      focus: {
        current: null,
      },
      docContext: {
        current: {
          key: "workflow",
          ids: [],
        },
        dataById: {},
      },
      announcement: null,
      clientHasUpdates: false,
    }
  }

  let initialState = defaultState()
  if (!import.meta.env.SSR) {
    // XXX use replaceState instead?
    if (window.__vuex__) initialState = window.__vuex__
  }

  function clearLocalState(state) {
    const nextState = defaultState()
    state.deployments = nextState.deployments
    state.events = nextState.events
    state.exportsScopesByPipelineId = nextState.exportsScopesByPipelineId
    state.sources = nextState.sources
  }

  const $store = createStore({
    state: initialState,
    mutations: {
      CLEAR_LOCAL_STATE(state) {
        clearLocalState(state)
      },
      SET_FOCUS(state, { key }) {
        state.focus.current = key
      },
      SET_DOC_CONTEXT(state, { key, ids }) {
        state.docContext.current = { key, ids }
      },
      DELETE_DOC_CONTEXT_DATA(state, { id }) {
        if (id) {
          delete state.docContext.dataById[id]
        }
      },
      SET_DOC_CONTEXT_DATA(state, { id, data }) {
        if (!_isEqual(state.docContext.dataById[id], data)) {
          state.docContext.dataById[id] = data
        }
      },
      RECEIVE_DEPLOYMENTS(state, deployments) {
        for (const deployment of deployments) {
          let d = state.deployments.byId[deployment.id]
          if (!d) {
            d = {
              eventIds: [],
              ...deployment,
            }
            state.deployments.byId[deployment.id] = d
          } else {
            mergeState(d, deployment)
          }
        }
      },
      RECEIVE_SOURCES(state, sources) {
        for (const source of sources) {
          const existingIndex = _findIndex(
            state.sources.ids,
            s => source.id == s.id
          )
          if (_inRange(existingIndex, 0, state.sources.ids.length)) {
            state.sources.ids.splice(existingIndex, 1)
          }
          const insertAt = _sortedIndexBy(
            state.sources.ids,
            source,
            "display_order"
          )
          state.sources.ids.splice(insertAt, 0, {
            id: source.id,
            display_order: source.display_order,
          })
          state.sources.byId[source.id] = source
        }
      },
      EDITOR_PAUSE_EVENTS(state, { paused = false, count = 0 }) {
        if (paused) {
          if (state.events.paused) {
            state.events.paused.count += count
          } else {
            state.events.paused = {
              count,
              ids: _clone(state.events.ids),
              byId: _clone(state.events.byId),
            }
          }
        } else {
          state.events.paused = false
        }
      },
      EDITOR_WATCH_PIPELINE(state, pid) {
        if (pid != state.events.pid) {
          state.events.ids.splice(0, state.events.ids.length)
          state.events.byId = {}
          state.events.pid = pid
          state.events.connected = false
        }
      },
      EDITOR_WATCH_PIPELINE_CONNECTED(state) {
        state.events.connected = true
      },
      DELETE_ALL_EVENTS(state) {
        state.events.ids.splice(0, state.events.ids.length)
        state.events.byId = {}
      },
      DELETE_EVENT(state, eventId) {
        const index = _findIndex(state.events.ids, eventId)
        if (index >= 0) {
          state.events.ids.splice(index, 1)
        }
        delete state.events.byId[eventId]
      },
      RECEIVE_OBSERVATIONS(state, observation_data) {
        const MAX_EVENTS = 100
        _forEach(observation_data, observation_tuple => {
          const [entryId, observation] = observation_tuple
          if (observation.pid == state.events.pid) {
            let event = _get(state.events.byId, observation.id, null)
            if (event && event.entryIds) {
              event.entryIds.push(entryId)
            }
            switch (observation.observation_type) {
              case "delete_all_eventsv1":
                state.events.ids.splice(0, state.events.ids.length)
                state.events.byId = {}
                break
              case "delete_eventv1": {
                const indexToDelete = _findIndex(
                  state.events.ids,
                  observation.event_id
                )
                if (indexToDelete >= 0) {
                  state.events.ids.splice(indexToDelete, 1)
                }
                delete state.events.byId[observation.event_id]
                break
              }
              case "startv1": {
                if (event) break
                event = {
                  id: observation.id,
                  did: observation.did,
                  destinations: {},
                  entryIds: [entryId],
                  errors: [],
                  execution: null,
                  pid: observation.pid,
                  start: observation,
                  ts: observation.ts,
                  _ts_ms: Date.parse(observation.ts),
                  version: null,
                  _search: {
                    source: null,
                    context: null,
                    diffs: [],
                    responses: [],
                  },
                }
                state.events.byId[observation.id] = event
                if (observation.source) {
                  // For event search
                  event._search.source = JSON.stringify(observation.source)
                }
                if (observation.context) {
                  event._search.context = JSON.stringify(observation.context)
                }
                if (observation.error) {
                  event.errors.push(observation.error)
                }

                if (observation.trigger_exports) {
                  const exports = {}
                  // save trigger export shape to a namespace that cannot be used by the user
                  // and then expose it as the current trigger namespace when building scope / extraLib
                  exports["-trigger"] = observation.trigger_exports
                  const scope =
                    state.exportsScopesByPipelineId[observation.pid] || {}
                  scopeMerge(scope, exports)
                  state.exportsScopesByPipelineId[observation.pid] = scope
                }

                let insertAt = 0
                for (const index in state.events.ids) {
                  const prev = state.events.byId[state.events.ids[index]] || {}
                  if (prev._ts_ms <= event._ts_ms) {
                    insertAt = index
                    break
                  }
                }
                state.events.ids.splice(insertAt, 0, event.id)

                const num_events = state.events.ids.length
                if (num_events > MAX_EVENTS) {
                  const trimmed = state.events.ids.splice(
                    MAX_EVENTS,
                    num_events - MAX_EVENTS
                  )
                  for (const trim of trimmed) {
                    delete state.events.byId[trim]
                  }
                }
                break
              }
              case "executionv1":
                if (event) {
                  event.execution = observation
                  if (observation.diffs) {
                    // For event search
                    event._search.diffs = JSON.stringify(observation.diffs)
                  }
                  if (observation.error) {
                    event.errors.push(observation.error)
                  }
                  if (observation.response) {
                    event._search.response = JSON.stringify(
                      observation.response
                    )
                  }
                  if (observation.exports) {
                    // XXX hacky, but we set special -trigger via startv1 observation
                    // - this allows someone to name a step trigger
                    // - it also frees some redundant memory
                    // ... but it's hacky
                    delete observation.exports.trigger
                    const scope =
                      state.exportsScopesByPipelineId[observation.pid] || {}
                    scopeMerge(scope, observation.exports)
                    state.exportsScopesByPipelineId[observation.pid] = scope
                  }
                } else {
                  // Sentry.captureMessage("Missing start event: ", observation.id)
                }
                break
              case "destinationv1":
                if (event) {
                  if (observation.response_raw_b64) {
                    try {
                      event._search.responses.push(
                        atob(observation.response_raw_b64)
                      )
                    } catch (e) {
                      // noop
                    }
                  }
                  event.destinations[
                    observation.send_id || observation.cell_id
                  ] = observation
                  if (observation.error) {
                    event.errors.push(observation.error)
                  }
                } else {
                  // Sentry.captureMessage("Missing start event: ", observation.id)
                }
                break
            }
          }
        })
      },
      SET_ANNOUNCEMENT(state, announcement) {
        state.announcement = announcement
      },
      CLIENT_HAS_UPDATES(state) {
        state.clientHasUpdates = true
      },
      SET_TEST_EVENT_SUMMARY(state, { dcId, summary }) {
        if (dcId) {
          const lru = new LRU(10, state.testEventSummaries)
          lru.set(dcId, summary)
          state.testEventSummaries = lru.toJSON().filter(kv => kv && kv.key)
        }
      },
      PIPELINE_EXPORTS_MIGRATE_STEP_NAMESPACE(state, { pipelineId, ov, nv }) {
        const exports = state.exportsScopesByPipelineId[pipelineId]
        if (!exports) return
        if (!(ov in exports)) return
        exports[nv] = exports[ov]
        delete exports[ov]
      },
    },
    actions: {
      setFocus({ commit }, key) {
        commit("SET_FOCUS", { key })
      },
      setDocContext({ commit }, context) {
        commit("SET_DOC_CONTEXT", context)
      },
      deleteDocContextData({ commit }, { id }) {
        commit("DELETE_DOC_CONTEXT_DATA", { id })
      },
      setDocContextData({ commit }, { id, data }) {
        commit("SET_DOC_CONTEXT_DATA", { id, data })
      },
      clearLocalState({ commit }) {
        commit("CLEAR_LOCAL_STATE")
      },
      async createPipeline(ctx, opts = {}) {
        const { deploy, nonce, creationContext } = opts
        const res = await api.createPipeline(
          opts.pipeline,
          nonce,
          deploy,
          creationContext
        )
        const pipeline = res.data
        return pipeline
      },
      async fetchDeployment({ commit, state }, did) {
        if (!state.deployments.byId[did]) {
          const deployment = (await api.getDeployment(did)).data
          if (deployment) {
            commit("RECEIVE_DEPLOYMENTS", [deployment])
          }
        }
        return state.deployments.byId[did]
      },
      async createSource({ commit, state }, source) {
        const res = await api.createSource(source)
        commit("RECEIVE_SOURCES", [res.data])
        return state.sources.byId[res.data.id]
      },
      async updateSource({ commit, state }, source) {
        const res = await api.updateSource(source)
        commit("RECEIVE_SOURCES", [res.data])
        return state.sources.byId[res.data.id]
      },
      async fetchSource({ commit, state }, id) {
        const res = await api.fetchSource(id)
        commit("RECEIVE_SOURCES", [res.data])
        return state.sources.byId[res.data.id]
      },
      async fetchSources({ commit }) {
        const res = await api.fetchSources()
        commit("RECEIVE_SOURCES", res.data)
      },
      async updatePipeline(ctx, { pipeline, nonce, deploy }) {
        const res = await api.updatePipeline(
          pipeline.id,
          pipeline,
          nonce,
          deploy
        )
        const updatedPipeline = res.data
        return updatedPipeline
      },
      async editorNextEvent(_, { timeout = 5000 } = {}) {
        return new Promise((resolve, reject) => {
          let unwatch = null
          let timeoutId = null
          unwatch = $store.watch(
            (_, { lastEventId }) => lastEventId,
            nv => {
              if (unwatch) {
                unwatch()
                unwatch = null
                resolve(nv)
              }
              clearTimeout(timeoutId)
            }
          )
          if (timeout > 0) {
            timeoutId = setTimeout(() => {
              if (unwatch) {
                unwatch()
                unwatch = null
                reject(new Error("Timeout: editorNextEvent"))
              }
            }, timeout)
          }
        })
      },
      editorPauseEvents({ commit }, paused) {
        commit("EDITOR_PAUSE_EVENTS", { paused })
      },
      async editorWatch({ commit, state }, id) {
        if (id) {
          commit("EDITOR_WATCH_PIPELINE", id)
          let eventBuffer = []
          const onEvent = _throttle(
            async () => {
              let observation_data = eventBuffer.splice(0, eventBuffer.length)
              if (state.events.paused) {
                const count = _filter(
                  observation_data,
                  e => e.observation_type == "startv1"
                ).length
                commit("EDITOR_PAUSE_EVENTS", { paused: true, count })
              }
              commit("RECEIVE_OBSERVATIONS", observation_data)
            },
            500,
            { leading: true, trailing: true }
          )
          await api.watchPipeline(id, async on => {
            eventBuffer.push(...on.events)
            await onEvent()
            if (!state.events.connected && on.on == "connected") {
              commit("EDITOR_WATCH_PIPELINE_CONNECTED")
            }
          })
        }
      },
      async editorUnwatch({ commit }, id = null) {
        commit("EDITOR_WATCH_PIPELINE", null)
        await api.unwatchPipeline(id)
      },
      async editorUnwatchAllPipelines({ commit }) {
        commit("EDITOR_WATCH_PIPELINE", null)
        await api.unwatchAllPipelines()
      },
      async deleteAllEvents({ commit }, pipelineId) {
        try {
          await api.deleteAllEvents(pipelineId)
        } finally {
          commit("DELETE_ALL_EVENTS")
        }
      },
      async deleteEvent({ commit, state }, eventId) {
        const event = _get(state.events.byId, eventId, null)
        if (event) {
          try {
            await api.deleteEvent(event.pid, event.id, event.entryIds)
          } finally {
            commit("DELETE_EVENT", eventId)
          }
        }
      },
      setTestEventSummary({ commit }, { dcId, summary }) {
        commit("SET_TEST_EVENT_SUMMARY", { dcId, summary })
      },
    },
    getters: {
      editorPausedCount(state) {
        return _get(state, "events.paused.count", 0)
      },
      events(state) {
        if (state.events.paused) {
          return _filter(
            _map(state.events.paused.ids, eid => state.events.paused.byId[eid]),
            e => e && e.start && e.start.ts
          )
        } else {
          return _filter(
            _map(state.events.ids, eid => state.events.byId[eid]),
            e => e && e.start && e.start.ts
          )
        }
      },
      eventsSearch(state, { events }) {
        return search => {
          if (!search) return events
          const re = regexp(search)
          return _filter(events, e => findDeep(e, re, 8))
        }
      },
      lastEventId(state) {
        if (!state.events.ids.length) {
          return null
        }
        return state.events.ids[0]
      },
      lastEvent(state, { lastEventId }) {
        return lastEventId && state.events.byId[lastEventId]
      },
      lastCompletedEvent(state) {
        for (const eventId of state.events.ids) {
          const event = state.events.byId[eventId]
          if (_get(event, "execution.complete_ts")) return event
        }
        return null
      },
      allSources(state) {
        return state.sources.ids.map(s => state.sources.byId[s.id])
      },
      sources(state) {
        return _filter(
          state.sources.ids.map(s => state.sources.byId[s.id]),
          s => s.enabled
        )
      },
      testEventSummary(state) {
        return dcId => {
          return _get(
            state.testEventSummaries.find(({ key }) => key == dcId),
            "value"
          )
        }
      },
    },
  })

  app.use($store)

  return $store
}
