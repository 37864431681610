import _pick from "lodash-es/pick"

export default async function saveViewer(data) {
  const resp = await this.mutation(
    `
      mutation saveViewer(
        $username: String
        $desiredEmail: String
        $currentPassword: String
        $newPassword: String
        $newPasswordConfirmation: String
        $step: String
      ) {
        saveViewer(
          username: $username
          desiredEmail: $desiredEmail
          currentPassword: $currentPassword
          newPassword: $newPassword
          newPasswordConfirmation: $newPasswordConfirmation
          step: $step
        ) {
          user {
            id
            username
            desiredEmail
            completeOnboardingSteps {
              step
            }
          }
          errors
        }
      }
    `,
    _pick(data, [
      "username",
      "desiredEmail",
      "currentPassword",
      "newPassword",
      "newPasswordConfirmation",
      "step",
    ])
  ).toPromise()
  const { user, errors } = resp.data.saveViewer
  if (errors && errors.length) throw errors
  return user
}
