import { gql } from "@urql/core"

export default gql`
  query adminUsers(
    $after: String
    $q: String
    $banned: Boolean
    $premium: Boolean
    $subscribed: Boolean
    $orderBy: [UserOrderByEnum!]
  ) {
    admin {
      users(
        after: $after
        first: 20
        q: $q
        banned: $banned
        premium: $premium
        subscribed: $subscribed
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          admin
          bannedAt
          cmTag
          createdAt
          email
          basePeriodInvocations
          extraDailyInvocations
          lambdaTimeQuota
          nickname
          premium
          stripeCustomerId
          stripeSubscriptionActive
          stripeSubscriptionId
          username
        }
      }
    }
  }
`
