import NProgress from "nprogress"
import "./nprogress.css" // rewrite #29 to primary green

NProgress.configure({ showSpinner: false })

export function cancelTopProgress() {
  NProgress.done()
}

function tryInitProgress() {
  NProgress.start()
}

export function initProgress(router) {
  router.afterEach(cancelTopProgress)
  router.beforeEach((to, from, next) => {
    tryInitProgress()
    return next()
  })
}
