import { gql } from "@urql/core"
import { app, appWiths } from "@graphql/fragments"

export default gql`
  query apps(
    ${appWiths.fragmentPart}
  ) {
    apps {
      ...AppParts
    }
  }
  ${app}
`
