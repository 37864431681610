import { gql } from "@urql/core"
import { action } from "@graphql/fragments"

export default gql`
  query action($id: String!) {
    action(id: $id) {
      ...ActionParts
    }
  }
  ${action}
`
