import { inject, reactive, watchEffect } from "vue"
import { Sentry } from "@sentry"

const NETWORK_STATUS = Symbol("NETWORK_STATUS")

export function createNetworkStatus({ app, router }) {
  const networkStatus = reactive({
    online: typeof window === "undefined" ? true : window.navigator.onLine,
  })

  const onOnline = () => {
    networkStatus.online = true
    Sentry.addBreadcrumb({
      category: "network-status",
      message: "online",
    })
    // force refresh, assuming no beforeRouteLeave to confirm dirty changes, etc.
    for (const m of router.currentRoute.value.matched) {
      if (m.components?.default?.beforeRouteLeave) {
        return
      }
    }
    router.go(0)
  }
  const onOffline = () => {
    networkStatus.online = false
    Sentry.addBreadcrumb({
      category: "network-status",
      message: "offline",
    })
  }

  if (typeof window !== "undefined") {
    watchEffect(onInvalidate => {
      window.addEventListener("online", onOnline)
      window.addEventListener("offline", onOffline)
      onInvalidate(() => {
        window.removeEventListener("online", onOnline)
        window.removeEventListener("offline", onOffline)
      })
    })
  }

  app.config.globalProperties.$networkStatus = networkStatus
  app.provide(NETWORK_STATUS, networkStatus)

  return networkStatus
}

export function useNetworkStatus() {
  const networkStatus = inject(NETWORK_STATUS)
  if (!networkStatus)
    throw new Error(
      "must createNetworkStatus().install() before useNetworkStatus"
    )
  return networkStatus
}
