import * as Sentry from "@sentry/browser"
//import { init } from "./sentry-vue3"
import { Integrations } from "@sentry/tracing"

const init = Sentry.init

export function createClientSentry({ app, router }) {
  const opts = {
    dsn:
      "https://97aa41261e6e462d93e454687a0d01f2@o210198.ingest.sentry.io/5660875",
    release: import.meta.env.VITE_SENTRY_RELEASE,
    environment: import.meta.env.MODE,
    // app,
    // tracingOptions: {
    //   trackComponents: true,
    // },
    // logErrors: import.meta.env.DEV,
    integrations: [
      new Integrations.BrowserTracing({
        // XXX check other origins / if we send in dev
        tracingOrigins: ["api.pipedream.com", "rt.pipedream.com"],
        // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#beforenavigate
        beforeNavigate: () => {}, // let vue-router integration below take care of it
      }),
    ],
    tracesSampler() {
      if (import.meta.env.DEV) {
        return 0.0
      }
      return 0.1
    },
    ignoreErrors: [
      /ResizeObserver loop limit exceeded/, // https://stackoverflow.com/a/50387233/387413
      /ResizeObserver loop completed with undelivered notifications/, // XXX  could be an issue! (https://github.com/juggle/resize-observer/issues/103)
      /^Canceled$/, // https://sentry.io/organizations/pipedream-inc/issues/2474232167
      /Timeout: editorNextEvent/, // https://pipe-dream.slack.com/archives/C021T7R5NR2/p1624571173184700A
      /AbortError: Aborted/, // eg. on logout
      /Identifier 'originalPrompt' has already been declared/, // grammarly extension issue
      // the following are networking errors... was hoping the $networkStatus blocking below would silence...
      // XXX check whether these are just CORS errors, or if the networkStatus stuff is silencing them correctly when they are due to actual network issues
      // /Network request failed/,
      // /error loading dynamically imported module/,
      // /Couldn't resolve component "default" at/,
      // /Failed to fetch/,
    ],
    beforeSend(event, hint) {
      const sourceFile = event.extra?.body?.sourceFile
      if (sourceFile?.startsWith("chrome-extension")) {
        return null
      }
      if (sourceFile?.startsWith("safari-extension")) {
        return null
      }
      const { $networkStatus } = app.config.globalProperties
      if ($networkStatus && !$networkStatus.online) {
        // not online, not sending/queuing errors to sentry, for example:
        // - Network request failed
        // - Failed to fetch dynamically imported module:
        // - Couldn't resolve component "default" at
        return null
      }
      if (import.meta.env.DEV) {
        console.error(hint.originalException)
        return null
      }
      return event
    },
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === "console") {
        if (import.meta.env.DEV) {
          // defend against sentry breadcrumb stuff causing:
          // "Avoid app logic that relies on enumerating keys on a component instance"
          // if we see a vnode (eg.printing component stack with ref),
          // THEN BAIL
          for (const arg of breadcrumb.data?.arguments || []) {
            if (arg?._?.vnode) return null
          }
        }
        // https://discord.com/channels/325477692906536972/410996148501872641/857309742333952000
        if (
          breadcrumb.level === "error" &&
          /'parentNode' of null/.test(breadcrumb.message)
        ) {
          Sentry.captureMessage(breadcrumb.message)
          return null
        }
      }
      // XXX if breadcrumb.category === "xhr",
      // ... hint.xhr /graphql endpoint, add #name to end
      // but hint.xhr doesn't have info about request??
      return breadcrumb
    },
  }
  init(opts)

  app.config.errorHandler = (err, _, info) => {
    Sentry.setTag("info", info)
    Sentry.captureException(err)
  }

  app.config.globalProperties.$sentry = Sentry
  app.provide("$sentry", Sentry)

  // XXX mimc the following from @sentry/vue
  // vueRouterInstrumentation(router)
  router.onError(err => Sentry.captureException(err))
  const tags = {
    "routing.instrumentation": "vue-router",
  }
  const startTransactionOnPageLoad = true
  const startTransactionOnLocationChange = true
  let firstLoad = true
  router.beforeEach((to, _from, next) => {
    const data = {
      params: to.params,
      query: to.query,
    }
    if (startTransactionOnPageLoad && firstLoad) {
      Sentry.startTransaction({
        name: to.name || to.path,
        op: "pageload",
        tags,
        data,
      })
    }
    if (startTransactionOnLocationChange && !firstLoad) {
      Sentry.startTransaction({
        name: to.name || to.matched[0].path || to.path,
        op: "navigation",
        tags,
        data,
      })
    }
    firstLoad = false
    next()
  })
}

export { Sentry }
