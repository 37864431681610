import * as fragments from "@graphql/fragments"
import { replaceLatestDeployment } from "./pipelineUpdateStep"

const mutation = `
  mutation pipelineDeleteStep(
    $deploymentId: String!
    $index: Int!
    $lambdaMakerSuffix: String
    $orgId: String
  ) {
    my(orgId: $orgId) {
      pipelineDeleteStep(
        deploymentId: $deploymentId
        index: $index
        lambdaMakerSuffix: $lambdaMakerSuffix
      ) {
        deployment {
          ...WorkflowDeploymentParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.deployment}
  ${fragments.workflow.savedComponent}
`
export default {
  name: "my.pipelineDeleteStep",
  async mutate(pipeline, index, { lambdaMakerSuffix = null, orgId }) {
    await this.mutation(mutation, {
      deploymentId: pipeline.latestDeployment.id,
      index,
      lambdaMakerSuffix,
      orgId,
    }).toPromise()
  },
  update(result, _args, cache, info) {
    const args = info.variables
    if (!result.my.errors) {
      replaceLatestDeployment(
        args.deploymentId,
        result.my.pipelineDeleteStep.deployment,
        cache
      )
    }
  },
}
