import { gql } from "@urql/core"
import { orgUser } from "@graphql/fragments"

export default {
  async mutate(orgId, orgUserId) {
    const resp = await this.mutation(
      gql`
        mutation orgReinviteUser($orgId: String!, $orgUserId: String!) {
          orgReinviteUser(orgId: $orgId, orgUserId: $orgUserId) {
            orgUser {
              ...OrgUserParts
            }
            errors
          }
        }
        ${orgUser}
      `,
      {
        orgId,
        orgUserId,
      }
      // XXX add update?
    ).toPromise()
    const { orgUser: ou, errors } = resp.data.orgReinviteUser
    if (errors && errors.length) throw errors
    return ou
  },
}
