export default async function(
  {
    deploymentId,
    endNamespace,
    lambdaMakerSuffix = null,
    startNamespace,
    traceId,
    trigger,
    triggerOnly,
  },
  { orgId } = {}
) {
  const resp = await this.mutation(
    `
    mutation executeTestRequest(
      $deploymentId: String!
      $endNamespace: String
      $lambdaMakerSuffix: String
      $orgId: String
      $startNamespace: String
      $traceId: String
      $trigger: JSON
      $triggerOnly: Boolean
    ) {
      my(orgId: $orgId) {
        executeTestRequest(
          deploymentId: $deploymentId
          endStepNamespace: $endNamespace
          executionTraceId: $traceId
          lambdaMakerSuffix: $lambdaMakerSuffix
          startStepNamespace: $startNamespace
          trigger: $trigger
          triggerOnly: $triggerOnly
        ) {
          errors
          executionTraceId
        }
      }
    }
  `,
    {
      deploymentId,
      endNamespace: triggerOnly ? undefined : endNamespace,
      lambdaMakerSuffix,
      orgId,
      startNamespace: triggerOnly ? undefined : startNamespace,
      traceId,
      trigger: traceId ? undefined : trigger,
      triggerOnly,
    }
  ).toPromise()
  if (resp.error) throw resp.error
  const { errors, executionTraceId } = resp.data.my.executeTestRequest
  if (errors && errors.length) throw errors
  return executionTraceId
}
