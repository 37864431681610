import { gql } from "@urql/core"

export default {
  name: "my.updateEnvironmentVariable",
  async mutate({ orgId, id, value }) {
    const mutation = gql`
      mutation updateEnvironmentVariable(
        $orgId: String
        $id: String!
        $value: String!
      ) {
        my(orgId: $orgId) {
          updateEnvironmentVariable(id: $id, value: $value) {
            errors
            redactedEnvironmentVariable {
              id
              updatedAt
            }
          }
        }
      }
    `
    const variables = {
      orgId,
      id,
      value,
    }
    const resp = await this.mutation(mutation, variables).toPromise()
    const {
      redactedEnvironmentVariable,
      errors,
    } = resp.data.my.updateEnvironmentVariable
    if (errors && errors.length) throw errors
    return redactedEnvironmentVariable
  },
}
