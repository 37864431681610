import _get from "lodash-es/get"
import { invalidateAuthProvisionQueries } from "./saveAuthProvision"
export default {
  async mutate(authProvision) {
    await this.mutation(
      `
      mutation deleteAuthProvision($id: String!) {
        deleteAuthProvision(id: $id) {
          errors
        }
      }
    `,
      {
        id: authProvision.id,
      }
    ).toPromise()
  },
  update(result, args, cache /* , info */) {
    if (!result.error && !_get(result, "deleteAuthProvision.errors.length")) {
      if (args.id) {
        invalidateAuthProvisionQueries(cache)
        cache.invalidate({ __typename: "AuthProvision", id: args.id })
      }
    }
  },
}
