import * as fragments from "@graphql/fragments"
import PipelineInput from "./pipelineInput"

const mutation = `
  mutation updatePipeline(
    $id: String!
    $orgId: String
    $pipeline: PipelineInput!
  ) {
    my(orgId: $orgId) {
      updatePipeline(id: $id, pipeline: $pipeline) {
        pipeline {
          ...WorkflowPipelineParts
        }
        errors
      }
    }
  }
  ${fragments.workflow.deployedComponent}
  ${fragments.workflow.deployment}
  ${fragments.workflow.httpInterface}
  ${fragments.workflow.pipe}
  ${fragments.workflow.pipeline}
  ${fragments.workflow.savedComponent}
  ${fragments.workflow.timerInterface}
`
export default async function pipelineUpdate(
  id,
  pipelineInput,
  { orgId } = {}
) {
  const _pipelineInput = PipelineInput.prepare(pipelineInput)
  const results = await this.mutation(mutation, {
    id,
    orgId,
    pipeline: _pipelineInput,
  }).toPromise()
  const { errors, pipeline } = results.data.my.updatePipeline
  if (errors && errors.length) throw errors
  if (!pipeline) throw "Expected pipeline!"
  return pipeline
}
