import { gql } from "@urql/core"

export default gql`
  query publicPipelines($offset: Int!, $limit: Int!) {
    pipelines(offset: $offset, limit: $limit) {
      id
      name
      nameSlug
      user {
        username
      }
    }
  }
`
